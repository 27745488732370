import React, { useState, useEffect } from "react"
import Layout from "../../components/Layout"
import * as styles from "../../pages/seller/sellerProp.module.css"
import "react-multi-carousel/lib/styles.css"
import "bootstrap/dist/css/bootstrap.css"
import IntrestedPropertyDetail from "../seller/intrestedPropertyDetail"
import { intrestedPropertyDetail, favoritePropertyDetails, similarProperty, imageLikeList } from "../../components/Api/ListingApi"
// import Loader from "../../components/Loader"
import Cards from "../../components/card/card"
import { useSelector } from "react-redux"
import ColdWellPopup from "../../components/popup/ColdWellPopup"
import SkeletonVerticalCard from "../../components/card/skeletonVerticalCard";
import { handleCheckBrokarage } from "../../common/GenericFunction"

function InterestedProperty(props) {

  // redux function //
  const userDetails = useSelector(state => state.user.userDetail);
  // variable declare //
  const array = ["1", "2", "3", "4", "5", "6"]
  let userId = localStorage.getItem('userId')

  // UseState function //  
  const [open, setOpen] = useState(false)
  const [loader, setLoader] = useState(false);
  const [agentDetails, setAgentDetails] = useState([])
  const [similar, setSimilar] = useState([])
  const [detailList, setDetailList] = useState([])
  const [isdataFounded, setisDataFounded] = useState(false)
  const [eindex, setEindex] = useState()
  const [getAgentDetails, setGetAgentDetails] = useState({})
  const [openProperty, setOpenProperty] = useState(false)
  const [propertyId, setPropertyId] = useState("")
  const [success, setSucess] = useState(false)
  const [propertDetails, setPropertyDetails] = useState({})
  const [likes, setLikes] = useState([])

  // useEffect declare //
  useEffect(() => {
    setLoader(true)
    getIntresentedPropertyDetails()
    setLoader(false)
  }, [])


  useEffect(() => {
    if (userId !== null) {
      handleImageLikeList(userId)
    }
  }, [])


  const handleImageLikeList = async (userId) => {
    var value = []
    await imageLikeList(userId).then((res) => {
      setLikes(res.data.data)
      value = res.data.data
      getIntresentedPropertyDetails()
    }).catch((error) => {
      console.log(error)
    })
    return value
  }
  // interested property details open function//
  const showDetail = () => {
    setOpen(true)
  }
  // interested property details close function//
  const closeDetail = () => {
    setOpen(false)
  }
  // Interested property Details function //
  const getIntresentedPropertyDetails = async () => {
    setOpen(false)
    setLoader(true)
    await intrestedPropertyDetail(userId).then((res) => {
      let data = [...res.data.data]
      data.length !== 0 ? setisDataFounded(false) : setisDataFounded(true)
      data.forEach((item) => {
        item.isLike = item.property_id !== null && item.property_id._id
      })
      setDetailList(data)
      setLoader(false)
    })
  }

  // similar property function //
  const similarPropertyData = async (index, data) => {
    setAgentDetails(data)
    let props = { longitude: data.property_id.location !== undefined && data.property_id.location.coordinates[0], latitude: data.property_id.location !== undefined && data.property_id.location.coordinates[1] }
    await similarProperty(data.property_id._id, props).then((res) => {
      setSimilar(res.data.data)
      if (data.property_id.listing_status !== 3) {
        showDetail(index, data)
      }
    }).catch((error) => {
      console.log(error)
    })
  }

  // Interested property list API //
  const listing = async (data) => {
    // setAgent(data)
    let property_id = data.property_id._id
    await favoritePropertyDetails(property_id)
      .then((res) => {
        if (res.data.data) {
          setGetAgentDetails(res.data.data)
        }
        else {
          setGetAgentDetails({})
        }
      }).catch((error) => {
        console.log(error)
      })
  }

  // open Exclusive property //
  const OpenData = (index, item) => {
    if (item.property_id.listing_status === 3 || item.property_id.listing_status === "3") {
      if (userDetails.cb_active === true) {
        setOpen(true)
      }
      else {
        toggleSuccess(item)
        setOpen(false)
      }
    } if (item.property_id.listing_status !== 3) {
      setOpen(true)
    }
    setEindex(index)
  }
  // property details popup// 
  const hanldeOpenDetails = (id) => {
    // toggleSuccess()
    setOpenProperty(p => !p)
    setPropertyId(id)

  }

  //coldwell popup //
  const toggleSuccess = (item) => {
    // let userId = localStorage.getItem('userId')
    console.log(item !== undefined && item.property_id.companyID);
    // console.log(item!==undefined && item.property_id.listing_status == 3 , item!==undefined && item.property_id.brokerage_exclusive !== false , userDetails.role == "user", userDetails.cb_active !== true);
    if (item !== undefined && item.property_id.companyID) {
      // console.log("1");
      if (item.property_id.listing_status == 3 && item.property_id.brokerage_exclusive !== false && userDetails.role == "user" && handleCheckBrokarage(item.property_id.companyID, userDetails)) {
        setSucess(p => !p)
        setPropertyDetails(item)
      }
    }
    else {
      if (item == undefined)
        setSucess(p => !p)
    }
    //  else{
    // if (userId !== null) {
    //   setSucess(p => !p)
    //   setPropertyDetails(item)
    // setTimeout(() => setSucess(false), 10000)
    // } else {
    // setMessage("In order to view this property details")
    // hanldeOpenPopup()
    // }
    //  }
  }

  const handleLike = (item, index, id) => {
    OpenData(index, item);
    setPropertyId(item.property_id._id);
    similarPropertyData(index, item);
    listing(item);
    setPropertyDetails(item)
  }
  return (
    <>

      <Layout>
        {<ColdWellPopup success={success} toggleSuccess={toggleSuccess} openProperty={openProperty} hanldeOpenDetails={hanldeOpenDetails}  open ={()=>setOpen(true)} propertyId={propertyId} listView={true} propertDetails={propertDetails !== undefined && propertDetails.property_id} />}
        <div className="row ms-3 mt-3">
          <div className={`${open ? 'col-md-8' : 'col-md-12'}`}>
            <div className="row" style={{ height: "902px", overflow: "auto", }}>
              {loader ?
                array.map((item) => (
                  <div className={`${open ? 'col-md-6' : 'col-md-4 py-2 px-1'}`} >
                    <div className={styles.CarouselCard1} style={{ marginBottom: "50px" }} tabIndex="0">
                      <SkeletonVerticalCard purpose={"card"} />
                    </div></div>
                ))

                :
                detailList.map((item, index) => (
                  <div className={`${open ? 'col-md-6' : 'col-md-4 py-2 px-1'}`} key={index}>
                    <div className={styles.CarouselCard1} style={{ marginBottom: "50px" }} role="button" tabIndex="0">
                      <Cards data={item} Eindex={eindex} cindex={index} reLoadPage={getIntresentedPropertyDetails} loader={() => setLoader(true)} likes={likes.includes(item.property_id._id)} handleLike={() => handleLike(item, index)} />
                    </div>
                  </div>
                ))}
              {isdataFounded && <div className='no-result2' style={{ marginTop: "25%" }}>Please Favorite a property to list out here.</div>}
            </div>
          </div>
          {open && (
            <div className="col-lg-4 col-xl-4 col-md-4 mt-0 pe-0" >
              <IntrestedPropertyDetail data={agentDetails} itemDetail={getAgentDetails} similarProperty={similar} close={closeDetail} />
            </div>
          )}
        </div>
      </Layout>
    </>
  )
}

export default InterestedProperty

import * as React from 'react';
// import ForgetForm from '../../components/form/ForgetForm';
import { Link } from 'gatsby';
import Seo from "../../components/seo";
import NewPassWordForm from '../../components/form/newpasswordForm';

const NewPassword = () => {
    return (
    <div className='container forgotHeight position-relative'>
      <Seo title="Reset Password" />
      <div className='logoupdate position-absolute'>
                    <Link to="/" ><img className="register-logo" src="/images/keyweUpdatedlogo.png" alt="logo" /></Link></div>
        <div className='row bgWhite' id='ForgetPasswordContainerDiv' >
            <div className='col-lg-6 col-xl-6 col-md-6 p-0' id="leftsideform">
                <div className='register-leftsides'>
                </div>
            </div>
            <div className='col-lg-6 col-xl-6 col-md-6 p-0'>
                <div className='register-rightside'>
                    <div className='register-iconsection'>
                        <p className='registration font-weight-bold ForGEtPaSSSWord'>Reset Password</p>                       
                    </div>
                    <NewPassWordForm />
                </div>
            </div>
        </div>
    </div>
    )
}
export default NewPassword;
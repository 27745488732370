import React, { useEffect, useState } from "react"
import Layout from "../../components/Layout"
import * as styles from "../../pages/seller/sellerProp.module.css"
import "react-multi-carousel/lib/styles.css"
import "bootstrap/dist/css/bootstrap.css"
import { StaticImage } from "gatsby-plugin-image"
import { Card } from "react-bootstrap"
// import { Icon } from "@iconify/react"
// import { SocialMediaIconsReact } from "social-media-icons-react"
import Seo from "../../components/seo";
import { useDispatch, useSelector } from "react-redux"
import { claimAgent } from "../../state/claimAgentList"
import AvatarImage from "../../components/Avatar/avatarImage"
import ChatPopup from "../../components/popup/ChatPopup"
import { StreamChat } from "stream-chat";
import "stream-chat-react/dist/css/index.css";
import UnClaimPopup from "../../components/popup/UncliamPopup"
import Loader from "../../components/Loader"
import ToastPopup from "../../components/popup/ToastPopup"
import { agentUnavailable, leadsAdding, myLeads } from "../../components/Api/ListingApi"
import { Tab, Tabs, Row, Col, Button } from 'react-bootstrap';
import { leadsId } from "../../state/userSlice"

function MyLeads(props) {
  console.log(props);
  //Redux function //
  const userDetails = useSelector(state => state.user.userDetail)
  const chattingUserData = useSelector(state => state.dashboard.chat_disconnect)
  //   const claimAgentDetails = useSelector(state => state.claimAgent.result)
  //   const dispatch = useDispatch()

  // usesate declare //
  const [open, setOpen] = useState(false)
  const [item, setItem] = useState({})
  const [isActive, setIsActive] = useState(false);
  const [index, setIndex] = useState(0)
  const [chatAgent, setChatAgent] = useState({})
  const [chatUser, setChatUser] = useState({})
  const [chatId, setChatId] = useState("")
  const [loader, setLoader] = useState(false);
  //   const [uncliamDetails, setUncliamDetails] = useState({})
  const [openChat, setOpenChat] = useState(false)
  //   const [userSuccess, setUserSuccess] = useState(false)
  const [messages, setMessages] = useState("")
  const [successAgent, setSucessAgent] = useState(false);
  const [myLeadData, setMyLeadData] = useState("")

  // useEffect declare //
  // let dispatch = useDispatch();
  // useEffect(() => {

  //   dispatch(leadsId(window.location.href.split("/")[3]=="myleads" ? window.location.href.split("/")[4] !== undefined && window.location.href.split("/")[4]:"" ))

  // }, [])
  useEffect(() => {
    myLeadList()

  }, [])

  const myLeadList = async () => {
    let userId = localStorage.getItem('userId')
    let getParams = {
      id: userId
    }

    await myLeads(getParams).then((res) => {
      console.log("response my lead", res)
      setMyLeadData(res.data.data)

    })

  }
  const myLeadsId = useSelector(state => state.user.leadsId);
  console.log(myLeadsId);
  useEffect(() => {
    var id = localStorage.getItem("myLeadId")
    if (myLeadsId !== "" && myLeadsId[0] !== "" && myLeadsId[0] !== null && myLeadsId !== null) {
      console.log("id", id)
      let userId = localStorage.getItem('userId')
      let getParams = {
        user_id: userId,
        agent_id: myLeadsId[0]
      }
      leadsAdding(getParams).then((res) => {
        // if(res.data.status==200){
        myLeadList()
        // }
        console.log("responselead", res);
      }).catch((err) => {
        console.log(err);
      })
    }
    else {
      myLeadList()
    }
  }, [])
  // Details function //
  const toggleClick = (data, i) => {
    setIndex(i);
    setIsActive(true);
    setOpen(true)
    setItem(data)
  }
  // Image null function //
  const hanldeImage = (img) => {
    let data = img !== null && img.split('/')[0]
    if (data === "uploads") {
      return process.env.API_IMAGE_URL + img
    }
    else {
      return img
    }
  }
  //chat popup//
  const showChatDetail = () => {
    if (openChat === false) {
      setLoader(true);
    }
    else {
      setLoader(false);
    }
    setOpenChat(p => !p)
  }
  // Chat funcion //
  const handleChat = async (id, name, lastName, image) => {
    let names = name
    await chattingUserData !== null && chattingUserData.disconnectUser()
    if (openChat) {
      setOpen(false)
    }
    const apikey = process.env.STREAM_CHAT_APIKEY;
    const chatClient = StreamChat.getInstance(apikey);
    await chatClient.connectUser({ id: id, name: names, image: image }, chatClient.devToken(id));
    await chatClient.disconnectUser()
    setChatAgent({ id: id, name: names, image: image })
    setChatUser({ id: userDetails._id, name: userDetails.name, image: hanldeImage(userDetails.img) })
    setChatId(id + userDetails._id)
    showChatDetail()
  }

  // unclaim popop
  //   const toggleUserSuccess = () => setUserSuccess(p => !p)
  // toast popup//
  const toggleSuccessAgent = () => setSucessAgent(p => !p);
  // agent unavailable popup //
  const handleAgentStatus = async (data) => {
    let agentId = data.agent_id._id
    let userId = localStorage.getItem("userId")
    let getParams = {
      agent_id: agentId,
      user_id: userId
    }
    await agentUnavailable(getParams).then(res => {
      setMessages("Agent has been notified. You will hear back from the Agent shortly")
      toggleSuccessAgent()
    }).catch(error => {
      console.log(error)
    })
  }

  return (
    <Layout>
      <Seo title="My Leads" />
      <ToastPopup success={successAgent} toggleSuccess={toggleSuccessAgent} messages={messages} />
      {/* <UnClaimPopup userSuccess={userSuccess} toggleUserSuccess={toggleUserSuccess} data={uncliamDetails} /> */}
      <div>
        <Tabs defaultActiveKey="myleads" id="uncontrolled-tab-example" className="mb-3" style={{ borderBottom: '1px solid lightgrey', background: 'transparent', paddingBottom: "20px" }}>
          <Tab eventKey="myleads" title="My Leads">
            <div className="row mt-5 mb-5">
              {myLeadData.length !== 0 &&
                myLeadData.map((data, i) => (
                  data.agent_id !== null &&
                  <div className="col-md-4" style={{ width: "30%" }}>
                    <Card
                      key={i}
                      onClick={() => setItem(data, i)} onKeyPress={() => setItem(data, i)} className={styles.agentCarouselCard2} style={{
                        border: (isActive && index === i) ? "1px solid rgb(4, 144, 251)" : "1px solid white", borderRadius: "25px",
                        width: "94%",
                        height: "92%"
                      }} >
                      <div className={styles.favorite} onClick={toggleClick} onKeyPress={toggleClick} role="button" tabIndex="0">
                        {data.agent_id.img ?
                          <img src={hanldeImage(data.agent_id.img)} alt='tour2' className={styles.agentProfile} style={{ borderRadius: "50%" }} crossOrigin="anonymous" />
                          : <div className={styles.agentProfile}><AvatarImage fontSize={'85px'} data={data.agent_id.name ? data.agent_id.name : ''} /></div>
                        }
                      </div>
                      <div className={styles.CardItemRowSeller} style={{
                        display: "flex", flexDirection: "column", justifyContent: "space-between"
                      }}>
                        <div>
                          <div className="d-flex">
                            <p style={{ width: "30%", fontSize: "13px" }}>Name </p>
                            <p style={{ fontWeight: "bold", fontSize: "14px", marginLeft: "20px" }}>{data.agent_id.name}</p>
                          </div>
                          {data.agent_id.address_city !== null && data.agent_id.address_city !== "" &&
                            <div className="d-flex">
                              <p style={{ width: "30%", fontSize: "13px" }}>Location </p>
                              <p style={{ fontWeight: "bold", fontSize: "14px", marginLeft: "20px" }}>{(data.agent_id.address_city !== null && data.agent_id.address_city !== "") ? data.agent_id.address_city : "-"}</p>
                            </div>
                          }
                          {(data.agent_id.meta_id !== undefined && data.agent_id.meta_id !== null) && data.agent_id.meta_id.office_name !== null && data.agent_id.meta_id.office_name !== "" && data.agent_id.role !== "user" && data.agent_id.office_name !== null &&
                            <div className="d-flex">
                              <p style={{ width: "30%", fontSize: "13px" }}>Office Name </p>
                              <p style={{ fontWeight: "bold", fontSize: "14px", marginLeft: "20px" }}>{(data.agent_id.meta_id.office_name !== null && data.agent_id.meta_id.office_name !== "") ? data.agent_id.meta_id.office_name : "-"}</p>
                            </div>

                          }
                          {data.agent_id.phone_number !== null && data.agent_id.phone_number !== "" &&
                            <div className="d-flex">
                              <p style={{ width: "30%", fontSize: "13px" }}>Phone Number </p>
                              <p style={{ fontWeight: "bold", fontSize: "14px", marginLeft: "20px" }}>{(data.agent_id.phone_number !== null && data.agent_id.phone_number !== "") ? data.agent_id.phone_number : "-"}</p>
                            </div>
                          }
                          {data.agent_id.email !== null && data.agent_id.email !== "" &&
                            <div className="d-flex">
                              <p style={{ width: "30%", fontSize: "13px" }}>Email </p>
                              <p style={{ fontWeight: "bold", fontSize: "14px", marginLeft: "20px", wordWrap: "break-word", width: "70%" }}>{(data.agent_id.email !== null && data.agent_id.email !== "") ? data.agent_id.email : "-"}</p>
                            </div>
                          } {data.agent_id.meta_id !== undefined && data.agent_id.meta_id !== null && data.agent_id.meta_id.license_number !== null && data.agent_id.meta_id.license_number !== "" && data.agent_id.role !== "user" && data.agent_id.license_number !== null &&
                            <div className="d-flex">
                              <p style={{ width: "30%", fontSize: "13px" }}>License Number </p>
                              <p style={{ fontWeight: "bold", fontSize: "14px", marginLeft: "20px" }}>{(data.agent_id.meta_id.license_number !== null && data.agent_id.meta_id.license_number !== "") ? data.agent_id.meta_id.license_number : "-"}</p>
                            </div>
                          }
                          {data.agent_id.role !== null && data.agent_id.role !== "" && data.agent_id.role !== undefined &&
                            <div className="d-flex">
                              <p style={{ width: "30%", fontSize: "13px" }}>Role </p>
                              <p className="text-capitalize" style={{ fontWeight: "bold", fontSize: "14px", marginLeft: "20px" }}>{(data.agent_id.role !== null && data.agent_id.role !== "") ? data.agent_id.role : "-"}</p>
                            </div>
                          }
                          <div style={{ display: "flex" }} onClick={toggleClick} role="button" tabIndex="0" onKeyPress={toggleClick}>
                            <p style={{ width: "30%", fontSize: "13px" }}>Status </p>
                            <span style={{ marginTop: "4px" }}>
                              {data.agent_id.online_status === true ?
                                <StaticImage
                                  src="../../../static/images/Ellipse2.png"
                                  alt="Ellips"
                                  className={styles.ActiveIcon}
                                ></StaticImage> :
                                <div style={{ backgroundColor: "red", borderRadius: "50%", height: "12px", width: "12px" }}></div>
                              }

                            </span>
                            <span style={{ margin: "0 -3px 0px 2px", paddingLeft: "8px", fontWeight: "bold", fontSize: "14px" }}>
                              {data.agent_id.online_status === true ? "Active" : "In-active"}
                            </span>

                          </div> </div>
                        <div className="mb-4" style={{ textAlign: "center" }}>

                          <button className='btn w-100 primaryColor' id={styles.AgentSelectButton11} onClick={() => { data.agent_id.online_status === true ? handleChat(data.agent_id._id, data.agent_id.name, data.agent_id.last_name, data.agent_id.img ? hanldeImage(data.agent_id.img) : "") : handleAgentStatus(data) }}>
                            Chat
                          </button> </div>

                      </div>
                      <div>
                      </div>
                    </Card>
                    {/* <p className={styles.unClaim1} onClick={() => { toggleUserSuccess(); setUncliamDetails(data) }} onKeyDown={() => { toggleUserSuccess(); setUncliamDetails(data) }} aria-hidden >Release</p> */}
                  </div>
                ))}
              {myLeadData.length === 0 && <div className='no-result2' style={{ marginTop: "8%" }}>You don’t have any Leads now!</div>}
              <>
                {loader ? <Loader /> : null}
                {openChat && <ChatPopup show={openChat} toggleShow={showChatDetail} agent={chatAgent} user={chatUser} id={chatId} />}</>

            </div>
          </Tab>
        </Tabs>

      </div>


    </Layout>
  )
}

export default MyLeads

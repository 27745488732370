import React, { useState, useEffect } from "react"
import memberIcon from "../../../static/images/memberIcon.png"
import phonintrest from "../../../static/images/PhoneImage.png"
import msgint from "../../../static/images/ChatImage.png"
import Group236 from "../../../static/images/sharing.png"
import closebutton from "../../../static/images/Icon ionic-ios-close.png"
import * as styled from "../buyers/buyer.module.css"
import { Button, Card } from "react-bootstrap"
import { Link, navigate } from "gatsby";
import AvatarImage from "../../components/Avatar/avatarImage"
import ConnectAgentPopup from "../../components/popup/connectAgentPopup";
// import { useLocation } from '@reach/router';
import { PropertyStatus } from "../../components/Api/ListingApi"
import ChatPopup from "../../components/popup/ChatPopup"
import { useSelector } from "react-redux";
import { StreamChat } from "stream-chat";
import "stream-chat-react/dist/css/index.css";
import SharePopup from "../../components/popup/sharePopup"
import * as style1 from "../../pages/dashboard/detail.module.css"
import Loader from "../../components/Loader"
import Dummy from "../../../static/images/house4.png"
import PropMap from "../../components/Seller/PropMap"
import PropertyDetails from "../../components/popup/propertDetails"
import { createBrowserHistory } from 'history';

function ProspectDetail(props) {
    const agentDetails = props.prospectDetail
    const [open, setOpen] = useState(false)
    const showDetail = () => {
        setOpen(true)
    }
    // const location = useLocation();
    const history = createBrowserHistory();
    const [connect, setConnect] = useState(false);
    const toggleConnect = () => setConnect(p => !p);
    const [openProperty, setOpenProperty] = useState(false)
    const [propertyId, setPropertyId] = useState("")
    const [propertDetails, setPropertyDetails] = useState({})
    // propert details popup //
    const hanldeOpenDetails = (id, data) => {
        // setOpenProperty(p => !p)
        setPropertyId(id)
        setPropertyDetails(data)
        window.open(`/homedetails/${(data.address).replaceAll(", ", '-').replaceAll(" ", '-').replaceAll("#", '%23')}`, "_blank")// when popup open
    }
    const hanldeOpenDetailsClose = (id, data) => {
        setOpenProperty(p => !p)
        setPropertyId(id)
        setPropertyDetails(data)
        history.push(`/sellers`) // when popup open
    }

    // console.log("prospect details", agentDetails)
    // const [propStatus, setPropStatus] = useState(sss[])
    const chattingUserData = useSelector(state => state.dashboard.chat_disconnect)
    useEffect(() => {
        async function fetchData() {
            await getPropertystatus();
        } fetchData();
    }, []);

    const getPropertystatus = async () => {
        await PropertyStatus().then((res) => {
            // setPropStatus(res.data.data)
        }
        )
    }
    const [loader, setLoader] = useState(false);
    const [chatAgent, setChatAgent] = useState({})
    const [chatUser, setChatUser] = useState({})
    const [chatId, setChatId] = useState("")
    const userDetails = useSelector(state => state.user.userDetail);
    const [openChat, setOpenChat] = useState(false)
    const showChatDetail = () => {
        if (openChat === false) {
            setLoader(true);
        }
        else {
            setLoader(false);

        }
        setOpenChat(p => !p)
    }

    const hanldeImage = (img) => {
        let data = img !== null && img.split('/')[0]
        if (data === "uploads") {
            return process.env.API_IMAGE_URL + img
        }
        else {
            return img
        }
    }
    const handleChat = async (id, name, lastName, image) => {
        let names = name
        await chattingUserData !== null && chattingUserData.disconnectUser()
        const apikey = process.env.STREAM_CHAT_APIKEY;
        const chatClient = StreamChat.getInstance(apikey);
        await chatClient.connectUser({ id: id, name: names, image: image }, chatClient.devToken(id));
        await chatClient.disconnectUser()
        setChatAgent({ id: id, name: names, image: image })
        setChatUser({ id: userDetails._id, name: userDetails.name, image: hanldeImage(userDetails.img) })
        setChatId(id + userDetails._id)
        showChatDetail()
    }
    const [shows, setShows] = useState(false)
    const toggleShows = () => {
        setShows(p => !p)
    }
    const makeCall = (PhoneNumber) => {
        if (PhoneNumber !== null && PhoneNumber !== "") {
            window.location.href = 'tel://' + PhoneNumber;

        }
    }


    // console.log("agentDetails",agentDetails)

    return (
        <>
            <SharePopup shows={shows} toggleShows={toggleShows} id={agentDetails.property_id._id} />
            <ConnectAgentPopup data={agentDetails} cityDetail={agentDetails} location={props.prospectDetail.property_id.location} isActive={false} connect={connect} toggleConnect={toggleConnect} />
            {/* {openProperty && agentDetails !== {} && <PropertyDetails openProperty={openProperty} hanldeOpenDetails={hanldeOpenDetailsClose} id={agentDetails.property_id._id} propertDetails={agentDetails.property_id} />} */}
            <div>
                <div className="prospectback mb-5">
                    <Card className="prospectCard" >
                        <div className="row" style={{ alignItems: "center" }}>
                            <div className="col-md-5" style={{ textAlign: "right" }}>
                                {agentDetails.user_id.img ?
                                    <img src={process.env.API_IMAGE_URL + agentDetails.user_id.img} alt="mask193" className="sellerImageSide" style={{ borderRadius: "50%", marginRight: "18px" }} crossOrigin="anonymous" />
                                    : <>{agentDetails.user_id.name ? <div className="avatarImageCircle" style={{ margin: 'auto', marginRight: "18px" }}><AvatarImage fontSize={'38px'} data={agentDetails.user_id.name ? agentDetails.user_id.name : ""} /></div>
                                        : null}</>
                                }
                            </div>
                            <div className="col-md-6">
                                {agentDetails.user_id.is_private_profile === true ?
                                    <h6 className="buyerName text-capitalize" > {agentDetails.user_id.nick_name} </h6>
                                    :
                                    <h6 className="buyerName text-capitalize" > {agentDetails.user_id.name} </h6>}
                                <div style={{ cursor: "pointer" }} className={styled.PhoneChat}>
                                    <img src={msgint} alt="msgint" className="msgint1" aria-hidden onClick={() => { handleChat(agentDetails.user_id._id, agentDetails.user_id.name, agentDetails.user_id.last_name, agentDetails.user_id.img ? hanldeImage(agentDetails.user_id.img) : ""); }} onKeyPress={() => { handleChat(agentDetails.user_id._id, agentDetails.user_id.name, agentDetails.user_id.last_name, agentDetails.user_id.img ? hanldeImage(agentDetails.user_id.img) : ""); }} />
                                    {agentDetails.user_id.phone_number !== undefined && agentDetails.user_id.phone_number !== null && agentDetails.user_id.phone_number !== "" &&
                                        <span role="button" tabIndex="0" onClick={() => makeCall((agentDetails.user_id.phone_number !== undefined && agentDetails.user_id.phone_number !== null && agentDetails.user_id.phone_number !== "") ? agentDetails.user_id.phone_number : null)} onKeyPress={() => makeCall((agentDetails.user_id.phone_number !== undefined && agentDetails.user_id.phone_number !== null && agentDetails.user_id.phone_number !== "") ? agentDetails.user_id.phone_number : null)} style={{ cursor: "pointer" }} className={styled.tooltip}>
                                            <img src={phonintrest} alt="phonintrest" className={styled.phonintrest} />
                                            {agentDetails.user_id.phone_number !== null && agentDetails.user_id.phone_number !== "" && agentDetails.user_id.phone_number !== undefined ?
                                                <span className={styled.tooltiptext}>{agentDetails.user_id.phone_number}</span> : null

                                            }
                                        </span>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="interaddress">
                            <h6 style={{ fontFamily: "DejaVuSansBold", marginBottom: "12px" }}> {agentDetails.property_id.address.split(',').slice(0).shift()},</h6>
                            <p>{agentDetails.property_id.address.split(",").slice(1).join(",")}</p>
                            <p>${String(agentDetails.property_id.assessor_last_saleamount == null ? agentDetails.property_id.price.$numberDecimal : agentDetails.property_id.assessor_last_saleamount).replace(
                                /(.)(?=(\d{3})+$)/g,
                                "$1,"
                            )}</p>
                        </div>
                        <div>
                            <Button variant="primary" className={styled.transferbut} id={styled.TransferRequest} onClick={toggleConnect}>Transfer Request</Button>
                        </div>
                        <Button variant="primary" className={styled.viewbut2} id={styled.Viewbutton} onClick={showDetail}>
                            View Property Details
                        </Button>
                        {open ? (
                            <div >
                                <div className={styled.imagedetails}>
                                    {/* <Link to={`/dashboard/detail/${agentDetails.property_id._id}`} style={{ textDecoration: "none" }}> */}
                                    {agentDetails.property_id.thumbnail_image !== undefined ?
                                        <>
                                            {agentDetails.property_id.thumbnail_image !== null ?
                                                <>
                                                    {agentDetails.property_id.thumbnail_image !== null && agentDetails.property_id.thumbnail_image !== undefined && agentDetails.property_id.thumbnail_image.includes('mls') ? <img src={agentDetails.property_id.thumbnail_image !== undefined && hanldeImage(agentDetails.property_id.thumbnail_image)} alt="EditGroup1 " width={200} height={300} className={styled.inthomeImage} id={styled.sellerAvatar} onClick={() => hanldeOpenDetails(agentDetails.property_id._id, agentDetails.property_id)} /> :
                                                        <img src={agentDetails.property_id.thumbnail_image !== undefined && hanldeImage(agentDetails.property_id.thumbnail_image)} width={200} height={300} className={styled.inthomeImage} id={styled.sellerAvatar} alt="EditGroup1 " crossOrigin='anonymous' onClick={() => hanldeOpenDetails(agentDetails.property_id._id, agentDetails.property_id)} />}</>
                                                : <img src={Dummy} width={200} height={300} className={styled.inthomeImage} id={styled.sellerAvatar} alt="EditGroup1 " crossOrigin='anonymous' onClick={() => hanldeOpenDetails(agentDetails.property_id._id, agentDetails.property_id)} />}</> :
                                        <div >
                                            {/* <img className={styles.carouselImage} src={`https://maps.googleapis.com/maps/api/streetview?size=449x290&location=${data.property_id.address}&key=${process.env.FIREBASE_API_KEY}`} alt="EditGroup1 " aria-hidden crossOrigin='anonymous' /> */}
                                            <img src={`https://maps.googleapis.com/maps/api/streetview?size=449x290&location=${agentDetails.property_id.address}&key=AIzaSyCVpTy7dON49XuSmtftSLfjgw2QxTSia2M`} width={200} height={300} className={styled.inthomeImage} id={styled.sellerAvatar} alt="EditGroup1 " crossOrigin='anonymous' onClick={() => hanldeOpenDetails(agentDetails.property_id._id, agentDetails.property_id)} />

                                            {/* <PropMap lat={agentDetails.property_id.location.coordinates[1]} lng={agentDetails.property_id.location.coordinates[0]} /> */}
                                        </div>}

                                    {/* </Link> */}
                                    <img src={closebutton} alt="closebutton" className={styled.closebutton2} onClick={() => setOpen(false)} onKeyPress={() => setOpen(false)} aria-hidden />
                                    <div className="shareButton1" >
                                        <img src={Group236} alt="group236" className="shareIcon" onClick={toggleShows} onKeyPress={toggleShows} aria-hidden />
                                    </div>
                                    {agentDetails.property_id.listing_status === 3 &&
                                        <div className={styled.memberdetails}>
                                            <img src={memberIcon} alt="memberIcon" className={styled.memberIcon} />
                                            <button type="button" className={styled.memberbut2}>
                                                Exclusive
                                            </button>
                                        </div>
                                    }
                                </div>
                                <div className={styled.housedetail}>
                                    <div className="d-flex" style={{ marginLeft: "17px" }}>
                                        {/* {details.listing_status < 4 && */}
                                        <i className="fa fa-circle ClaimActiveIcon " style={{ color: agentDetails.property_id.listing_status === 0 ? "orange" : agentDetails.property_id.listing_status === 1 || agentDetails.property_id.listing_status === 2 ? "#03B45B" : agentDetails.property_id.listing_status === 4 ? "yellow" : agentDetails.property_id.listing_status === 6 ? "grey" : agentDetails.property_id.listing_status === 7 ? "red" : agentDetails.property_id.listing_status === 8 ? "red" : agentDetails.property_id.listing_status === 10 ? "gray" : "red" }} ></i>
                                        <p className={style1.housetext1}>{agentDetails.property_id.listing_status === 0 ? "Draft" : agentDetails.property_id.listing_status === 1 ? "Active" : agentDetails.property_id.listing_status === 2 ? "Active Contingent" : agentDetails.property_id.listing_status === 3 ? "Exclusive" : agentDetails.property_id.listing_status === 4 ? "Pending" : agentDetails.property_id.listing_status === 5 ? "Sold" : agentDetails.property_id.listing_status === 6 ? "Expired" : agentDetails.property_id.listing_status === 7 ? "Cancelled" : agentDetails.property_id.listing_status === 8 ? "Withdrawn" : agentDetails.property_id.listing_status === 10 ? "Off market" : "Keywe Exclusive"}</p>
                                        {/* } */}
                                    </div>

                                    <p className={styled.pricehouse} id={styled.priceOfhouse}>${String(agentDetails.property_id.assessor_last_saleamount === null ? agentDetails.property_id.price.$numberDecimal : agentDetails.property_id.assessor_last_saleamount).replace(
                                        /(.)(?=(\d{3})+$)/g,
                                        "$1,"
                                    )}</p>
                                </div>
                                <div className={styled.headContent} >
                                    <h5 className={styled.intaddess} id={styled.HeadContentAddress}>{agentDetails.property_id.address.split(',').slice(0).shift()},</h5>
                                    <h5 className={styled.intaddess} id={styled.HeadContentAddress}>{agentDetails.property_id.address.split(",").slice(1).join(",")}</h5>
                                </div>
                                {/* <div className={styled.dashboardActive2} id={styled.DashboardActiveIcon}>
                                <i className="fa fa-circle" style={{ color: "green" }}></i>
                                <p style={{ marginLeft: "5px" }} id={styled.ActiveShowing}>Actively Showing</p>
                            </div> */}
                                <div>
                                    <Card
                                        className={styled.detCard}
                                        id={styled.detCards}
                                        style={{
                                            borderRadius: "10px",
                                            height: "93px",
                                            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                            borderTop: "0px"
                                        }}>
                                        <div className="row m-0">
                                            <div className="col-md-4 cardtext2 mt-3">
                                                <p style={{ fontFamily: "DejaVuSansBold" }}>{agentDetails.property_id.beds}</p>
                                                <p style={{ color: "#898686" }}>Beds</p>
                                            </div>
                                            <div className="col-md-4 cardtext2 mt-3">
                                                <p style={{ fontFamily: "DejaVuSansBold" }}>{agentDetails.property_id.baths}</p>
                                                <p style={{ color: "#898686" }}>Baths</p>
                                            </div>
                                            <div className="col-md-4 cardtext2 mt-3">
                                                <p style={{ fontFamily: "DejaVuSansBold" }}>{agentDetails.property_id.square_feet.$numberDecimal}</p>
                                                <p style={{ color: "#898686" }}>Sq. Feet</p>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        ) : null}
                        {/* <div>
                        <h6 className="prospectmessage" id={styled.ProspectMessage}>Message</h6>
                    </div> */}
                    </Card>
                    <>
                        {loader ? <Loader /> : null}
                        {openChat && <ChatPopup show={openChat} toggleShow={showChatDetail} agent={chatAgent} user={chatUser} id={chatId} />}
                    </>
                </div>
            </div>
        </>
    )
}
export default ProspectDetail


import React from 'react';
import { Modal } from 'react-bootstrap';
import './popup.css'
import "react-toastify/dist/ReactToastify.css"
import AvatarImage from "../Avatar/avatarImage"


export default function AcceptPopup(props) {
    const { showAgent, toggleAgent, detail } = props;
    const hanldeImage = (img) => {
        let data = img !== null && img.split('/')[0]
        if (data === "uploads") {
            return process.env.API_IMAGE_URL + img
        }
        else {
            return img
        }
    }

    return (
        <div>
            <Modal show={showAgent} onHide={toggleAgent} className='special_modal ModalDiv'>
                <div className="PopUpBorderDiv">
                    <Modal.Header closeButton className="team-popup-margin teampopup-title" >
                        <Modal.Title className='form-field-label form-label-team '>Agent Details</Modal.Title>
                    </Modal.Header>
                    <hr />
                    <Modal.Body className="team-popup-margin pb-0 "  >
                        <div className='d-flex justify-content-center'>
                            <div className="col-md-3" onClick={toggleAgent} aria-hidden>
                                {detail !== undefined && detail !== undefined && detail !== null && detail.img ?
                                    <img src={hanldeImage(detail !== undefined && detail !== null && detail.img)} alt='tour2' className="tour" width={150} height={150} style={{ borderRadius: "75px" }} crossOrigin="anonymous" />
                                    : <div style={{ width: '75px', height: '75px', position: 'relative' }}><AvatarImage fontSize={'38px'} data={detail !== undefined && detail !== undefined && detail !== null && detail.name ? detail !== undefined && detail !== null && detail.name : ''} /></div>}
                            </div>
                        </div>
                        <div className='mt-3 d-flex'>
                            <p style={{ fontFamily: "DejaVuSansBold",paddingLeft:"40px" ,width:"34%" }}>Name</p>
                            <p style={{  paddingLeft: "50px"}}>{detail !== undefined && detail !== undefined && detail !== null && detail.name}</p>
                        </div>
                        <div className='d-flex '>
                            <p style={{ fontFamily: "DejaVuSansBold",paddingLeft:"40px" ,width:"34%" }}>Email</p>
                            <p style={{ paddingLeft: "50px" }}>{detail !== undefined && detail !== undefined && detail !== null && detail.email}</p>
                        </div>{
                            detail !== undefined && detail !== undefined && detail !== null && detail.phone_number !== null && detail.phone_number &&
                            <div className='d-flex' >
                                <p style={{ fontFamily: "DejaVuSansBold",paddingLeft:"40px" ,width:"34%" }}>Number</p>
                                <p style={{ paddingLeft: "50px"  }}>{detail !== undefined && detail !== undefined && detail !== null && detail.phone_number}</p>
                            </div>
                        }
                        {detail !== undefined && detail !== undefined && detail !== null && detail.address !== "" && detail.address !== null &&
                            <div className='d-flex' >
                                <p style={{ fontFamily: "DejaVuSansBold",paddingLeft:"40px" ,width:"34%" }}>Address</p>
                                <p style={{ paddingLeft: "50px" }}>{detail !== undefined && detail !== null && detail.address}</p>
                            </div>}
                        {detail !== undefined && detail !== undefined && detail !== null && detail.address_city !== "" && detail.address_city !== null &&
                            <div className='d-flex' >
                                <p style={{ fontFamily: "DejaVuSansBold",paddingLeft:"40px" ,width:"34%" }}>City</p>
                                <p style={{ paddingLeft: "50px" }}>{detail !== undefined && detail !== null && detail.address_city}</p>
                            </div>}
                        {detail !== undefined && detail !== undefined && detail !== null &&detail.license_number !== undefined &&  detail.license_number !== null && detail.license_number !== "" &&
                            <div className='d-flex' >
                                <p style={{ fontFamily: "DejaVuSansBold",paddingLeft:"40px" ,width:"34%" }}>License No</p>
                                <p style={{ paddingLeft: "50px" }}>{detail !== undefined && detail !== undefined && detail !== null && detail.license_number}</p>
                            </div>
                        }
                        {detail !== undefined && detail !== undefined && detail !== null &&detail.office_name !== undefined &&  detail.office_name !== null && detail.office_name !== "" &&
                            <div className='d-flex' >
                                <p style={{ fontFamily: "DejaVuSansBold",paddingLeft:"40px" ,width:"34%" }}>Office Name</p>
                                <p style={{ paddingLeft: "50px" }}>{detail !== undefined && detail !== undefined && detail !== null && detail.office_name}</p>
                            </div>
                        }
                    </Modal.Body>

                </div>
            </Modal>
        </div>
    )
}

import React, { useState } from 'react'
import { Card } from "react-bootstrap"
import * as styles from "../../pages/seller/sellerProp.module.css"
import { imageLike } from '../Api/ListingApi'
import fav from "../../../static/images/fav.png"
import fav_select from "../../../static/images/fav-select.png"
import mlsdotimage from "../../../static/images/mlsdotimage.png"
import { useSelector } from 'react-redux'
import Dummy from "../../../static/images/house4.png"
import PropMap from "../../components/Seller/PropMap"
import { Spinner } from 'react-bootstrap'
import axios from 'axios'
import { handleImage } from '../../common/GenericFunction'
import newBrokaragelogo from "../../../static/images/newBrokaragelogo.png"
import { handleCheckBrokarage } from '../../common/GenericFunction'

export default function Cards(props) {
    // console.log(Dummy);
    const [googleImg, setGoogleImg] = useState("")
    const [likes, setLikes] = useState(props.likes == true)
    const [spinner, setSpinner] = useState(false)
    const userDetails = useSelector(state => state.user.userDetail);
    let userId = localStorage.getItem('userId')
    const item = (props.data.property_id === undefined || props.data.property) ? props.data : props.data.property_id

    // console.log("item.thumbnail_image",(item.thumbnail_image ==="https://mlslmediav2.mlslistings.com/property/MLSL/81918261/8b8874d9a6f34887b2ef76c65ef607ce/2/2"),item.thumbnail_image )

    const likePathName = (window.location.pathname).replace(/\//gi, '');

    var addressData = item.address;
    var addressIndex = item.address !== undefined && item.address.lastIndexOf(",");
    var address1 = addressData !== undefined && addressData.substring(0, addressIndex);
    var address2 = addressData !== undefined && addressData.substring(addressIndex + 1);

    //split
    var addressSplit = addressData !== undefined && addressData.split(", ")
    var addressOne = addressSplit[0] + ","
    var addressTwo = addressSplit[1] + ", " + addressSplit[2]

    // console.log("addressOne", addressOne);
    // console.log("addressTwo", addressTwo);
    // console.log("addressData",addressData)




    const hanldeLike = (id) => {
        if (userId !== null) {
            setSpinner(true)
            imageLike(userId, id).then((res) => {
                setLikes(res.data.data.includes(id))
                setSpinner(false)
                if (props.isLiked == undefined && props.isLiked) {
                    props.reLoadPage("like")

                }
            })
        }
        else {
            props.logout()
        }
    }

    const hanldeImage = img => {
        let data = img !== null && img !== false ? img.split("/")[0] : ""
        if (data === "uploads") {
            return process.env.API_IMAGE_URL + img
        } else {
            return img
        }
    }

    const handleLikes = (data) => {
        // console.log("id details", props.handleLike !== undefined)
        if (props.handleLike !== undefined) {
            // console.log("id details data", props.id === undefined ? data : props.id)

            props.handleLike(props.id === undefined ? data : props.id)
        }
    }
    // const ImageCard=(address)=>{
    //     // console.log(address,"address");
    //  axios
    //     .get(`https://maps.googleapis.com/maps/api/streetview?size=449x290&location=${address!==undefined && address.replaceAll("#","")}&source=outdoor&key=AIzaSyCVpTy7dON49XuSmtftSLfjgw2QxTSia2M`, {
    //       headers: {
    //         "Content-Type": 'application/json;charset=UTF-8'
    //       }
    //     })
    //     .then((response) => {
    //     setGoogleImg(`https://maps.googleapis.com/maps/api/streetview?size=449x290&location=${address!==undefined && address.replaceAll("#","")}&source=outdoor&key=AIzaSyCVpTy7dON49XuSmtftSLfjgw2QxTSia2M`)
    //     //  console.log("response",response.status);

    //     })
    //     .catch((error) => {
    //         setGoogleImg(Dummy)
    //         // console.log("response",error)
    //     });
    //     return(
    //         googleImg
    //     )
    //  }
    //  var shouldUseCrossOrigin=""
    var dataValue = ""
    var shouldUseCrossOrigin = false
    const handleImage = (imgValue, address) => {

        if (imgValue == undefined || imgValue == "" || imgValue == null) {
            var address1 = address !== null && address !== undefined && address.replace("#", "")
            shouldUseCrossOrigin = false
            dataValue = `https://maps.googleapis.com/maps/api/streetview?size=449x290&location=${address1}&key=AIzaSyCVpTy7dON49XuSmtftSLfjgw2QxTSia2M`
            // console.log(dataValue);
            return dataValue
        }
        else {
            let data = imgValue !== null && imgValue.split('/')
            // console.log( data);
            if (imgValue !== null && imgValue.split('/')[2] == "gokeywe.com") {
                // console.log("gokeywe1");
                shouldUseCrossOrigin = true
                dataValue = imgValue


                return dataValue
            }
            if (imgValue !== null && imgValue.split('/')[0] == "uploads") {
                shouldUseCrossOrigin = true
                // console.log("gokeywe2");
                dataValue = process.env.API_IMAGE_URL + imgValue
                return dataValue
            }

            else {
                dataValue = imgValue
                // console.log("gokeywe3");
                shouldUseCrossOrigin = false
                // console.log(dataValue);
                return dataValue
            }
        }

    }

       return (
        <>

            <Card className={styles.carouselCard} style={{ borderRadius: "25px", cursor: "pointer", boxShadow: "0 1px 4px rgb(82 80 80 / 40%), inset 0 0 40px rgb(135 128 128 / 8%)", border: props.I === props.Index && props.Eindex === props.cindex ? "2px solid rgb(4, 144, 251)" : "1px solid white" }}>
                <div className={styles.favorite} >
                    {/* {item.thumbnail_image !== undefined && */}
                    {/* // <> */}
                    <img src={handleImage(item !== undefined && item.thumbnail_image, item !== undefined && item.address)} alt="EditGroup1 " onClick={() => { handleLikes(item); }} className={styles.carouselImage}{...(shouldUseCrossOrigin ? { crossOrigin: "anonymous" } : "")} />

                    {/* //</div>{/* {(item.thumbnail_image === null || item.thumbnail_image === "") ? */}
                    {/* //     (( likePathName !== "interestedproperty" &&likePathName !== "listproperty" && likePathName !== "dashboard") ? <img className={styles.carouselImage} src={`https://maps.googleapis.com/maps/api/streetview?size=449x290&location=${item.address}&source=outdoor&key=AIzaSyCVpTy7dON49XuSmtftSLfjgw2QxTSia2M`}  alt="EditGroup1 " onClick={() => handleLikes(item)} onKeyPress={() => handleLikes(item)} aria-hidden crossOrigin='anonymous' /> :
                            //         item.location.coordinates !== undefined && item.location.coordinates !== undefined &&
                            //         // <PropMap address={item.address} lat={parseFloat(item.location.coordinates[1])} lng={parseFloat(item.location.coordinates[0])} />
                            //         <img className={styles.carouselImage} src={ImageCard(item.address)} alt="EditGroup1 " onClick={() =>{ handleLikes(item);}} onKeyPress={() => handleLikes(item)} aria-hidden />

                            //     )
                            //     :
                            //     item.thumbnail_image !== undefined && item.thumbnail_image !== null && item !== null && item.thumbnail_image.includes('mls') ? <img src={(item !== null && item.thumbnail_image) !== undefined && hanldeImage(item !== null && item.thumbnail_image)} alt="EditGroup1 " onClick={() => handleLikes(item)} className={styles.carouselImage} aria-hidden /> :
                            //         <img className={styles.carouselImage} src={(item !== null && item.thumbnail_image) !== undefined && hanldeImage(item !== null && item.thumbnail_image)} alt="EditGroup1 " onClick={() => handleLikes(item)} crossOrigin='anonymous' aria-hidden />
                            // } */}
                    {/* ///* </> : */}
                    {/* // <div onClick={() => handleLikes(item)}>
                        //     {item.location !== undefined && item.location.coordinates !== undefined && item.location.coordinates !== undefined && item.location.coordinates[0] !== null && item.location.coordinates[0] !== null && */}
                    {/* //         // <PropMap address={item.address} lat={parseFloat(item.location.coordinates[1])} lng={parseFloat(item.location.coordinates[0])} />
                        //         <img className={styles.carouselImage} src={ImageCard(item.address)} alt="EditGroup1 " aria-hidden />
                        //     }
                        // </div> */}

                    {/* } */}
                    <div className={styles.favorite1} onClick={() => hanldeLike(item._id)} aria-hidden>
                        {(userDetails.role !== "agent" && (window.location.pathname.includes("dashboard") || window.location.pathname == "/" || window.location.pathname === "/interestedproperty")) &&
                            <div>
                                {spinner ?
                                    <Spinner animation="border" variant="secondary" style={{ margin: "5px 0px 0px 0px", height: "1.8rem", width: "1.8rem" }} /> :
                                    (likes) ?
                                        <img src={fav_select} style={{ margin: "5px 0px 0px 0px" }} alt="like"></img>
                                        : <img src={fav} style={{ margin: "5px 0px 0px 0px" }} alt="like"></img>
                                }
                            </div>
                        }
                        {/* {window.location.pathname === "/intrestedproperty" && <img src={fav_select} style={{ margin: "5px 0px 0px 0px" }} alt="like"></img>} */}
                    </div>
                </div>
                <div className="row m-0" onClick={() => handleLikes(item)} onKeyPress={() => handleLikes(item)}>
                    <div className="col-md-8 cardtext1">
                        {
                            (userId == null && item.brokerage_exclusive !== false && item.listing_status === 3) ?
                                <>
                                    {/* {console.log("{item.city}", item)} */}
                                    <p className={`${props.style !== undefined && props.style == false ? "addrList" : "addList"}`}>{item.city}</p>
                                    <p className={`${props.style !== undefined && props.style == false ? "addrList" : "addList"}`}> &nbsp;  &nbsp;</p> </>
                                :
                                <div>
                                    {(item.listing_status === 3 && item.brokerage_exclusive !== false && userDetails.role === "user" && handleCheckBrokarage(item.companyID,userDetails) && window.location.pathname !== "/listproperty") ?
                                        <>
                                            <p className={`${props.style !== undefined && props.style == false ? "addrList" : "addList"}`}>{item.city}</p>
                                            <p className={`${props.style !== undefined && props.style == false ? "addrList" : "addList"}`}> &nbsp;  &nbsp;</p> </>
                                        : <>
                                            {/* <p  className={`${props.style!==undefined && props.style==false ? "addrList":"addList"}`}>{address1},</p>
                                            <p  className={`${props.style!==undefined && props.style==false ? "addrList":"addList"}`}>{address2}</p></>}</div>} */}
                                            <p className='addrList'>{addressOne}</p>
                                            <p className='addrList'>{addressTwo}</p></>}</div>}
                    </div>

                    <div className="col-md-4 cardtext1">
                        <div className={styles.dashboardActive} id={styles.ActiveIconsStatus}>
                            {item.listing_status === 3 ?
                                ((item.brokerage_exclusive !== false && item.companyID === "1") ? <img src={mlsdotimage} alt='mlsdotimage' className='mlsImage' /> : <img src={newBrokaragelogo} alt='mlsdotimage' className='mlsImage' />) :
                                <i className="fa fa-circle" style={{ color: item !== null && (item.listing_status === 0 ? "orange" : item.listing_status === 1 ? "#03B45B" : item.listing_status === 2 ? "yellow" : item.listing_status === 4 ? "yellow" : item.listing_status === 5 ? "red" : item.listing_status === 6 ? "gray" : item.listing_status === 7 ? "red" : item.listing_status === 8 ? "red" : item.listing_status === 10 ? "gray" : "gray") }} ></i>
                            }
                            <p className={styles.listingStaus} style={{ fontSize: "14px" }}>{item !== null && (item.listing_status === 0 ? "Draft" : item.listing_status === 1 ? "Active" : item.listing_status === 2 ? "Active Contingent" : item.listing_status === 3 ? "Exclusive" : item.listing_status === 4 ? "Pending" : item.listing_status === 5 ? "Sold" : item.listing_status === 6 ? "Expired" : item.listing_status === 7 ? "Cancelled" : item.listing_status === 8 ? "Withdrawn" : item.listing_status === 10 ? "Off Market" : "Off Market")}</p>
                        </div>
                    </div>
                </div>
                {/* {console.log(item, "item")} */}
                <div className="row m-0" style={{ display: "flex", marginTop: "5px" }} onClick={() => handleLikes(item)} onKeyPress={() => handleLikes(item)}>
                    {item.listing_status == 5 ? <div className="col-md-6 ">
                        <p className={styles.homePrice} id={styles.pricedetail}>${String((item.sale_price !== undefined && item.sale_price.$numberDecimal !== null && item.sale_price.$numberDecimal !== "" && item.sale_price.$numberDecimal !== "0") ? item.sale_price.$numberDecimal : item.price.$numberDecimal
                        ).replace(
                            /(.)(?=(\d{3})+$)/g,
                            "$1,")}</p>
                    </div>
                        :
                        <div className="col-md-6 ">
                            <p className={styles.homePrice} id={styles.pricedetail}>${String(item.assessor_last_saleamount === null || item.assessor_last_saleamount === undefined ? (item !== null && (item.price !== null && item.price !== undefined) ? item.price.$numberDecimal : 0) : item.assessor_last_saleamount).replace(
                                /(.)(?=(\d{3})+$)/g,
                                "$1,")}</p>
                        </div>}
                    <div className="col-md-6 cardtext2 containers"  >
                        <div className={styles.beds}>
                            <div><p style={{ fontFamily: "DejaVuSansBold" }} className={styles.bedsstyle}>{item !== null && item.beds}</p>
                                <p style={{ color: "#898686" }} className={styles.bedsstyle2}>Beds</p>
                            </div>
                            <div>
                                <p style={{ fontFamily: "DejaVuSansBold" }} className={styles.bathsstyle}>{item !== null && item.baths}</p>
                                <p style={{ color: "#898686" }} className={styles.bathsstyle2}>Baths</p>
                            </div>
                            <div>
                                <p className={styles.sqftstyle}><b>{String(item !== null && item.square_feet !== undefined && item.square_feet.$numberDecimal).replace(
                                    /(.)(?=(\d{3})+$)/g, "$1,"
                                )}</b></p>
                                <p style={{ color: "#898686" }} className={styles.sqftstyle2}>Sq.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </>
    )
}

import React from "react";
import { Modal } from 'react-bootstrap';
// import * as style1 from '../../pages/dashboard/detail.module.css';
import { InlineShareButtons } from "sharethis-reactjs";
import { useLocation } from "@reach/router";
function SharePopup(props) {
  const { shows, toggleShows, id, address } = props;
  // console.log("addresssssss", address)
  const location = useLocation();

  return (
    <div  >
      <Modal show={shows} onHide={toggleShows} className='ModalDiv'>
        <div style={{ border: "1px solid rgb(4, 144, 251)", borderRadius: "13px" }}>
          <Modal.Header closeButton className="team-popup-margin teampopup-title "  >
            <Modal.Title className='form-field-label form-label-team1 '>Share with</Modal.Title>
          </Modal.Header>
          <Modal.Body className="team-popup-margin pb-0" style={{ height: "151px" }} >
            <InlineShareButtons

              config={{
                alignment: 'center',
                color: 'white',
                enabled: true,
                font_size: 22,
                language: 'en',
                networks: [
                  'whatsapp',
                  'linkedin',
                  'telegram',
                  'gmail',
                  'facebook',
                  'twitter',

                ],
                padding: 14,
                radius: 6,
                size: 60,
                url: location.origin + '/homedetails/' + id,
                description: 'custom text',
                title: 'custom title',
                message: 'custom email text',
                subject: 'custom email subject',
                username: 'custom twitter handle'
              }}
            />
          </Modal.Body>
        </div>
      </Modal>
    </div>
  )
}
export default SharePopup
import React, { useState, useEffect } from "react"
import Layout from "../../components/Layout";
import * as styles from "../../pages/seller/sellerProp.module.css";
import "react-multi-carousel/lib/styles.css"
import "bootstrap/dist/css/bootstrap.css";
import { Card } from "react-bootstrap"
import { Icon } from "@iconify/react"
import Seo from "../../components/seo";
import SelectAgentPopup from "../../components/popup/selectAgentPopup";
import { findAgentListDuplicate, agentUnavailable, cityNames, agentNameSearch } from "../../components/Api/ListingApi";
import Pagination from '../../components/Pagination';
import Loader from '../../components/Loader';
import AvatarImage from '../../components/Avatar/avatarImage';
import ChatPopup from "../../components/popup/ChatPopup"
import { useSelector } from "react-redux";
import { StreamChat } from "stream-chat";
import "stream-chat-react/dist/css/index.css";
import * as styled from "../buyers/buyer.module.css"
import AgentShowProfile from "../../components/popup/AgentShowProfile";
import ToastPopup from "../../components/popup/ToastPopup";
import SkeletonVerticalCard from "../../components/card/skeletonVerticalCard";
import Select from 'react-select';
import { Spinner } from "react-bootstrap";
import { Link } from "gatsby";


var currentPage = 1;
export default function Agent() {

  // Redux function //
  const userDetails = useSelector(state => state.user.userDetail);
  const chattingUserData = useSelector(state => state.dashboard.chat_disconnect);
  const initialState = {
    beforeClaim: false,
  }
  // UseState function //
  const [show, setShow] = useState(false);
  const [detail, setDetail] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loaders, setLoaders] = useState(false);
  const [agentId, setAgentId] = useState('')
  const [isActivee, setIsActivee] = useState(false);
  const [indexx, setIndexx] = useState(0);
  const [pagecount, setpagecount] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);
  const [searchName, setSearchName] = useState("")
  const [showChat, setShowChat] = useState(false)
  const [chatAgent, setChatAgent] = useState({})
  const [chatUser, setChatUser] = useState({})
  const [chatId, setChatId] = useState("")
  // const [counts, setCount] = useState('')
  const [showAgent, setShowAgent] = useState(false);
  const [details, setDetails] = useState({})
  const array = ["1", "2", "3", "4", "5", "6"]
  const [cityName, setCityName] = useState([])
  const [city, setCity] = useState("")
  const [claimpopup, setClaimPopup] = useState(initialState)
  const [success, setSucess] = useState(false);
  const [messages, setMessages] = useState("")
  const [selectedOption, setSelectedOption] = useState(null);
  const [agentName, setAgentName] = useState([])
  const [open, setOpen] = useState(false)
  const [spinner, setSpinner] = useState(false)


  //variable declare //
  var userId = localStorage.getItem("userId")

  // useEffect //
  useEffect(() => {
    async function fetchData() {
      setLoader(true);
      await listingApis();
      await cityList();
    } fetchData()
  }, [])

  // Agent profile popup //
  const toggleAgent = ((data) => {
    setShowAgent(p => !p)
    setDetails(data)
  })

  // Find agent Api //
  const listingApis = async (searchs, searchNames) => {
    let getParams = {
      page: currentPage,
      pagesize: 21,
      // search: searchs,
      sort_value: -1,
      sort_field: "_id",
      name_search: searchNames,
      city: searchs
    };
    setLoader(true);
    await findAgentListDuplicate(getParams)
      .then(async (res) => {
        setLoader(false)
        setpagecount(res.data.Paging.total_page);
        setSelectedPage(res.data.Paging.current_page - 1);
        setDetail(res.data.data)
        // setCount(res.data.Paging.total_items)

      }).catch((error) => {
        console.log(error)
      })
  }

  // Search function //
  const handleSearch = (data) => {
    // console.log(selectedOption);
    currentPage = 1
    listingApis(data, searchName);
  }

  // Search function //
  // const handleSearchName = () => {
  //   currentPage = 1
  //   listingApis(city, searchName);
  // }
  const handleSearchName = (name) => {
    if (name !== "") {
      currentPage = 1
      listingApis(city, name);
    } else {
      currentPage = 1
      listingApis(city, searchName);
    }
  }

  //select agent popup //
  const toggleShow = () => {
    setShow(p => !p)
  }
  // pagination //
  async function handlePageClick(event) {
    currentPage = event.selected + 1;
    await listingApis(city, searchName, selectedPage);
  };
  // image null handle //
  const hanldeImage = (img) => {
    let data = img !== null && img.split('/')[0]
    if (data === "uploads") {
      return process.env.API_IMAGE_URL + img
    }
    else {
      return img
    }
  }
  // const handleKeySearch = (event) => {
  //   if (event.key === 'Enter') {
  //     handleSearch()
  //   }
  // }

  // name search function //
  const handleKeySearchName = (event) => {
    if (event.key === 'Enter') {
      handleSearchName()
    }
  }

  // chat popup //
  const toggleShowChat = () => {
    if (showChat === false) {
      setLoaders(true);
    }
    else {
      setLoaders(false);
    }
    setShowChat(p => !p)
  }

  // Chat function //
  const handleChat = async (id, name, lastName, image) => {
    let names = name
    await chattingUserData !== null && chattingUserData.disconnectUser()
    const apikey = process.env.STREAM_CHAT_APIKEY;
    const chatClient = StreamChat.getInstance(apikey);
    await chatClient.connectUser({ id: id, name: names, image: image }, chatClient.devToken(id));
    await chatClient.disconnectUser()
    setChatAgent({ id: id, name: names, image: image })
    setChatUser({ id: userDetails._id, name: userDetails.name, image: userDetails.img ? hanldeImage(userDetails.img) : "" })
    setChatId(userDetails._id + id)
    toggleShowChat()
    setSpinner(false)
  }

  // card select function //
  const handleChange1 = (i, e) => {
    setIndexx(i)
    setIsActivee(true);
  }

  // Toast Popup //
  const toggleSuccess = () => setSucess(p => !p);

  // Login alert function //
  const loginAlert = () => {
    setClaimPopup({ ...claimpopup, beforeClaim: !claimpopup.beforeClaim })
  }

  // Agent unavailble API integration //
  const handleAgentStatus = async (data) => {
    if (userId === null) {
      loginAlert()
      setTimeout(
        () => setClaimPopup({ ...claimpopup, beforeClaim: false }),
        3000
      )
    } else {
      var getParams = {
        agent_id: data._id,
        user_id: userId
      }
      await agentUnavailable(getParams).then(res => {
        setMessages("Agent has been notified. You will hear back from the Agent shortly")
        toggleSuccess()
      }).catch(error => {
        console.log(error)
      })
    }
  }

  // City list API integration //
  const cityList = async () => {
    await cityNames().then((res) => {
      // console.log("response", res.data.data)
      setCityName(res.data.data)

    }).catch(error => {
      console.log(error)
    })
  }

  // search option function //
  const handleChange = (selectedOption, e) => {
    setSelectedOption(selectedOption);
    setCity(selectedOption.value)
    handleSearch(selectedOption.value)
  };
  // city name map function //
  const options = cityName.map((item) => ({
    value: item.CityName,
    label: item.CityName,
  }));
  const handleName = async (e) => {
    // console.log(e.target.value);
    if (searchName !== "") {
      let getPrarams = {
        search: e.target.value
      }
      // console.log("getPrarams", getPrarams)
      await agentNameSearch(getPrarams).then((res) => {
        // console.log("respose age", res.data.data.map((item) => item))
        setAgentName(res.data.data)

      }).catch((err) => {
        console.log(err)
        setAgentName([])
      })
    }
  }
  const option = [{
    value: "",
    label: "",
  }]
  return (
    <>
      <Layout>
        {loaders ? <Loader /> : null}
        <Seo title="Find an Agent" />
        {showAgent && <AgentShowProfile showAgent={showAgent} toggleAgent={toggleAgent} detail={details} />}
        <SelectAgentPopup show={show} toggleShow={toggleShow} agentId={agentId} />
        <ToastPopup success={success} toggleSuccess={toggleSuccess} messages={messages} />
        {showChat && <ChatPopup show={showChat} toggleShow={toggleShowChat} agent={chatAgent} user={chatUser} id={chatId} />}
        <div className="row" style={{ cursor: "default" }}>
          <div className="col-md-1">
            {/* space pupose */}
          </div>
          <div className="col-md-11 ps-4">
            <div className={styles.search}>
              <div className="me-4 mt-2">
                <p className="mb-1  ">Search City</p>
                <div style={{ width: "214px", height: "46px" }} className="d-flex position-relative align-items-center">
                  <Select
                    className="border-none form-control rounded-pill selectDropdown"
                    value={selectedOption}
                    onChange={(e) => handleChange(e)}
                    options={loader ? option : options}
                    name=""
                    isSearchable={true}
                    placeholder="Select City..."
                    onFocus={() => setOpen(false)}
                  />
                  {/* <input type="search" className="border-none form-control br-10" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="City Name" onKeyDown={handleKeySearch} />
                  <span className="position-absolute right-10 cursor-pointer" onClick={handleSearch} onKeyPress={handleSearch} role="button" tabIndex="0"><i className="fa fa-search" aria-label="search"></i></span> */}
                </div>
              </div>
              <div className="mt-2">
                <p className="mb-1 ">Name</p>
                <div className="d-flex position-relative align-items-center">
                  <input type="search" className="border-none form-control rounded-pill" value={searchName} onChange={(e) => { setSearchName(e.target.value); handleName(e); }} placeholder="Agent Name" onKeyDown={handleKeySearchName} style={{ position: "relative" }} onFocus={() => setOpen(true)} />
                  {open && agentName.length !== 0 ?
                    <div className='agentNameSearch' style={{ position: "absolute", height: "auto", width: "215px", background: "white", zIndex: 1, overflow: "auto", top: "44px" }}>
                      {agentName.length !== 0 ? agentName.map((item, i) => (
                        <p onClick={() => { handleSearchName(item.name); setSearchName(item.name); setOpen(false) }} style={{ padding: '8px 15px', cursor: "pointer", marginBottom: "0px", color: searchName === item.name ? "white" : "", background: searchName === item.name ? "#4B91FB" : "" }} key={i} value={item.name}>{item.name}</p>
                      )) : <p style={{ textAlign: "center", paddingTop: "13px", color: "#414141" }}>No Results found</p>}
                    </div>
                    : null}
                  <span className="position-absolute right-10 cursor-pointer" onClick={handleSearchName} onKeyPress={handleSearchName} role="button" tabIndex="0"><i className="fa fa-search" aria-label="search" ></i></span>
                </div>
              </div>
            </div>
            <hr />
            <div>
              {detail.length !== 0 && <p className={styles.agenthead}>Agents</p>}
              <div className="row">
                {loader ?
                  array.map((item) => (
                    <div className='col-md-4 mb-4' tabIndex="0">
                      <SkeletonVerticalCard purpose={"agent"} />
                    </div>
                  ))
                  :
                  detail.map((data, i) => (data.user_id !== userId &&
                    <div className='col-md-4 mb-4' onClick={() => setAgentId(data._id)} onKeyPress={() => setAgentId(data._id)} key={i} role="button" tabIndex="0" style={{ cursor: "default" }}>
                      <Card
                        className={styles.agentCarouselCard2}
                        onClick={(e) => { handleChange1(i, e); }}
                        style={{
                          borderRadius: "25px",
                          width: "94%",
                          height: "100%",
                          border: (isActivee && indexx === i) ? "1px solid rgb(4, 144, 251)" : "1px solid white",
                          boxShadow:
                            "0 1px 4px rgb(82 80 80 / 40%), inset 0 0 40px rgb(135 128 128 / 8%)",
                          cursor: "default"
                        }}
                      >
                        <Link to="/agentDetails" style={{ textDecoration: "none" }} state={{ props: data, check: "user" }} >
                          <div className={styles.favorite} role="button" tabIndex="0" style={{ cursor: "default" }}>
                            {data.img ?

                              (data.img !== undefined && data.img.split('/')[0] === "https:" ? <img src={data.img !== undefined && hanldeImage(data.img)} alt="agent" className={styles.agentProfile1} style={{ borderRadius: "50%" }} /> :
                                <img alt="agent" className={styles.agentProfile1} src={data.img !== undefined && hanldeImage(data.img)} style={{ borderRadius: "50%", cursor: "pointer" }} crossOrigin='anonymous' />)
                              : <div className={styles.agentProfile1} style={{ cursor: "pointer" }}><AvatarImage fontSize={'80px'} data={data.name ? data.name : ''} width={200} height={300} className={styled.inthomeImage} id={styled.sellerAvatar} /></div>
                            }
                          </div></Link>

                        <div className={styles.CardItemRowSeller} style={{
                          display: "flex", flexDirection: "column", justifyContent: "space-between"
                        }}>
                          <div>
                            <div className="d-flex">
                              <p style={{ width: "30%", fontSize: "13px" }}>Name </p>
                              <p style={{ fontWeight: "bold", fontSize: "14px" }}>{(data.name !== null && data.name !== "") ? data.name : "-"}</p>
                            </div>
                            {data.address_city !== null && data.address_city !== "" &&
                              <div className="d-flex">
                                <p style={{ width: "30%", fontSize: "13px" }}>City</p>
                                <p style={{ fontWeight: "bold", fontSize: "14px" }}>{(data.address_city !== null && data.address_city !== "") ? data.address_city : "-"}</p>
                              </div>
                            }
                            {data.office_name !== null && data.office_name !== "" &&
                              <div className="d-flex">
                                <p style={{ width: "30%", fontSize: "13px" }}>Office Name </p>
                                <p style={{ fontWeight: "bold", fontSize: "14px" }}>{(data.office_name !== null && data.office_name !== "") ? data.office_name : "-"}</p>
                              </div>
                            }
                            {data.email !== null && data.email !== "" &&
                              <div className="d-flex">
                                <p style={{ width: "30%", fontSize: "13px" }}>Email </p>
                                <p style={{ fontWeight: "bold", fontSize: "14px" }}>{(data.email !== null && data.email !== "") ? data.email : "-"}</p>
                              </div>
                            }
                            {data.phone_number !== null && data.phone_number !== "" &&
                              <div className="d-flex">
                                <p style={{ width: "30%", fontSize: "13px" }}>Phone Number </p>
                                <p style={{ fontWeight: "bold", fontSize: "14px" }}>{(data.phone_number !== null && data.phone_number !== "") ? data.phone_number : "-"}</p>
                              </div>
                            }
                            {data.license_number !== null && data.license_number !== "" &&
                              <div className="d-flex">
                                <p style={{ width: "30%", fontSize: "13px" }}>License Number </p>
                                <p style={{ fontWeight: "bold", fontSize: "14px" }}>{(data.license_number !== null && data.license_number !== "") ? data.license_number : "-"}</p>
                              </div>
                            }
                            {(data.user_id !== null && data.agent_id !== null) &&
                              <div className="d-flex">
                                <p style={{ width: "30%", fontSize: "13px" }}>Status</p>
                                <i className="fa fa-circle" style={{ color: "green" }} />
                                <p style={{ fontWeight: "bold", fontSize: "14px", marginLeft: "5px" }}>Active</p>

                              </div>
                            }
                          </div>
                          <div>
                            {(data.user_id !== null && data.agent_id !== null) &&
                              <div style={{ display: "flex", margin: "5px", justifyContent: "space-between" }}>
                                <button className='btn w-100 primaryColor' id={styles.AgentSelectButton1} onClick={(data) => toggleShow(data)}>
                                  Select Agent
                                </button>
                                <div className='btn outline-primary ' id={styles.AgentSelectButton2} >
                                  <div className={styles.active} id={styles.ChatIconsName} role="button" tabIndex="0" onClick={() => { setSpinner(true); handleChat(data.user_id !== null && data.user_id._id, data.name, data.last_name, data.img ? hanldeImage(data.img) : "") }} onKeyPress={() => handleChat(data.user_id !== null && data.user_id, data.name, data.last_name, data.img ? hanldeImage(data.img) : "")} >
                                    <div className={styles.chatIcons1}>
                                      {spinner ?
                                        <Spinner animation="border" variant="secondary" style={{ margin: "5px 0px 0px 0px", height: "0.8rem", width: "0.8rem" }} /> :
                                        <Icon icon="bi:chat" />}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                            {data.user_id == null &&
                              <div style={{ textAlign: "center" }}>

                                <button className='btn w-100 primaryColor' id={styles.AgentSelectButton11} onClick={() => handleAgentStatus(data)}>
                                  Connect with Agent
                                </button> </div>
                            }
                          </div>
                        </div>
                      </Card>
                    </div>
                  ))}
              </div>
            </div>
            {detail.length === 0 && <div className='resulttext position-relative'>
              <div className='no-result2' style={{ marginTop: "20%" }}>No Results Found</div>
            </div>}
          </div>
          {detail.length > 0 ? <Pagination pagecount={pagecount} handlePageClick={handlePageClick} forcepage={selectedPage} /> : null}
        </div>
      </Layout >
    </>
  )
}

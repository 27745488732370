// extracted by mini-css-extract-plugin
export var Calendar = "detail-module--Calendar--9ed64";
export var CardTextDiv = "detail-module--CardTextDiv--822a4";
export var ConnectWithAgent = "detail-module--ConnectWithAgent--d235b";
export var ContentPara = "detail-module--ContentPara--0e3b3";
export var DejaVuSansBold = "detail-module--DejaVuSansBold--6e0fc";
export var Editsubmitbut = "detail-module--Editsubmitbut--e96e0";
export var RefrelFee = "detail-module--RefrelFee--3986e";
export var TourDetailHomeImage = "detail-module--TourDetailHomeImage--0887b";
export var ViewBut3d = "detail-module--ViewBut3d--42f77";
export var accepttext = "detail-module--accepttext--1651d";
export var activeClaim = "detail-module--activeClaim--e4580";
export var activity = "detail-module--activity--28101";
export var addHead = "detail-module--addHead--5c83d";
export var additionalbut = "detail-module--additionalbut--a98e2";
export var additionalbut2 = "detail-module--additionalbut2--fd824";
export var additionalbut3 = "detail-module--additionalbut3--22c2b";
export var agentText = "detail-module--agentText--3bb1c";
export var agentText2 = "detail-module--agentText2--d247f";
export var agentsubBut = "detail-module--agentsubBut--d0f16";
export var barChart = "detail-module--barChart--17aa2";
export var buttonss = "detail-module--buttonss--d83a5";
export var calbutton = "detail-module--calbutton--a946a";
export var calbutton1 = "detail-module--calbutton1--ad655";
export var calendarIcon = "detail-module--calendarIcon--b8067";
export var cancetourbut = "detail-module--cancetourbut--5fdef";
export var card = "detail-module--card--e3f9b";
export var chatActive = "detail-module--chatActive--3632b";
export var chatIconns = "detail-module--chatIconns--9196b";
export var chatbut = "detail-module--chatbut--62980";
export var chatdetail = "detail-module--chatdetail--e5793";
export var chatinput = "detail-module--chatinput--d1336";
export var chatsCard = "detail-module--chatsCard--af259";
export var circle2 = "detail-module--circle2--9bbb3";
export var claimOwner = "detail-module--claimOwner--62d70";
export var claimbut = "detail-module--claimbut--da640";
export var clmbut = "detail-module--clmbut--54a02";
export var clockIcon = "detail-module--clockIcon--1e90c";
export var detCard = "detail-module--detCard--4bed7";
export var detCard2 = "detail-module--detCard2--77c09";
export var detCardprop = "detail-module--detCardprop--3d7c0";
export var detCards = "detail-module--detCards--be251";
export var detCards2 = "detail-module--detCards2--ca8e4";
export var detailIcon = "detail-module--detailIcon--c9c8a";
export var detailText = "detail-module--detailText--64ad4";
export var divCarousel = "detail-module--div-carousel--c207a";
export var editButton = "detail-module--editButton--7ab23";
export var editSelect22 = "detail-module--editSelect22--420c3";
export var faSearch = "detail-module--fa-search--4836d";
export var fileUpload = "detail-module--fileUpload--db2fe";
export var fileUploadBrowse = "detail-module--fileUploadBrowse--2410a";
export var fileUploadClaim = "detail-module--fileUploadClaim--67438";
export var fileUploadbox = "detail-module--fileUploadbox--ef8ae";
export var fileuploadbox = "detail-module--fileuploadbox--5f8ae";
export var groupaddbut = "detail-module--groupaddbut--451ea";
export var groupdetail = "detail-module--groupdetail--e1f97";
export var homes1 = "detail-module--homes1--02994";
export var houseSale = "detail-module--houseSale--83283";
export var houseSale1 = "detail-module--houseSale1--496fb";
export var houseSale2 = "detail-module--houseSale2--84a20";
export var housetext = "detail-module--housetext--806ab";
export var housetext1 = "detail-module--housetext1--070ed";
export var imagetext = "detail-module--imagetext--7a30d";
export var incredibletext = "detail-module--incredibletext--02644";
export var interestedPrice = "detail-module--interestedPrice--cedab";
export var listingdetail = "detail-module--listingdetail--5d4c9";
export var mask38 = "detail-module--mask38--5d47d";
export var mask381 = "detail-module--mask381--7a091";
export var memberIcon = "detail-module--memberIcon--cf5c5";
export var memberSale = "detail-module--memberSale--aa231";
export var memberSale1 = "detail-module--memberSale1--8dcf9";
export var memberSale2 = "detail-module--memberSale2--e6ba0";
export var memberbut = "detail-module--memberbut--daf47";
export var memberbutt = "detail-module--memberbutt--232cb";
export var memberdetails = "detail-module--memberdetails--04f45";
export var mlsagentCard = "detail-module--mlsagentCard--f17ae";
export var modalContent = "detail-module--modal-content--ee7cf";
export var modalDialog = "detail-module--modal-dialog--f4b6a";
export var modelbox = "detail-module--modelbox--aacb1";
export var modelparent = "detail-module--modelparent--0bd34";
export var namedet = "detail-module--namedet--7ece0";
export var nobutton = "detail-module--nobutton--ad609";
export var offerbut = "detail-module--offerbut--68619";
export var otherbut = "detail-module--otherbut--b860d";
export var ownerFileName = "detail-module--ownerFileName--687fc";
export var ownership = "detail-module--ownership--6206a";
export var phoneClaim = "detail-module--phoneClaim--59743";
export var phoneNumber = "detail-module--phoneNumber--b1290";
export var phoneText = "detail-module--phoneText--6a567";
export var photosendbut = "detail-module--photosendbut--82a32";
export var photosendbut1 = "detail-module--photosendbut1--51d1d";
export var photosendtext = "detail-module--photosendtext--6a975";
export var photosendtext1 = "detail-module--photosendtext1--0e4c6";
export var popupparentSelect = "detail-module--popupparentSelect--1ac13";
export var popupsearch = "detail-module--popupsearch--0bd92";
export var popupselect = "detail-module--popupselect--c75ad";
export var port = "detail-module--port--05592";
export var profileDetail = "detail-module--profileDetail--a8bda";
export var profileImage = "detail-module--profileImage--38709";
export var profileName = "detail-module--profileName--6bad9";
export var propCard = "detail-module--propCard--20d61";
export var propImage = "detail-module--propImage--278dc";
export var propertyImage = "detail-module--propertyImage--d4594";
export var refsubmitbut = "detail-module--refsubmitbut--5a091";
export var reportbut = "detail-module--reportbut--03f5e";
export var schedulbut = "detail-module--schedulbut--93a3a";
export var search = "detail-module--search--5b670";
export var searchIcon = "detail-module--searchIcon--b6c67";
export var searchbox = "detail-module--searchbox--151d2";
export var selectAgent = "detail-module--selectAgent--65dd2";
export var selectAgentbutton = "detail-module--selectAgentbutton--fbb8b";
export var selectRadius = "detail-module--selectRadius--c252e";
export var selectdet = "detail-module--selectdet--bcc31";
export var selectdistance = "detail-module--selectdistance--65312";
export var selecttime1 = "detail-module--selecttime1--54ca0";
export var selecttime2 = "detail-module--selecttime2--58303";
export var sendimage = "detail-module--sendimage--756dd";
export var simCard = "detail-module--simCard--a56d3";
export var simhead = "detail-module--simhead--c2905";
export var simheadCard = "detail-module--simheadCard--cff58";
export var simheadCard1 = "detail-module--simheadCard1--08dd8";
export var similar = "detail-module--similar--92d89";
export var similarCard = "detail-module--similarCard--18fb4";
export var similarback = "detail-module--similarback--7517a";
export var similarproperties = "detail-module--similarproperties--d1a38";
export var simproperties = "detail-module--simproperties--82a45";
export var stBtn = "detail-module--st-btn--01e4c";
export var stRemoveLabel = "detail-module--st-remove-label--eaf28";
export var successtext = "detail-module--successtext--3b09d";
export var successtext2 = "detail-module--successtext2--6eb5b";
export var text2 = "detail-module--text2--fc4fa";
export var textnotes = "detail-module--textnotes--078b6";
export var timebut1 = "detail-module--timebut1--6a80a";
export var timebut2 = "detail-module--timebut2--fe5fc";
export var timesubbut = "detail-module--timesubbut--0348a";
export var tooltip = "detail-module--tooltip--ec15d";
export var tourAddress = "detail-module--tourAddress--3d779";
export var tourAddressText = "detail-module--tourAddressText--525fb";
export var tourCard = "detail-module--tourCard--e06aa";
export var tourDetailcard = "detail-module--tourDetailcard--0a717";
export var tourHead = "detail-module--tourHead--886aa";
export var tourName = "detail-module--tourName--e71af";
export var tourbut = "detail-module--tourbut--b5641";
export var tourconfirm = "detail-module--tourconfirm--d1252";
export var tourdetails = "detail-module--tourdetails--54339";
export var tourdetailtext = "detail-module--tourdetailtext--70392";
export var tourimage = "detail-module--tourimage--a3f1b";
export var tourimage1 = "detail-module--tourimage1--cbf48";
export var toursearch = "detail-module--toursearch--ad11d";
export var toursearchIcon = "detail-module--toursearchIcon--9145a";
export var tourtext = "detail-module--tourtext--adaf6";
export var tourtext1 = "detail-module--tourtext1--bd154";
export var updatehome = "detail-module--updatehome--944c8";
export var updatehomeprice = "detail-module--updatehomeprice--076ab";
export var updatetourbut = "detail-module--updatetourbut--6686e";
export var upload = "detail-module--upload--6da11";
export var uploadOwner = "detail-module--uploadOwner--f4467";
export var view = "detail-module--view--7930c";
export var viewbut = "detail-module--viewbut--35d6b";
export var viewdetails = "detail-module--viewdetails--48bc6";
export var viewmytourbut = "detail-module--viewmytourbut--46dc1";
export var viewtext = "detail-module--viewtext--d05b9";
export var week = "detail-module--week--f1901";
export var yesbutton = "detail-module--yesbutton--eb769";
export var yesbutton1 = "detail-module--yesbutton1--daabc";
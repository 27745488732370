import React, { useState, useEffect } from 'react';
import { Tab, Tabs, Row, Col, Button } from 'react-bootstrap';
import Layout from '../../components/Layout';
import { claimPropertyList, getOffers, recentlySold } from "../../components/Api/ListingApi";
import SkeletonHorizondalCard from "../../components/card/skeletonHorizondalCard";
import mlsdotimage from "../../../static/images/mlsdotimage.png";
import newBrokaragelogo from "../../../static/images/newBrokaragelogo.png"
// import PropMap from '../../components/Seller/PropMap3';
import MyListDetail from './MyListDetail';
// import searchIcon from '../../../static/images/Icon feather-search.png';
// import AddPropertyImage from '../../../static/images/AddPropertyImage.png';
import { Link } from "gatsby";
import "./MyList.css"
import Pagination from '../../components/Pagination';
// import axios from 'axios';
// import Dummy from "../../../static/images/house4.png"
// import { handleImage } from '../../common/GenericFunction';
import Seo from "../../components/seo";

var currentPage = 1;
export default function MyList() {
    //useState declare //
    const [loader, setLoader] = useState(false);
    const [myListData, setMyListData] = useState([]);
    // console.log("myListData", myListData)
    const [isMyList, setIsMyList] = useState(false);
    const [myListDetails, setMyListDetails] = useState({});
    const [anotherProps, setAnotherProps] = useState(false);
    const [offer, setOffer] = useState({});
    const [recentSold, setRecentSold] = useState([]);
    const [open, setOpen] = useState(false);
    const [index, setIndex] = useState(0);
    const [isActive, setIsActive] = useState(false);
    const [pagecount, setpagecount] = useState(0);
    const [paginationCount, setpaginationCount] = useState(0);
    const [selectedPage, setSelectedPage] = useState(0);
    const [propertyStatus, setPropertyStatus] = useState("")
    const [verifyData, setVerifyData] = useState(false)
    const array = ["1", "2", "3", "4"]
    // console.log("verifyData", verifyData)

    //usEffect declare //
    useEffect(() => {
        async function fetchData() {
            setLoader(true);
            await myList(propertyStatus, currentPage)
            setLoader(false);
            // await getAgentSellerDetails(search);
        } fetchData()
    }, []);


    const myList = async (status, filterPage) => {
        setpaginationCount(filterPage)
        let userId = localStorage.getItem('userId')
        let getParams = {
            status: 'Approve',
            id: userId,
            pagesize: 20,
            page: filterPage,
            sort_field: "_id",
            sort_value: -1,
            listing_status: status
        }
        // console.log("getParams", getParams)
        setLoader(true);
        await claimPropertyList(getParams).then((res) => {
            console.log('my list', res.data.data)
            setLoader(false);
            // setIsMyList(false)
            if (res.status === 200) {
                // setVerifyData("mytours")
                // console.log("my list", res.data.data)
                setpagecount(res.data.Paging.total_page);
                setSelectedPage(res.data.Paging.current_page - 1);
                setMyListData(res.data.data)
            }

        })

    }

    const handleReload = () => {
        myList(propertyStatus, currentPage)
    }


    async function handlePageClick(event) {
        currentPage = event.selected + 1;
        // setpaginationCount(currentPage);
        setIsMyList(false)
        await myList(propertyStatus, currentPage);
    };

    const hanldeImage = (img) => {
        let data = img !== null && img !== undefined && img.split('/')[0]
        if (data === "uploads") {
            return process.env.API_IMAGE_URL + img
        }
        else {
            return img
        }
    }
    const OpnAnotherProps = () => {
        setAnotherProps(!anotherProps)
    }
    useEffect(() => {
        async function fetchData() {
            setMyListDetails(myListData[index])
        } fetchData()
    }, [myListData])

    const offerProperty = async (data) => {
        let id = (data !== undefined ? data.property._id : null);
        await getOffers(id).then((res) => {
            if (res.status === 200) {
                // setVerifyData("mytours")
                setOffer(res.data.data)
            }
        })
    }

    // const recentlySoldComparable = async (data) => {
    //     let id = data.property._id
    //     let getParams = {
    //         longitude: data.property.location !== undefined ? data.property.location.coordinates[0] : data.property.property_latitude,
    //         latitude: data.property.location !== undefined ? data.property.location.coordinates[1] : data.property.property_longitude,
    //     }
    //     // setLoader(true)
    //     await recentlySold(id, getParams).then(res => {
    //         if (res.status === 200) {
    //             setVerifyData(true)
    //             setRecentSold(res.data.data)
    //             let data = [...res.data.data]
    //         }
    //         // setLoader(false)
    //         // data !== null && data.length !== 0 ? setisDataFounded(false) : setisDataFounded(true)
    //     }).catch((error) => {
    //         console.log(error);
    //     });
    // };


    const handleClick = async (data, i) => {
        setMyListDetails(data)
        await offerProperty(data);
        // recentlySoldComparable(data)
        setOpen(true);
        setIndex(i);
        setIsActive(true);
        setIsMyList(true);
    }

    var dataValue = ""
    var shouldUseCrossOrigin = false
    const handleImage = (imgValue, address) => {

        if (imgValue == undefined || imgValue == "") {
            shouldUseCrossOrigin = false
            dataValue = `https://maps.googleapis.com/maps/api/streetview?size=449x290&location=${address.replaceAll("#", "")}&key=AIzaSyCVpTy7dON49XuSmtftSLfjgw2QxTSia2M`
            // console.log(dataValue);
            return dataValue
        }
        else {
            let data = imgValue !== null && imgValue.split('/')
            // console.log( data);
            if (imgValue !== null && imgValue.split('/')[2] == "gokeywe.com") {
                // console.log("gokeywe1");
                shouldUseCrossOrigin = true
                dataValue = imgValue
                return dataValue
            }
            if (imgValue !== null && imgValue.split('/')[0] == "uploads") {
                shouldUseCrossOrigin = true
                // console.log("gokeywe2");
                dataValue = process.env.API_IMAGE_URL + imgValue
                return dataValue
            }

            else {
                dataValue = imgValue
                // console.log("gokeywe3");
                shouldUseCrossOrigin = false
                // console.log(dataValue);
                return dataValue
            }
        }

    }

    return (
        <Layout>
            <Seo title="myList" />
            <div>
                <div className='searchBuy'>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {/* <div style={{ padding: '0px 10px' }}>
                            <input type="search" id="gsearch" placeholder="Search City" className="buyer-search" />
                            <div className="searchIconAgent" >
                                <img src={searchIcon} alt="Icon feather-search.png" className='search-icon-img' aria-hidden />
                            </div>
                        </div> */}
                        <div className='mt-0' style={{ marginRight: "15px" }}>
                            {/* <select onChange={(e) => { offerStatusUpdate(e.target.value) }} value={offerStatus} className="form-select propectstatus" name="calender" id="calender"> */}
                            <select className="form-select propectstatus2" onChange={(e) => { myList(e.target.value, 1); setPropertyStatus(e.target.value) }} name="calender" id="calender">
                                <option value="">Property Status</option>
                                <option value="0">Draft</option>
                                <option value="1">Active</option>
                                <option value="2">Active Contingent</option>
                                <option value="3">Exclusive</option>
                                <option value="4">Pending</option>
                                <option value="5">Sold</option>
                                <option value="6">Expired</option>
                                <option value="7">Cancelled</option>
                                <option value="8">Withdrawn</option>
                                <option value="10">Off market</option>
                            </select>
                        </div>
                        <div>
                            <Link to="/myList/AddProperty" >
                                <Button className='addListbut mt-2'>Add Listing</Button>
                            </Link>
                        </div>
                    </div>
                </div>
                <Tabs defaultActiveKey="myList" id="uncontrolled-tab-example" className="mb-3" style={{ borderBottom: '1px solid lightgrey', background: 'transparent', paddingBottom: "20px" }}>
                    <Tab eventKey="myList" title="My Listing">
                        <div >
                            <div className='row mb-5 position-relative'>
                                <div className="col-md-8" style={{ height: "750px", overflow: "auto" }}>
                                    {loader ? array.map((item) => (<SkeletonHorizondalCard purpose={"myList"} />)) :
                                        myListData.map((data, i) =>
                                            <Row key={i} onClick={() => { handleClick(data, i); }} className='sellercardRow' style={{ border: (isActive && index === i) ? "1px solid rgb(4, 144, 251)" : "1px solid white" }}>
                                                {/* {console.log("data",data.property)} */}
                                                <div className='col-lg-3'>
                                                    {/* {data.property.thumbnail_image !== undefined && data.property.thumbnail_image !== null && data.property.thumbnail_image !== "" && data.property.thumbnail_image.includes('mls') ? <img src={data.property.thumbnail_image !== undefined && hanldeImage(data.property.thumbnail_image)} alt="EditGroup1 " style={{ width: '100%', height: '159px', borderRadius: '10px', objectFit: "cover" }}  /> : */}
                                                    <img src={handleImage(data.property !== undefined && data.property.thumbnail_image, data.property !== undefined && data.property.address)} alt="EditGroup1 " style={{ width: '100%', height: '159px', borderRadius: '10px', objectFit: "cover" }} {...(shouldUseCrossOrigin ? { crossOrigin: "anonymous" } : "")} />
                                                    {/* 
                                                    {data.property !== null && data.property !== undefined && data.property.thumbnail_image !== undefined && data.property.thumbnail_image !== null && data.property.thumbnail_image !== "" ?
                                                        <>
                                                            {data.property.thumbnail_image !== null && data.property.thumbnail_image !== "" && data.property.thumbnail_image !== undefined && data.property.thumbnail_image.includes('mls') ? <img src={data.property.thumbnail_image !== undefined && hanldeImage(data.property.thumbnail_image)} alt="EditGroup1 " style={{ width: '100%', height: '159px', borderRadius: '10px', objectFit: "cover" }} /> :
                                                            
                                                                <img src={data.property.thumbnail_image !== undefined && data.property.thumbnail_image} crossOrigin='anonymous' alt="EditGroup11 " style={{ width: '100%', height: '159px', borderRadius: '10px', objectFit: "cover" }} />}
                                                                {console.log(" data.property.thumbnail_image", data.property.thumbnail_image)}
                                                                </> :
                                                        <div >
                                                            {data.property !== null && data.property !== undefined && data.property.location &&
                                                                // (data.property.source == "mls" ?
                                                                // <PropMap lng={data.property.location.coordinates[0]} lat={data.property.location.coordinates[1]} valueFor="seller" />
                                                                <img src={`https://maps.googleapis.com/maps/api/streetview?size=400x400&location=${data.property.address}&key=AIzaSyCVpTy7dON49XuSmtftSLfjgw2QxTSia2M`} alt="EditGroup10 " style={{ width: '100%', height: '159px', borderRadius: '10px', objectFit: "cover" }} />
                                                                // <img src={`https://maps.googleapis.com/maps/api/streetview?size=449x290&location=${data.property.address}&key=${process.env.FIREBASE_API_KEY}`} alt="EditGroup10 " style={{ width: '100%', height: '159px', borderRadius: '10px', objectFit: "cover" }} />
                                                                // :
                                                                // <img src={`https://maps.googleapis.com/maps/api/streetview?size=449x290&location=${data.property.address}&key=${process.env.FIREBASE_API_KEY}`} alt="EditGroup2 " style={{ width: '100%', height: '159px', borderRadius: '10px', objectFit: "cover" }} />)

                                                            }
                                                        </div>} */}
                                                </div>
                                                <Col lg="3">
                                                    {data.property !== null && data.property !== undefined && data.property.owner_first_name !== undefined && data.property.owner_first_name !== null && data.property.owner_first_name !== "" &&
                                                        <div>
                                                            <h6>Name</h6>
                                                            <h6 className='sellername'>{(data.property !== undefined && data.property !== null && data.property.owner_first_name) + " " + (data.property !== undefined && data.property !== null && data.property.owner_last_name)
                                                            }</h6>
                                                        </div>}
                                                    <div>
                                                        <h6>Address</h6>
                                                        <h6 className='selleraddress'>{data.property !== undefined && data.property.address.split(',').slice(0).shift()},</h6>
                                                        <h6 className='selleraddress'>{data.property !== undefined && data.property.address.split(",").slice(1).join(",")}</h6>
                                                    </div>
                                                </Col>
                                                <Col lg="3">
                                                    <h6>Property Status</h6>
                                                    {data.property !== undefined &&
                                                        <div className='d-flex align-items-baseline'>
                                                            <>{data.property.listing_status === 3 ?
                                                                ((data.property.companyID === "1" && data.property.brokerage_exclusive !== false) ? < img src={mlsdotimage} alt='mlsdotimage' style={{ width: "20px", height: "20px" }} /> : < img src={newBrokaragelogo} alt='newBrokaragelogo' style={{ width: "20px", height: "20px" }} />) :
                                                                <i className="fa fa-circle ClaimActiveIcon" style={{ color: data.property.listing_status === 0 ? "orange" : data.property.listing_status === 1 ? "#03B45B" : data.property.listing_status === 2 ? "yellow" : data.property.listing_status === 4 ? "yellow" : data.property.listing_status === 5 ? "red" : data.property.listing_status === 7 ? "gray" : data.property.listing_status === 6 ? "gray" : data.property.listing_status === 8 ? "gray" : data.property.listing_status === 9 ? "#0490fb" : "gray" }} ></i>
                                                            }</>
                                                            <h6 className='propStatus' style={{ marginLeft: "10px" }}>{data.property.listing_status === 0 ? "Draft" : data.property.listing_status === 1 ? "Active" : data.property.listing_status === 2 ? "Active Contingent" : data.property.listing_status === 3 ? "Exclusive" : data.property.listing_status === 4 ? "Pending" : data.property.listing_status === 5 ? "Sold" : data.property.listing_status === 6 ? "Expired" : data.property.listing_status === 7 ? "Cancelled" : data.property.listing_status === 8 ? "Withdrawn" : data.property.listing_status === 9 ? "Key Exclusive" : data.property.listing_status === 10 ? "Off market" : "Off market"}</h6>
                                                        </div>}
                                                </Col>
                                                <Col lg="3">
                                                    <h6>List Price</h6>
                                                    {data.property.listing_status == 5 ? <h6 className='sellerlistprice'>${String(data.property !== undefined && data.property !== null && data.property.sale_price !== null && data.property.sale_price !== undefined && data.property.sale_price.$numberDecimal == 0 ? data.property.sale_price.$numberDecimal : data.property !== undefined && data.property.sale_price !== null && data.property.sale_price !== undefined && data.property.sale_price.$numberDecimal).replace(
                                                        /(.)(?=(\d{3})+$)/g,
                                                        "$1,"
                                                    )}</h6>
                                                        :
                                                        <h6 className='sellerlistprice'>${String(data.property !== undefined && data.property !== null && data.property.price !== null && data.property.price !== undefined && data.property.price.$numberDecimal == 0 ? data.property.price.$numberDecimal : data.property !== undefined && data.property.price !== null && data.property.price !== undefined && data.property.price.$numberDecimal).replace(
                                                            /(.)(?=(\d{3})+$)/g,
                                                            "$1,"
                                                        )}</h6>}
                                                </Col>
                                            </Row>)}

                                    <div className='resulttext'>
                                        {myListData.length == 0 && loader == false && <div className='no-resultSell mt-5'> Result not found</div>}
                                    </div>


                                </div>
                                {isMyList && myListDetails !== undefined && (
                                    <div className='col-md-4'>
                                        <MyListDetail data={myListDetails} reload={handleReload} anotherPros={OpnAnotherProps} offers={offer} propertyStatus={propertyStatus} currentPage={paginationCount} refresh={myList} recentSold={recentSold} verifyData={verifyData} close={() => setIsMyList(p => !p)} />
                                    </div>
                                )}
                            </div>
                            {(myListData.length !== 0) ? <Pagination pagecount={pagecount} handlePageClick={handlePageClick} forcepage={selectedPage} /> : null}
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </Layout>
    )
}

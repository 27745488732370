import React, { useState, useEffect, useRef } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link, navigate } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';
import { signupPost } from '../Api/formApi';
import { mlsAgentSearch, leadsAdding } from "../Api/ListingApi"
// import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../Loader';
import VisibilityOffSharpIcon from '@mui/icons-material/VisibilityOffSharp';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import ToastPopup from "../popup/ToastPopup";
import { useSelector } from "react-redux"

export default function RegistrationForm() {
    const initialValues = {
        name: '',
        last_name: "",
        email: '',
        password: '',
        accept: false
    }
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [loader, setLoader] = useState(false);
    const [success, setSucess] = useState(false);
    const [messages, setMessages] = useState("")
    const toggleSuccess = () => setSucess(p => !p);
    const inputElement = useRef(null);
    const [passwordType, setPasswordType] = useState("password");
    const myLeadsId = useSelector(state => state.user.leadsId);
    useEffect(() => {
        if (inputElement.current) {
            inputElement.current.focus();
        }
    }, []);

    useEffect(() => {
        document.body.classList.add("form-bg");
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            handleSubmitFirebase();
        }
    }, [formErrors]);

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text");
            return;
        }
        setPasswordType("password");
    }



    const handleSubmitFirebase = async () => {
        try {
            setLoader(true);

            const { email, password, name, last_name } = formValues;

            var postDatas = {
                search: email,
            };
            mlsAgentSearch(postDatas).then((res) => {
                console.log("res response", res)
                if (res.data.status !== 0) {
                    setMessages("Email found in the MLS database. Please sign up as a real estate agent")
                    toggleSuccess()
                    setLoader(false);
                } else {
                    firebase
                        .auth()
                        .createUserWithEmailAndPassword(email, password)
                        .then(function (result) {
                            var uid = result.user.uid;
                            if (result) {
                                setMessages("Please verify your email address")
                                toggleSuccess()
                                // firebase.auth().currentUser.sendEmailVerification()
                                //     .then(() => {
                                //         setMessages("Please verify your email address")
                                //         toggleSuccess()
                                //     });

                                var postData = {
                                    name: name,
                                    last_name: last_name,
                                    uid: uid,
                                    email: email,
                                    provider: "local",
                                    role: "user",
                                    password: password,

                                };


                                signupPost(postData)
                                    .then((res) => {
                                        // console.log("res register", res.data.data)
                                        if (myLeadsId !== "" && myLeadsId[0] !== "" && myLeadsId[0] !== null && myLeadsId !== null) {
                                            let getParams = {
                                                user_id: res.data.data._id,
                                                agent_id: myLeadsId[0]
                                            }
                                            leadsAdding(getParams).then((res) => {
                                                // console.log("responselead", res);
                                            }).catch((err) => {
                                                console.log(err);
                                            })
                                        }

                                        if (res.status === 200) {
                                            setTimeout(() => {
                                                setLoader(false);

                                                navigate("/signin");
                                            }, 1000)

                                        }
                                    })
                                    .catch((err) => {
                                        setLoader(false);
                                        setMessages(err.message)
                                        toggleSuccess()
                                    })
                            }

                        }).catch(function (error) {
                            setLoader(false);
                            setMessages(error.message)
                            toggleSuccess()
                        });
                }
            })
        } catch (err) {
            setLoader(false);
            setMessages(err.message)
            toggleSuccess()
        }

    }



    const onSubmit = () => {
        // e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
        // handleCheckAgent()
    };

    const validate = (values) => {
        const errors = {};
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!values.name) {
            errors.name = 'First Name is Required';
        }
        if (!values.last_name) {
            errors.last_name = 'Last Name is Required';
        }
        if (!values.email) {
            errors.email = 'Please enter your Email';
        } else if (!regex.test(values.email)) {
            errors.email = 'Please enter a valid Email address';
        }
        if (!values.password) {
            errors.password = 'Please enter your password';
        } else if (values.password.length < 8) {
            errors.password = 'Password must be minimum 8 characters';
        }
        if (!values.accept) {
            errors.accept = 'You must agree with the terms and conditions';
        }
        return errors;
    };


    const formValChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value.trim() });
        validates(name, value)
    };

    const validates = (name, value) => {
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (name === 'name') {
            if (value === '') {
                setFormErrors({ ...formErrors, 'name': 'First Name is Required' })
            }
            else { setFormErrors({ ...formErrors, 'name': '' }) }
        } else if (name === 'last_name') {
            if (value === '') {
                setFormErrors({ ...formErrors, 'last_name': 'Last Name is Required' })
            }
            else { setFormErrors({ ...formErrors, 'last_name': '' }) }
        }
        else if (name === 'email') {
            if (value === '') {
                setFormErrors({ ...formErrors, 'email': 'Email is Required' })
            }
            else if (!regex.test(value)) {
                setFormErrors({ ...formErrors, 'email': 'Please enter a valid Email address' })
            }
            else { setFormErrors({ ...formErrors, 'email': '' }) }
        }
        else if (name === 'password') {
            if (value === '') {
                setFormErrors({ ...formErrors, 'password': 'Password is Required' })
            }
            else if (value.length < 8) {
                setFormErrors({ ...formErrors, 'password': 'Password must be minimum 8 characters' })
            }
            if (value.length > 30) {
                setFormErrors({ ...formErrors, 'password': 'Password maximum length is 30 characters' })
            }
            else { setFormErrors({ ...formErrors, 'password': '' }) }
        }
    }

    return (
        <div className='register-formsection'>
            <ToastPopup success={success} toggleSuccess={toggleSuccess} messages={messages} />
            {loader ? <Loader /> : null}
            {/* <Form  > */}
            <Form.Group controlId="name" >
                <Form.Label className="form-field-label" style={{ fontSize: "18px", fontFamily: "DejaVuSansBold" }}>First Name<span className='error-msg'> *</span></Form.Label>
                <Form.Control name="name" value={formValues.name} onChange={formValChange} className="form-control Registerformstyle SignUpForm SignUpForm0 InputErrorStyle SignUPFormagent" type="text" maxLength={20} placeholder='Enter First Name' style={{ borderRadius: "8px" }} ref={inputElement} />
            </Form.Group>
            {formErrors.name !== '' && <p className='error-msg ErrorMessage1'>{formErrors.name}</p>}

            <Form.Group controlId="name" >
                <Form.Label className="form-field-label" style={{ fontSize: "18px", fontFamily: "DejaVuSansBold" }}>Last Name<span className='error-msg'> *</span></Form.Label>
                <Form.Control name="last_name" value={formValues.last_name} onChange={formValChange} className="form-control Registerformstyle SignUpForm SignUpForm0 InputErrorStyle SignUPFormagent" type="text" maxLength={20} placeholder='Enter Last Name' style={{ borderRadius: "8px" }} />
            </Form.Group>
            {formErrors.name !== '' && <p className='error-msg ErrorMessage1'>{formErrors.last_name}</p>}

            <Form.Group controlId="email" >
                <Form.Label className="form-field-label" style={{ fontSize: "18px", fontFamily: "DejaVuSansBold" }}>Email<span className='error-msg'> *</span></Form.Label>
                <Form.Control name="email" value={formValues.email} onChange={formValChange} className="form-control  Registerformstyle SignUpForm SignUpForm0 InputErrorStyle SignUPFormagent" type='text' maxLength={50} placeholder='Email Address' style={{ borderRadius: "8px" }} />
            </Form.Group>
            {formErrors.email !== "" && <p className='error-msg ErrorMessage1'>{formErrors.email}</p>}
            <Form.Group controlId="password" >
                <Form.Label className="form-field-label" style={{ fontSize: "18px", fontFamily: "DejaVuSansBold" }}>Password<span className='error-msg'> *</span></Form.Label>
                <div style={{ display: "flex" }}>
                    <Form.Control name="password" value={formValues.password} onChange={formValChange} className="form-control  Registerformstyle SignUpForm InputErrorStyle SignUPFormagent" type={passwordType} placeholder='Password' style={{ borderRadius: "8px" }} />
                    <p style={{ margin: "18px 0 0px -39px", cursor: "pointer" }} >
                        {passwordType === "password" ? (
                            <VisibilitySharpIcon onClick={togglePassword} />
                        ) : (
                            <VisibilityOffSharpIcon onClick={togglePassword} />
                        )}
                    </p>
                </div>


            </Form.Group>
            {formErrors.password !== '' && <p className='error-msg ErrorMessage1'>{formErrors.password}</p>}
            <Form.Group controlId='accept' className="ReadandAccept InputErrorStyle">
                <label>
                    <Form.Check type="checkbox" id="rcheckbox" name="accept" defaultChecked={formValues.accept} onChange={() => { setFormValues({ ...formValues, ['accept']: !formValues.accept }); }} style={{ display: "inline-block", paddingRight: "5px" }} />
                    <Form.Text className='term-accept AcceptTerms' style={{ cursor: "pointer" }}>I have read and accepted the &nbsp;</Form.Text></label><Form.Text className='term-accept AcceptTerms '><Link to="/termscondition/" className="terms-conditions" style={{ textDecoration: "none" }}>Terms and Conditions.</Link></Form.Text>
            </Form.Group>
            {formValues.accept && isSubmit ? '' : <p className='error-msg ErrorMessage1'>{formErrors.accept}</p>}
            <Button type='submit' className='signup-submit-btn Registerformstyle SignUpForm' onClick={onSubmit}>Sign up</Button>

            {/* </Form> */}

        </div>
    );
}

import React, { useEffect, useState } from "react"
import Layout from "../../components/Layout"
import * as styles from "../../pages/seller/sellerProp.module.css"
import "react-multi-carousel/lib/styles.css"
import "bootstrap/dist/css/bootstrap.css"
import { StaticImage } from "gatsby-plugin-image"
import { Card } from "react-bootstrap"
// import { Icon } from "@iconify/react"
// import { SocialMediaIconsReact } from "social-media-icons-react"
import Seo from "../../components/seo";
import { useDispatch, useSelector } from "react-redux"
import { claimAgent } from "../../state/claimAgentList"
import AvatarImage from "../../components/Avatar/avatarImage"
import ChatPopup from "../../components/popup/ChatPopup"
import { StreamChat } from "stream-chat";
import "stream-chat-react/dist/css/index.css";
import UnClaimPopup from "../../components/popup/UncliamPopup"
import Loader from "../../components/Loader"
import ToastPopup from "../../components/popup/ToastPopup"
import { agentUnavailable, claimAgentList, leadsAdding } from "../../components/Api/ListingApi";
import { Link } from "gatsby";


function MyAgent() {
  //Redux function //
  const userDetails = useSelector(state => state.user.userDetail)
  const chattingUserData = useSelector(state => state.dashboard.chat_disconnect)
  // const claimAgentDetails = useSelector(state => state.claimAgent.result)
  const dispatch = useDispatch()

  // usesate declare //
  const [open, setOpen] = useState(false)
  const [item, setItem] = useState({})
  const [isActive, setIsActive] = useState(false);
  const [index, setIndex] = useState(0)
  const [chatAgent, setChatAgent] = useState({})
  const [chatUser, setChatUser] = useState({})
  const [chatId, setChatId] = useState("")
  const [loader, setLoader] = useState(false);
  const [uncliamDetails, setUncliamDetails] = useState({})
  const [openChat, setOpenChat] = useState(false)
  const [userSuccess, setUserSuccess] = useState(false)
  const [messages, setMessages] = useState("")
  const [successAgent, setSucessAgent] = useState(false);

  // useEffect declare //
  // useEffect(() => {
  //   let userId = localStorage.getItem('userId')
  //   async function fetchData() {
  //     dispatch(claimAgent(userId))
  //   } fetchData()
  // }, [])

  const [agentData, setAgentData] = useState([])

  useEffect(() => {
    agentList()
  }, [])

  const agentList = async () => {
    let userId = localStorage.getItem('userId')
    await claimAgentList(userId).then((res) => {
      // console.log("agenrLIst", res)
      setAgentData(res.data.data)
    }).catch((error) => {
      console.log(error)
    })
  }

  // Details function //
  const toggleClick = (data, i) => {
    setIndex(i);
    setIsActive(true);
    setOpen(true)
    setItem(data)
  }
  // Image null function //
  const hanldeImage = (img) => {
    let data = img !== null && img.split('/')[0]
    if (data === "uploads") {
      return process.env.API_IMAGE_URL + img
    }
    else {
      return img
    }
  }
  //chat popup//
  const showChatDetail = () => {
    if (openChat === false) {
      setLoader(true);
    }
    else {
      setLoader(false);
    }
    setOpenChat(p => !p)
  }
  // Chat funcion //
  const handleChat = async (id, name, lastName, image) => {
    let names = name
    await chattingUserData !== null && chattingUserData.disconnectUser()
    if (openChat) {
      setOpen(false)
    }
    const apikey = process.env.STREAM_CHAT_APIKEY;
    const chatClient = StreamChat.getInstance(apikey);
    await chatClient.connectUser({ id: id, name: names, image: image }, chatClient.devToken(id));
    await chatClient.disconnectUser()
    setChatAgent({ id: id, name: names, image: image })
    setChatUser({ id: userDetails._id, name: userDetails.name, image: hanldeImage(userDetails.img) })
    setChatId(id + userDetails._id)
    showChatDetail()
  }

  // unclaim popop
  const toggleUserSuccess = () => setUserSuccess(p => !p)
  // toast popup//
  const toggleSuccessAgent = () => setSucessAgent(p => !p);
  // agent unavailable popup //
  const handleAgentStatus = async (data) => {
    let agentId = data.agent_id._id
    let userId = localStorage.getItem("userId")
    let getParams = {
      agent_id: agentId,
      user_id: userId
    }
    await agentUnavailable(getParams).then(res => {
      setMessages("Agent has been notified. You will hear back from the Agent shortly")
      toggleSuccessAgent()
    }).catch(error => {
      console.log(error)
    })
  }

  const myLeadsId = useSelector(state => state.user.leadsId);
  console.log(myLeadsId);
  useEffect(() => {
    var id = myLeadsId
    if (myLeadsId !== "" && myLeadsId[0] !== "" && myLeadsId[0] !== null && myLeadsId !== null) {
      console.log("id checking", id)
      let userId = localStorage.getItem('userId')
      let getParams = {
        user_id: userId,
        agent_id: myLeadsId[0]
      }
      leadsAdding(getParams).then((res) => {
        // if(res.data.status==200){
        agentList()
        // }
        console.log("responselead", res);
      }).catch((err) => {
        console.log(err);
      })
    }
    else {
      agentList()
    }
  }, [])

  return (
    <Layout>
      <Seo title="My Agent" />
      <ToastPopup success={successAgent} toggleSuccess={toggleSuccessAgent} messages={messages} />
      <UnClaimPopup userSuccess={userSuccess} toggleUserSuccess={toggleUserSuccess} refresh={agentList} data={uncliamDetails} />
      <div>
        <div className="row mt-5 mb-5">
          {agentData.length !== 0 &&
            agentData.map((data, i) => (
              data.agent_id !== null &&
              <div className="col-md-4" style={{ width: "30%" }} key={i}>
                <Card key={i} onClick={() => setItem(data, i)} onKeyPress={() => setItem(data, i)} className={styles.agentCarouselCard2} style={{
                  border: (isActive && index === i) ? "1px solid rgb(4, 144, 251)" : "1px solid white", borderRadius: "25px", cursor: "default",
                  width: "94%",
                  height: "92%"
                }} >
                  <Link to="/agentDetails" style={{ textDecoration: "none" }} state={{ props: data.agent_id, check: "myAgent" }} >
                  <div className={styles.favorite} role="button" tabIndex="0">
                    {data.agent_id.img ?
                      <img src={hanldeImage(data.agent_id.img)} alt='tour2' className={styles.agentProfile} style={{ borderRadius: "50%" }} onClick={toggleClick} onKeyPress={toggleClick} crossOrigin="anonymous" />
                      : <div className={styles.agentProfile}><AvatarImage onClick={toggleClick} onKeyPress={toggleClick} fontSize={'85px'} data={data.agent_id.name ? data.agent_id.name : ''} /></div>
                    }
                  </div></Link>
                  <div className={styles.CardItemRowSeller} style={{
                    display: "flex", flexDirection: "column", justifyContent: "space-between"
                  }}>
                    <div>
                      <div className="d-flex">
                        <p style={{ width: "30%", fontSize: "13px" }}>Name </p>
                        <p style={{ fontWeight: "bold", fontSize: "14px" }}>{data.agent_id.name}</p>
                      </div>
                      {data.agent_id.address_city !== null && data.agent_id.address_city !== "" &&
                        <div className="d-flex">
                          <p style={{ width: "30%", fontSize: "13px" }}>Location </p>
                          <p style={{ fontWeight: "bold", fontSize: "14px" }}>{(data.agent_id.address_city !== null && data.agent_id.address_city !== "") ? data.agent_id.address_city : "-"}</p>
                        </div>
                      }
                      {data.agent_id.meta_id !== undefined && data.agent_id.meta_id !== null && data.agent_id.meta_id.office_name !== null && data.agent_id.meta_id.office_name !== "" &&
                        <div className="d-flex">
                          <p style={{ width: "30%", fontSize: "13px" }}>Office Name </p>
                          <p style={{ fontWeight: "bold", fontSize: "14px" }}>{(data.agent_id.meta_id.office_name !== null && data.agent_id.meta_id.office_name !== "") ? data.agent_id.meta_id.office_name : "-"}</p>
                        </div>
                      }
                      {data.agent_id.phone_number !== null && data.agent_id.phone_number !== "" &&
                        <div className="d-flex">
                          <p style={{ width: "30%", fontSize: "13px" }}>Phone Number </p>
                          <p style={{ fontWeight: "bold", fontSize: "14px" }}>{(data.agent_id.phone_number !== null && data.agent_id.phone_number !== "") ? data.agent_id.phone_number : "-"}</p>
                        </div>
                      }
                      {data.agent_id.email !== null && data.agent_id.email !== "" &&
                        <div className="d-flex">
                          <p style={{ width: "30%", fontSize: "13px" }}>Email </p>
                          <p style={{ fontWeight: "bold", fontSize: "14px", wordWrap: "break-word", width: "70%" }}>{(data.agent_id.email !== null && data.agent_id.email !== "") ? data.agent_id.email : "-"}</p>
                        </div>
                      } {data.agent_id.meta_id !== undefined && data.agent_id.meta_id !== null && data.agent_id.meta_id.license_number !== null && data.agent_id.meta_id.license_number !== "" &&
                        <div className="d-flex">
                          <p style={{ width: "30%", fontSize: "13px" }}>License Number </p>
                          <p style={{ fontWeight: "bold", fontSize: "14px" }}>{(data.agent_id.meta_id.license_number !== null && data.agent_id.meta_id.license_number !== "") ? data.agent_id.meta_id.license_number : "-"}</p>
                        </div>
                      }
                      <div style={{ display: "flex" }} onClick={toggleClick} role="button" tabIndex="0" onKeyPress={toggleClick}>
                        <p style={{ width: "30%", fontSize: "13px" }}>Status </p>
                        <span style={{ marginTop: "4px" }}>
                          {data.agent_id.online_status === true ?
                            <StaticImage
                              src="../../../static/images/Ellipse2.png"
                              alt="Ellips"
                              className={styles.ActiveIcon}
                            ></StaticImage> :
                            <div
                              style={{ backgroundColor: "red", borderRadius: "50%", height: "12px", width: "12px" }}

                            ></div>
                          }

                        </span>
                        <span style={{ margin: "0 -3px 0px 2px", paddingLeft: "8px", fontWeight: "bold", fontSize: "14px" }}>
                          {data.agent_id.online_status === true ? "Active" : "In-active"}
                        </span>

                      </div> </div>
                    <div style={{ textAlign: "center" }}>

                      <button className='btn w-100 primaryColor' id={styles.AgentSelectButton11} onClick={() => { data.agent_id.online_status === true ? handleChat(data.agent_id._id, data.agent_id.name, data.agent_id.last_name, data.agent_id.img ? hanldeImage(data.agent_id.img) : "") : handleAgentStatus(data) }}>
                        Chat
                      </button> </div>
                    {/* <button  onClick={() => { data.agent_id.online_status==true? handleChat(data.agent_id._id,data.agent_id.name, data.agent_id.last_name, data.agent_id.img ? hanldeImage(data.agent_id.img) : ""): handleAgentStatus(data)}}> */}
                    {/* <span>
                          <Icon icon="bi:chat" className={styles.chatIcons} style={{ color: "red" }} />
                        </span> */}
                    {/* <div className={styles.chatdata}>Chat</div> */}
                    {/* Chat</button> */}

                    {/* <div>
                          {(data.user_id !== null && data.agent_id !== null) &&
                            <div style={{ display: "flex", margin: "5px", justifyContent: "space-between" }}>
                              <button className='btn w-100 primaryColor' id={styles.AgentSelectButton1} onClick={(data) => toggleShow(data)}>
                                Select Agent
                              </button>
                              <div className='btn outline-primary ' id={styles.AgentSelectButton2} >
                                <div className={styles.active} id={styles.ChatIconsName} onClick={() => handleChat(data.user_id !== null && data.user_id, data.name, data.last_name, data.img ? hanldeImage(data.img) : "")} >
                                  <div className={styles.chatIcons1}>
                                    <Icon icon="bi:chat" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                          {data.user_id == null &&
                            <div style={{ textAlign: "center" }}>

                              <button className='btn w-100 primaryColor' id={styles.AgentSelectButton11} onClick={() => handleAgentStatus(data)}>
                                Connet with Agent
                              </button> </div>
                          }
                        </div> */}
                  </div>
                  {/* <div style={{ display: "flex", width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "column", width: "60%", gap: "0.4rem", padding: "10px" }} onClick={toggleClick}>
                      <div className={styles.agentName} style={{ padding: "0px" }}>
                        {data.agent_id.name+" "+(data.agent_id.last_name!==null && data.agent_id.last_name)}
                      </div>
                      <div style={{ fontSize: "14px" }}>
                        {data.agent_id.phone_number}
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", width: "35%", alignItems: "flex-end", gap: "0.3rem", padding: "10px" }}>
                      <div style={{ display: "flex" }} onClick={toggleClick}>
                        <span style={{ marginTop: "4px" }}>
                          {data.agent_id.online_status===true?
                          <StaticImage
                          src="../../../static/images/Ellipse2.png"
                          alt="Ellips"
                          className={styles.ActiveIcon}
                        ></StaticImage>:
                        <div
                        style={{backgroundColor:"red", borderRadius:"50%",height: "12px", width: "12px"}}
                            
                          ></div> 
                          }
                          
                        </span>
                        <span style={{ margin: "0 -3px 0px 2px", paddingLeft: "8px" }}>
                          {data.agent_id.online_status===true?"Active":"In-active"}
                        </span>

                      </div>

                      <div className={styles.active} onClick={() => { data.agent_id.online_status==true? handleChat(data.agent_id._id,data.agent_id.name, data.agent_id.last_name, data.agent_id.img ? hanldeImage(data.agent_id.img) : ""): handleAgentStatus(data)}}>
                        <span>
                          <Icon icon="bi:chat" className={styles.chatIcons} style={{ color: "red" }} />
                        </span>
                        <span className={styles.chatdata}>Chat</span>
                      </div>
                    </div>
                  </div> */}
                  <div>
                  </div>
                </Card>
                <p className={styles.unClaim1} onClick={() => { toggleUserSuccess(); setUncliamDetails(data) }} onKeyDown={() => { toggleUserSuccess(); setUncliamDetails(data) }} aria-hidden >Release</p>
              </div>
            ))}


        </div>

      </div>
      {/* {open ?
        <div>
          <Card
            className={styles.bodyCarouselCard}
            style={{
              borderRadius: "25px",
              boxShadow:
                "0 1px 4px rgb(82 80 80 / 40%), inset 0 0 40px rgb(135 128 128 / 8%)",
            }}
          >

            <>
              <div>
                <p className={styles.agentDetails}>About me</p>
                <p className={styles.agentDetails1}>
                  {item.agent_id.about_me}
                </p>
              </div>
              <div className={styles.agentDetails2}>
                <div className={styles.agentDetails4}>
                  <p className={styles.agentDetails5}>Social media Link</p>

                  <div
                    className="seller-agent"
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      marginLeft: "20px"
                    }}
                  >
                    <img src="/images/facebookIcon.png" alt='mlsdotimage' className="socialIcons2" />
                    {/* <SocialMediaIconsReact
                      borderColor="#3b5a9a"
                      icon="facebook"
                      iconColor="rgba(255,255,255,1)"
                      backgroundColor="#3b5a9a"
                      url={item.agent_id.facebook_link}
                      width="10"
                      height="10"
                      paddingleft="3em"
                    /> */}
                    {/* <img src="/images/instagramIcon.png" alt='mlsdotimage' className="socialIcons2" /> */}
                    {/* <SocialMediaIconsReact
                      borderColor="#3d739c"
                      icon="instagram"
                      iconColor="rgba(255,255,255,1)"
                      backgroundColor="#3d739c"
                      url={item.agent_id.instagram_link}
                      width="22"
                      height="22"
                    /> */}
                    {/* <img src="/images/linkedInIcon.png" alt='mlsdotimage' className="socialIcons2" /> */}
                    {/* <SocialMediaIconsReact
                      borderColor="#117bb8"
                      icon="linkedin"
                      iconColor="rgba(255,255,255,1)"
                      backgroundColor="#117bb8"
                      url={item.agent_id.linkedin_link}
                      width="22"
                      height="22"
                    /> */}
                    {/* <img src="/images/twitterIcon.png" alt='mlsdotimage' className="socialIcons2" /> */}
                    {/* <SocialMediaIconsReact
                      borderColor="#23aae1"
                      icon="twitter"
                      iconColor="rgba(255,255,255,1)"
                      backgroundColor="#23aae1"
                      url={item.agent_id.twitter_link}
                      width="22"
                      height="22"
                    /> */}
                  {/* </div>
                </div>
                <div className={styles.agentDetails4}>
                  <p style={{ color: "#413b3b", }}> Brokerage Company</p>
                  <p style={{ color: "#413b3b", fontSize: "small" }}>{item.agent_id.brokerage_company}</p>
                </div>
                <div className={styles.agentDetails4}>
                  <p style={{ color: "#413b3b" }}># of years licensed</p>
                  <p style={{ color: "#413b3b" }}>{item.agent_id.year_of_licensed}</p>
                </div>
                <div className={styles.agentDetails4}>
                  <p style={{ color: "#413b3b" }}>Email</p>
                  <p style={{ color: "#413b3b" }}>{item.agent_id.email}</p>
                </div>
              </div>
            </>
          </Card>
        </div>
        : null} */} 
      {agentData.length === 0 && <div className='no-result2' style={{ marginTop: "20%" }}>You don’t have any Agents yet, go to “Find an Agent” to add one now!</div>}
      <>
        {loader ? <Loader /> : null}
        {openChat && <ChatPopup show={openChat} toggleShow={showChatDetail} agent={chatAgent} user={chatUser} id={chatId} />}</>
    </Layout>
  )
}

export default MyAgent

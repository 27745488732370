import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import * as style1 from '../../pages/dashboard/detail.module.css';
import { agentUnavailable, inviteMember, findAgentListDuplicate, cityNames, agentNameSearch } from '../../components/Api/ListingApi';
import Pagination from '../../components/Pagination';
import 'react-toastify/dist/ReactToastify.css';
import ToastPopup from '../../components/popup/ToastPopup';
import { useSelector } from 'react-redux';
import { addImagePath } from '../../helper/addImagePath';
import { StreamChat } from 'stream-chat';
import Select from 'react-select';

export default function SelectMemberPopup(props) {
  const { showSelect, toggleSelect, select, chatDetails } = props;
  const [success, setSucess] = useState(false);
  const userDetails = useSelector(state => state.user.userDetail);
  const [messages, setMessages] = useState("")
  const toggleSuccess = () => setSucess(p => !p);
  const [pagecount, setpagecount] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);
  var currentPage = 1;
  const [search, setSearch] = useState("")
  const [searchName, setSearchName] = useState("")
  const [selectedOption, setSelectedOption] = useState(null);
  const [cityName, setCityName] = useState([])
  const [city, setCity] = useState("")
  const [agentName, setAgentName] = useState([])
  const [open, setOpen] = useState(false)


  useEffect(() => {
    async function fetchData() {
      await listingApis();
      cityList()
    } fetchData()

  }, []);

  const [detail, setDetail] = useState([]);
  const listingApis = async (searchs, searchNames) => {

    let getParams = {
      page: currentPage,
      pagesize: 20,
      // search: searchs,
      sort_value: -1,
      sort_field: "_id",
      name_search: searchNames,
      city: searchs
    };
    await findAgentListDuplicate(getParams).then(async (res) => {
      setpagecount(res.data.Paging.total_page);
      setSelectedPage(res.data.Paging.current_page - 1);
      setDetail(res.data.data)

    }).catch((error) => {
      console.log(error)
    })
  }

  const handleAdd = async (_id, name) => {
    let grp_id = chatDetails._id
    let userId = localStorage.getItem('userId')
    let getParams = {
      user_id: _id,
      group_id: select === undefined ? grp_id : select._id,
      send_by: userId
    }
    const apikey = process.env.STREAM_CHAT_APIKEY;
    let user = {
      id: userDetails._id,
      name: userDetails.name,
      image: addImagePath(userDetails.img !== null ? userDetails.img : "")
    }
    const chatClient = StreamChat.getInstance(apikey);
    await chatClient.connectUser(user, chatClient.devToken(user.id, user.image));
    const channel = await chatClient.channel('messaging', chatDetails !== undefined && chatDetails._id, { name: chatDetails.name })
    await channel.addMembers([_id], { text: `${name} joined the channel.` });
    await inviteMember(getParams).then((res) => {
      toggleSelect()
      setMessages(res.data.message)
      toggleSuccess()
    }).catch((error) => {
      console.log(error)
    })
  }
  const userId = localStorage.getItem('userId')

  const handleAgentStatus = async (id) => {
    let userId = localStorage.getItem("userId")
    var getParams = {
      agent_id: id,
      user_id: userId
    }
    await agentUnavailable(getParams).then(res => {
      setMessages("Agent has been notified. You will hear back from the Agent shortly")
      toggleSuccess()
    }).catch(error => {
      console.log(error)
    })
  }

  // const handleSearchName = () => {
  //   currentPage = 1
  //   listingApis(city, searchName);
  // }
  const handleSearchName = (name) => {
    if (name !== "") {
      currentPage = 1
      listingApis(city, name);
    } else {
      currentPage = 1
      listingApis(city, searchName);
    }
  }

  const handleSearch = (data) => {
    currentPage = 1
    listingApis(data, searchName);

  }
  async function handlePageClick(event) {
    currentPage = event.selected + 1;
    await listingApis(city, searchName);
  };


  const handleKeySearch = (event) => {
    if (event.key === 'Enter') {
      handleSearch()
    }
  }

  const handleKeySearchName = (event) => {
    if (event.key === 'Enter') {
      handleSearchName()
    }
  }
  const cityList = async () => {
    await cityNames().then((res) => {
      // console.log("response", res.data.data)
      setCityName(res.data.data)

    }).catch(error => {
      console.log(error)
    })
  }
  const handleChange = (selectedOption, e) => {
    setSelectedOption(selectedOption);
    setCity(selectedOption.value)
    handleSearch(selectedOption.value)
  };
  const options = cityName.map((item) => ({
    value: item.CityName,
    label: item.CityName,
  }));

  const handleName = async (e) => {
    // console.log(e.target.value);
    if (searchName !== "") {
      let getPrarams = {
        search: e.target.value
      }
      // console.log("getPrarams", getPrarams)
      await agentNameSearch(getPrarams).then((res) => {
        // console.log("respose age", res.data.data.map((item) => item))
        setAgentName(res.data.data)

      }).catch((err) => {
        console.log(err)
        setAgentName([])
      })
    }
  }


  return (
    <div>
      <ToastPopup success={success} toggleSuccess={toggleSuccess} messages={messages} />
      <Modal show={showSelect} onHide={toggleSelect} className='ModalDiv'>
        <div className="PopUpBorderDiv">
          <Modal.Header closeButton className="team-popup-margin teampopup-title"  >
            <Modal.Title className='form-field-label form-label-team '><h5 className='teamText'>Select Members</h5></Modal.Title>
          </Modal.Header>
          <Modal.Body className="team-popup-margin pb-0" >
            <div className='d-flex justify-content-between mb-3'>
              <div className="d-flex position-relative align-items-center">
                <Select
                  id="gsearch"
                  value={selectedOption}
                  onChange={(e) => handleChange(e)}
                  options={options}
                  name=""
                  isSearchable={true}
                  placeholder="Select City..."
                  onFocus={() => setOpen(false)}
                />
                {/* <input type="search" className="border form-control br-10" placeholder="Search City" value={search} onChange={(e) => setSearch(e.target.value)} onKeyDown={handleKeySearch} />
                <span className="position-absolute right-10 cursor-pointer" onClick={handleSearch} onKeyPress={handleSearch} role="button" tabIndex="0" ><i className="fa fa-search" aria-label='search'></i></span> */}
              </div>
              <div className="d-flex position-relative align-items-center">
                <input type="search" className="border form-control br-10" placeholder="Search Name" value={searchName} onChange={(e) => { setSearchName(e.target.value); handleName(e); }} onKeyDown={handleKeySearchName} onKeyPress={handleKeySearchName} onFocus={() => setOpen(true)} />
                {open && agentName.length !== 0 ?
                  <div className='agentNameSearch' style={{ position: "absolute", height: "auto", width: "215px", background: "white", zIndex: 1, overflow: "auto", top: "44px" }}>
                    {agentName.length !== 0 ? agentName.map((item, i) => (
                      <p onClick={() => { handleSearchName(item.name); setSearchName(item.name); setOpen(false) }} style={{ padding: '8px 15px', cursor: "pointer", marginBottom: "0px", color: searchName === item.name ? "white" : "", background: searchName === item.name ? "#4B91FB" : "" }} key={i} value={item.name}>{item.name}</p>
                    )) : <p style={{ textAlign: "center", paddingTop: "13px", color: "#414141" }}>No Results found</p>}
                  </div>
                  : null}
                <span className="position-absolute right-10 cursor-pointer" onClick={handleSearchName} onKeyPress={handleSearchName} role="button" tabIndex="0" ><i className="fa fa-search" aria-label='search'></i></span>
              </div>
            </div>
            <div style={{ height: "750px", overflow: "auto", padding: "10px" }}>
              {detail.map((item, i) => (
                <div className={style1.groupdetail} key={i} >
                  <h6>{item.name}</h6>
                  {(item.agent_id !== null && item.user_id !== null) && (userId !== item.agent_id) ?
                    <Button className={style1.groupaddbut} onClick={() => { handleAdd(item.user_id, item.name) }}>Add Member</Button>
                    : <Button className={style1.groupaddbut} onClick={() => { handleAgentStatus(item._id) }}>connect agent</Button>}
                </div>
              ))
              }
            </div>
          </Modal.Body>
          <div>
            {detail.length > 0 ? <Pagination pagecount={pagecount} handlePageClick={handlePageClick} forcepage={selectedPage} /> : null}
          </div>
        </div>
      </Modal >
    </div >
  )
}
import React, { useEffect, useState } from "react"
import { Card, Button, Modal } from "react-bootstrap"
import "./notification.css"
import Layout from '../components/Layout'
import "bootstrap/dist/css/bootstrap.css"
import Seo from "../components/seo";
import searchIcon from '../../static/images/Icon feather-search.png';
import filterIcon from '../../static/images/sortup.png';
import { notificationList, notificationDelete, agentReadNotification } from '../components/Api/ListingApi'
import AvatarImage from "../components/Avatar/avatarImage"
import DeletePopup from "../components/popup/DeletePopup"
import MarkAsReadPopup from "../components/popup/MarkAsReadPopup"
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../components/Loader'
import moment from "moment"
import sortdown from '../../static/images/sortdown.png'
import 'react-toastify/dist/ReactToastify.css';
import * as style1 from '../pages/dashboard/detail.module.css';
import Pagination from "../components/Pagination"
import reddot from "../../static/images/reddot.png"
import NotificationProfile from './../components/popup/notificationprofile'
import ToastPopup from "../components/popup/ToastPopup"
import SkeletonVerticalCard from "../components/card/skeletonVerticalCard"

export default function Notifications() {
  const [loader, setLoader] = useState(false);
  const [date, setDate] = useState({})
  const [notification, setNotification] = useState([]);
  const [tour, setTour] = useState([]);
  const [referral, setReferral] = useState([]);
  const [offer, setOffer] = useState([]);
  const [Group, setGroup] = useState([]);
  const [status, setStatus] = useState([]);
  const [buyerprospect, setBuyerProspect] = useState([]);
  const [sellprospect, setSellerProspect] = useState([]);
  const [noteProfile, setNoteProfile] = useState({})
  const [dataSort, setDataSort] = useState(-1)
  const [search, setSearch] = useState("")
  const [dataid, setDataID] = useState("")
  const [pagecount, setpagecount] = useState(0);
  const [selectedPage, setSelectedPage] = useState(0);
  const [indexx, setIndex] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [show, setShow] = useState(false);
  const dummy = "unknown"
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const array = ["1", "2", "3", "4", "5", "6", "7", "8"]

  const [showProfile, setShowProfile] = useState(false);
  const toggleProfile = () => setShowProfile(p => !p);
  const [counts, setCount] = useState('')
  var currentPage = 1;
  useEffect(() => {
    async function fetchData() {
      await getNotification(search, dataSort);
    } fetchData()
  }, [])
  const getNotification = async (searchs, dataSort, check) => {
    let userId = localStorage.getItem('userId')
    let getParams = {
      pagesize: 20,
      page: currentPage,
      sort_field: '_id',
      sort_value: dataSort,
      search: searchs,
    }
    setLoader((check !== undefined && check) ? false : true);
    await notificationList(userId, getParams).then((res) => {
      setLoader(false);
      setpagecount(res.data.Paging.total_page);
      setSelectedPage(res.data.Paging.current_page - 1);
      setNotification(res.data.data)
      setGroup(res.data.data.Group)
      setReferral(res.data.data.Referral)
      setOffer(res.data.data.offer)
      setBuyerProspect(res.data.data.buyerprospect)
      setSellerProspect(res.data.data.sellprospect)
      setTour(res.data.data.tour)
      setStatus(res.data.data.status)
      setCount(res.data.Count)
    }).catch((error) => {
      console.log(error)
    })
    // setLoader(false);
  }
  const listNotification = async (searchs) => {
    let userId = localStorage.getItem('userId')
    let getParams = {
      pagesize: 20,
      page: selectedPage,
      sort_field: '_id',
      sort_value: -1,
      search: searchs,
    }
    // setLoader(true);
    await notificationList(userId, getParams).then((res) => {
      setLoader(false)
      setNotification(res.data.data)
      setpagecount(res.data.Paging.total_page);
      setSelectedPage(res.data.Paging.current_page - 1);
      setGroup(res.data.data.Group)
      setReferral(res.data.data.Referral)
      setOffer(res.data.data.offer)
      setBuyerProspect(res.data.data.buyerprospect)
      setSellerProspect(res.data.data.sellprospect)
      setTour(res.data.data.tour)
      setStatus(res.data.data.status)
      setCount(res.data.Count)
    }).catch((error) => {
      console.log(error)
    })
    // setLoader(false);
  }

  if (counts >= 1) {
    let countVal = counts
    localStorage.setItem("countVal", countVal)

  }

  const hanldeImage = (img) => {
    let data = img !== null && img.split('/')[0]
    if (data === "uploads") {
      return process.env.API_IMAGE_URL + img
    }
    else {
      return img
    }
  }

  const [isShowPopUp1, setIsShowPopUp1] = useState(false)
  const [isShowPopUp2, setIsShowPopUp2] = useState(false)
  const [succes, setSuces] = useState(false);
  const [messages, setMessages] = useState("")
  const toggleSucces = () => setSuces(p => !p);
  const userId = localStorage.getItem('userId')

  const hanldeClosePopUp1 = () => {
    setIsShowPopUp1(p => !p)
  }
  const hanldeClosePopUp2 = () => {
    setIsShowPopUp2(p => !p)
  }


  const hanldeDeleteButton = (id) => {
    handleShow()

  }
  const HandleDelete = async (id) => {
    // console.log("id confirm", id)
    let getParams = {
      id: id,
      user_id: ""
    }
    // setLoader(true)
    await notificationDelete(getParams).then((res) => {
        setMessages(res.data.message)
      if (res.status === 200) {
        toggleSucces()
      }
      listNotification()
      // setLoader(false)
    }).catch((error) => {
      console.log(error)
    })
    handleClose();
  }
  async function handlePageClick(event) {
    currentPage = event.selected + 1

    await getNotification();
  };
  const handleKeySearch = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      handleSearch()
    }
  }

  const handleSearch = (e) => {
    getNotification(search)
  }
  const handleClick = (index, e) => {
    setIndex(index)
    setIsActive(true);
  }
  function Cards(props) {
    let index = props.index
    let data = props.data
    return (
      <div>
        <Card className='notifyCard' key={index} onClick={(e) => { handleClick(index, e); setDate(data._id); }} style={{ cursor:"default", border: (isActive && date === data._id && indexx === index) ? "1px solid rgb(4, 144, 251)" : "1px solid white" }} >
          <div onClick={() => readNotification(data._id)} role="button" tabIndex="0" onKeyPress={() => readNotification(data._id)} >
            <div style={{ display: "flex", margin: "0 0 0 10px" ,cursor:"default" }} >
              <div onClick={() => { setNoteProfile(data); toggleProfile() }} role="button" tabIndex="0" onKeyPress={() => { setNoteProfile(data); toggleProfile() }} >
                {data.content_id !== null && data.content_id.img ?
                  <img src={hanldeImage(data.content_id !== null && data.content_id.img)} alt='tour2' className="profilepic ProfileNotificationJs" style={{ borderRadius: "50%" }} crossOrigin="anonymous" />
                  : <div style={{ width: "80px", height: "80px" ,cursor:"pointer" }}>
                    <AvatarImage fontSize={'30px'} data={data.content_id !== undefined && data.content_id !== null && data.content_id.name === undefined ? dummy : data.content_id !== undefined && data.content_id !== null && data.content_id.name} /></div>
                }
              </div>
              <div>
                <div style={{ margin: "10px 0 0 11px" }}>
                  <div style={{ display: "flex", alignItems: "center", height: "22px" }}>
                    <div>
                      <h6 className="fname text-capitalize">{data.content_id !== null && data.content_id.name === undefined ? "Unknown User" : (data.content_id !== null && data.content_id !== null && data.content_id.name)}</h6>
                    </div>
                    <div>
                      {data.read_by === false && <img style={{ width: "45px" }} src={reddot} alt="reddot" />}
                    </div>
                  </div>
                  <p className="rolenote text-capitalize">{data.content_id !== null && data.content_id.role}</p>

                  <p className="time">{getTime(data.updatedAt)}</p>
                </div>
              </div>
            </div>
            <Card.Body style={{ paddingTop: '0rem', paddingBottom: '0rem' }}>
              <Card.Text className='CardTextDiv' style={{ height: "auto", marginBottom: '0rem' }}>
                <p className="content" id={style1.ContentPara} style={{ color: "black", }} >{data.content}</p> </Card.Text>

            </Card.Body></div>
          <Button type="button" variant="outline-primary" className=' Delete Deletebut' onClick={() => { setDataID(data._id); hanldeDeleteButton(data._id); }} >Delete</Button>

        </Card>

      </div>

    )
  }

  function getTime(updatedAt) {

    const startShiftTime = new Date()
    const sample = moment(startShiftTime, 'YYYY-MM-DDThh:mm')
    const dateString = sample.year() + '-' + sample.month() + '-' + sample.date() + 'T' + sample.hours() + ':' + sample.minutes() + ':' + sample.seconds();
    const [fullDate, time] = dateString.split('T');
    const [year, month, date] = fullDate.split('-');
    const [hour, minute, second] = time.split(':');
    const dateTime = new Date(year, month, date, hour, minute, second);


    const ms = moment(dateTime.toISOString(), "YYYY-MM-DDThh:mmTZD").diff(moment(updatedAt, "YYYY-MM-DDThh:mmTZD"));
    const d = moment.duration(ms)
    function SplitTime(numberOfHours) {
      const Days = Math.floor(numberOfHours / 24);
      const Remainder = numberOfHours % 24;
      const Hours = Math.floor(Remainder);
      const Minutes = Math.floor(60 * (Remainder - Hours));

      if (Hours == '0') {
        if (Minutes < '5') {
          return "Just now"
        } else {
          return Minutes + 'm'
        }
      } else if (Days == '0') {
        return Hours + 'h ' + Minutes + 'm'
      } else {
        return Days + 'd ' + Hours + 'h'
      }
    }

    return SplitTime(d.asHours())
  }
  const readNotification = async (id) => {
    let userId = localStorage.getItem('userId')
    let params = {
      user_id: userId,
      id: id
    }
    await agentReadNotification(params).then((res) => {
      listNotification()
    })

  }

  return (
    <div className="bgcolor">
      {/* {loader ? <Loader /> : null} */}
      <Layout>
        <Seo title="Notifications" />
        <ToastPopup success={succes} toggleSuccess={toggleSucces} messages={messages} />
        <MarkAsReadPopup open={isShowPopUp1} close={hanldeClosePopUp1} notification={getNotification} />
        <DeletePopup open={isShowPopUp2} close={hanldeClosePopUp2} userId={userId} notification={getNotification} />
        <NotificationProfile showProfile={showProfile} toggleProfile={toggleProfile} profileDetails={noteProfile} />

        <Modal show={show} onHide={handleClose} className='ModalDiv'>
          <div className="PopUpBorderDiv">
            <Modal.Header closeButton className="team-popup-margin teampopup-title " >
              <Modal.Title className='form-field-label form-label-team '></Modal.Title>
            </Modal.Header>
            <Modal.Body className="team-popup-margin pb-0" >

              <div className={style1.successtext}>
                <p className={style1.accepttext}>Are you sure you want to Delete the notification?</p>
              </div>

              <Button className={style1.nobutton} onClick={handleClose}>
                No
              </Button>
              <Button className={style1.yesbutton} onClick={() => HandleDelete(dataid)}>
                Yes
              </Button>
            </Modal.Body>
          </div>
        </Modal>

        <div className='searchBuy searchIcons'>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ padding: '0px 25px' }}>

              <form>

                <input className="buyer-search" value={search} onChange={(e) => setSearch(e.target.value)} placeholder='Search' onKeyDown={handleKeySearch} />

                <div className="searchIconAgent" >
                  <img src={searchIcon} alt="Icon feather-search.png" className='search-icon-img' onClick={handleSearch} onKeyDown={handleSearch} aria-hidden />
                </div>
              </form>
            </div>
            <div>
              <img src={dataSort === 1 ? sortdown : filterIcon} alt="sortup.png" onClick={() => {
                let sort = dataSort === 1 ? -1 : 1;
                setDataSort(sort);
                getNotification(search, sort)
              }} onKeyPress={() => {
                let sort = dataSort === 1 ? -1 : 1;
                setDataSort(sort);
                getNotification(search, sort)
              }} className='filter-icon-img' aria-hidden />
            </div>
          </div>
        </div>
        <div>

          <div className="cardProfile">
            <div className="mb-4 Markalldele">
              <button className='notificationdelete MarkAll' onClick={() => { hanldeClosePopUp1(); }} disabled={pagecount==0} >
                Mark all as read
              </button>
              <button className='networkmessage deleteAll' onClick={() => { hanldeClosePopUp2(); }} disabled={pagecount==0}>
                Delete All
              </button>
            </div>

            {loader ?
              <div className="row m-0">
                {array.map(() => (
                  <div className="col-md-3 col-lg-4 col-xl-3" >

                    <SkeletonVerticalCard purpose={"notification"} />

                  </div>
                ))}
              </div>
              :
              <>

                {Group.length === 0 && status.length === 0 && buyerprospect.length === 0 && sellprospect !== undefined && sellprospect.length === 0 && tour.length === 0 && offer.length === 0 && referral.length === 0 &&
                  <div className='no-result1' style={{ paddingTop: "130px" }}>You do not have any notifications</div>}
                <div style={{ height: "700px", overflow: "auto" }}>
                  <div className="row m-0">

                    {Group.length !== 0 && <h6 className="fname text-capitalize heading">Network Groups</h6>}
                    {Group.length !== 0 && Group.map((data, index) => (
                      <div className="col-md-3 col-lg-4 col-xl-3" key={index}>
                        <Cards index={index} data={data} />
                      </div>
                    ))}

                    {referral.length !== 0 && <h6 className="fname text-capitalize heading">Referral</h6>}
                    {referral.length !== 0 && referral.map((data, index) => (
                      <div className="col-md-3 col-lg-4 col-xl-3" key={index}>
                        <Cards index={index} data={data} />
                      </div>
                    ))}
                    {offer.length !== 0 && <h6 className="fname text-capitalize heading">Offers</h6>}
                    {offer.length !== 0 && offer.map((data, index) => (
                      <div className="col-md-3 col-lg-4 col-xl-3" key={index}>
                        <Cards index={index} data={data} />
                      </div>
                    ))}
                    {buyerprospect !== undefined && buyerprospect.length !== 0 && <h6 className="fname text-capitalize heading">Buyer Prospects</h6>}
                    {buyerprospect !== undefined && buyerprospect.length !== 0 && buyerprospect.map((data, index) => (
                      <div className="col-md-3 col-lg-4 col-xl-3" key={index}>
                        <Cards index={index} data={data} />
                      </div>
                    ))}

                    <>{sellprospect !== undefined && sellprospect.length !== 0 && <h6 className="fname text-capitalize heading">Seller Prospects</h6>}
                      {sellprospect !== undefined && sellprospect.length !== 0 && sellprospect.map((data, index) => (
                        <div className="col-md-3 col-lg-4 col-xl-3" key={index}>
                          <Cards index={index} data={data} />
                        </div>
                      ))}</>

                    {tour.length !== 0 && <h6 className="fname text-capitalize heading">Tours</h6>}
                    {tour.length !== 0 && tour.map((data, index) => (
                      <div className="col-md-3 col-lg-4 col-xl-3" key={index}>
                        <Cards index={index} data={data} />
                      </div>
                    ))}
                    {status.length !== 0 && <h6 className="fname text-capitalize heading">Status</h6>}
                    {status.length !== 0 && status.map((data, index) => (
                      <div className="col-md-3 col-lg-4 col-xl-3" key={index}>
                        <Cards index={index} data={data} />
                      </div>
                    ))}
                  </div>
                </div>
              </>}
          </div>

        </div>
        <div className="bgleftcolor"></div>
        {notification.length > 0 ? <Pagination pagecount={pagecount} handlePageClick={handlePageClick} forcepage={selectedPage} /> : null}
      </Layout>
    </div>
  )
}


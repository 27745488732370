import React, { useState, useEffect } from 'react';
import { RiArrowLeftLine } from "react-icons/ri";
import { navigate } from "@reach/router";
import { useLocation } from "@reach/router";
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import * as styles from "../../pages/seller/sellerProp.module.css";
import { agentUnavailable } from "../../components/Api/ListingApi";
import ToastPopup from '../../components/popup/ToastPopup';
import SelectAgentPopup from '../../components/popup/selectAgentPopup';





export default function AgentDetails() {
  const location = useLocation()
  const { props, check } = location.state
  const initialState = {
    beforeClaim: false,
  }
  //variable declare //
  var userId = localStorage.getItem("userId")
  const [storage, setStorage] = useState(false)
  const [claimpopup, setClaimPopup] = useState(initialState)
  const [success, setSucess] = useState(false);
  const [messages, setMessages] = useState("");
  const [show, setShow] = useState(false);
  useEffect(() => {
    const storedData = localStorage.getItem("userInfo")
    if (!storedData) {
      setStorage(false)
    } else {
      setStorage(true)
    }
  }, [])
  console.log("props", props)
  // console.log("name", props.name)
  const toggleSuccess = () => setSucess(p => !p);
  // image null handle //
  const hanldeImage = (img) => {
    let data = img !== null && img.split('/')[0]
    if (data === "uploads") {
      return process.env.API_IMAGE_URL + img
    }
    else {
      return img
    }
  }
  const loginAlert = () => {
    setClaimPopup({ ...claimpopup, beforeClaim: !claimpopup.beforeClaim })
  }

  const toggleShow = () => {
    setShow(p => !p)
  }
  const handleAgentStatus = async (data) => {
    if (userId === null) {
      loginAlert()
      setTimeout(
        () => setClaimPopup({ ...claimpopup, beforeClaim: false }),
        3000
      )
    } else {
      var getParams = {
        agent_id: props._id,
        user_id: userId
      }
      await agentUnavailable(getParams).then(res => {
        setMessages("Agent has been notified. You will hear back from the Agent shortly")
        toggleSuccess()
      }).catch(error => {
        console.log(error)
      })
    }
  }


  return (
    <div>
      <ToastPopup success={success} toggleSuccess={toggleSuccess} messages={messages} />
      <SelectAgentPopup show={show} toggleShow={toggleShow} agentId={props._id} />
      <Navbar isLogin={storage} />
      <div className='row' style={{ background: "#F2F2F2", height: "100vh" }}>
        <div className='col-md-2'></div>
        <div className='col-md-8'>
          <div className="dashboardbackicon1 mt-3" onClick={() => {
            navigate(check === "user" ? `/findagentseller` : check === "agent" ? `/findagent` : `/myAgent`);
          }} >
            <RiArrowLeftLine style={{ fill: "" }} size="25px" />
            <p className="backtext m-0 ps-1 mb-1" aria-hidden>Back</p>
          </div>
          <div className='d-flex'>
            <div >

              <div>
                <div className={styles.favorite} role="button" tabIndex="0" style={{ cursor: "default", marginTop: "40px" }}>
                  {props.img ?

                    (props.img !== undefined && props.img.split('/')[0] === "https:" ? <img src={props.img !== undefined && hanldeImage(props.img)} alt="agent" className="agentImage" /> :
                      <img alt="agent" className="agentImage" src={props.img !== undefined && hanldeImage(props.img)} crossOrigin='anonymous' />)
                    : <img alt="agent" className="agentImage" src="/images/agentDetailImage.png" crossOrigin='anonymous' />
                  }
                </div></div>
              {check !== "myAgent" &&
                <div className='mb-3'>
                  {(props.user_id !== null && props.agent_id !== null && check === "user") &&
                    <div >
                      <button className='agentButton1' onClick={() => toggleShow()}>
                        Select Agent
                      </button>
                    </div>
                  }
                  {props.user_id == null &&
                    <div >

                      <button className='agentButton1' onClick={() => { handleAgentStatus() }}>
                        Connect with Agent
                      </button> </div>
                  }
                </div>}

            </div>
            <div className='agentDetailsfind'>
              <h4 className='agentDetailName'>{props.name}</h4>
              <h5 className="DejaVuSansCondensed mb-2 mt-2" style={{ color: "black" }} >{props?.user_id ? props.user_id.office_name : props?.office_name ? props.office_name : ""}</h5>
              <div className='mt-4'>
                <img alt="phone" className='p-2' src="/images/PhoneAgent.png" />
                <span style={{ fontSize: "20px", color: "#414141" }}>{props?.user_id ? props.user_id.phone_number : props?.office_phone ? props.office_phone : props.phone_number}</span>
              </div>
              <div className='mt-2'>
                <img alt="mail" className='p-2' src="/images/mailAgent.png" />
                <span style={{ fontSize: "20px", color: "#414141" }}>{props?.user_id ? props.user_id.email : props?.email ? props.email : ""}</span>
              </div>
              <div className='mt-2'>
                {(props.address !== null || (props.user_id !== null && props?.user_id?.address !== null)) && <>
                  <img alt="location" className='p-2' src="/images/addressAgent.png" />
                  <span style={{ fontSize: "20px", color: "#414141" }}>{props?.user_id ? props.user_id.address : props?.address ? props.address : props.state_licenseno[0].state}</span></>}
              </div>
              <div className='mt-2'>
                {(props.license_number !== null || (props?.user_id !== null && props?.user_id?.license_number !== null)) && <>
                  <img alt="license" className='p-2' src="/images/licenseAgent.png" />
                  <span style={{ fontSize: "20px", color: "#414141" }}>{props?.user_id ? props.user_id.license_number : props?.license_number ? props.license_number : props.state_licenseno[0].license_number}</span></>}
              </div>
              <div className='mt-2'>
                <a href={props?.user_id?.facebook_link ? "https://www." + props.user_id.facebook_link : props?.facebook_link ? props.facebook_link : "https://www.facebook.com/"} target="_blank" rel="noreferrer"><img alt="Facebook" className='p-2' src="/images/FacebookAgent.png" /></a>
                <a href={props?.user_id?.twitter_link ? "https://www." + props.user_id.twitter_link : props?.twitter_link ? props.twitter_link : "https://twitter.com/login"} target="_blank" rel="noreferrer"><img alt="twitter" className='p-2' src="/images/twitterAgent.png" /></a>
                <a href={props?.user_id?.instagram_link ? "https://www." + props.user_id.instagram_link : props?.instagram_link ? props.instagram_link : "https://www.instagram.com/"} target="_blank" rel="noreferrer"><img alt="instagram" className='p-2' src="/images/instaAgent.png" /></a>
                <a href={props?.user_id?.linkedin_link ? "https://www." + props.user_id.linkedin_link : props?.linkedin_link ? props.linkedin_link : "https://www.linkedin.com/"} target="_blank" rel="noreferrer"><img alt="Linkedin" className='p-2' src="/images/LinkedinAgent.png" /></a>

              </div>
            </div>

          </div>
          <div className='mt-4 mb-4'>
            <img alt="Dotted" className='' src="/images/agentdetailsdoted.png" />
          </div>
          <div>
            <h3 className='aboutAgent'>More About Me</h3>
            <h5 className="DejaVuSansCondensed mb-2 mt-2" style={{ color: "black", lineHeight: "1.5" }}>{props?.user_id ? props.user_id.about_me : props.about_me}</h5>
          </div>
        </div>
        <div className='col-md-2'></div>
      </div>

      <Footer isLogin={storage} />
    </div>
  )
}

import React, { useEffect } from "react";
import App from "./app";
// import Head
// import Heads from "./Head";
// import  useSelector  from "react-redux"
import { Provider, useSelector } from "react-redux";
import SeoAddress from "../components/SeoAddress";
import { SEOMetaTag } from "../components/seoMetaTag";


if (typeof window !== "undefined") {
  // This code will run in the browser environment
  var address = localStorage.getItem("title");
  var description = localStorage.getItem("description");

  var image = localStorage.getItem("image");

}
// const siteMetaDAta=()=>{
//   const claimdetailResult = useSelector(state => state.detail.result)
//   console.log("claimdetailResult", claimdetailResult)
// }
export default function Index() {
  const claimdetailResult = useSelector(state => state.detail.result)
  let address = "hi"

  if (window.location.pathname == "/homedetails/:id") {
    address = "bye"
  }

  // console.log("claimdetailResult", claimdetailResult)
  // localStorage.setItem("claimdetailResult","")

  console.log("claimdetailResult sdfsdfsdf", claimdetailResult)

  return (
    <>
      <section>
        <App />
        <SEOMetaTag title={claimdetailResult.address} description={claimdetailResult.description} image={claimdetailResult.thumbnail_image} url={"address"} />
      </section>
    </>
  )
}
export const Head = (props) => {
  const { title ,description,image,url} = props
  console.log("title",title)
  console.log("description",description)
  console.log("image",image)
  



  // console.log("claimdetailResult", siteMetaDAta)
  return (
    <SEOMetaTag title={title} description={description} image={image} url={address} />
  )
}



// extracted by mini-css-extract-plugin
export var ActiveData = "sellerProp-module--ActiveData--1f98b";
export var ActiveIcon = "sellerProp-module--ActiveIcon--f9631";
export var ActiveIconsName = "sellerProp-module--ActiveIconsName--80c56";
export var ActiveIconsStatus = "sellerProp-module--ActiveIconsStatus--60e50";
export var ActiveIconspan = "sellerProp-module--ActiveIconspan--256b8";
export var ActiveIconss = "sellerProp-module--ActiveIconss--37b5f";
export var ActiveName = "sellerProp-module--ActiveName--76c3c";
export var AgentPhoneNumber = "sellerProp-module--AgentPhoneNumber--36b79";
export var AgentSelectButton1 = "sellerProp-module--AgentSelectButton1--89aa5";
export var AgentSelectButton11 = "sellerProp-module--AgentSelectButton11--77bb3";
export var AgentSelectButton2 = "sellerProp-module--AgentSelectButton2--11610";
export var AgentSelectButton3 = "sellerProp-module--AgentSelectButton3--76d85";
export var AvatarImageOwned = "sellerProp-module--AvatarImageOwned--03197";
export var BarInfo = "sellerProp-module--BarInfo--aee88";
export var Calendar = "sellerProp-module--Calendar--a4be1";
export var CardItemRowSeller = "sellerProp-module--CardItemRowSeller--6f3b6";
export var CarouselCard1 = "sellerProp-module--CarouselCard1--28242";
export var CarouselCard2 = "sellerProp-module--CarouselCard2--eb4fc";
export var ChatIconsName = "sellerProp-module--ChatIconsName--278bb";
export var ChatName = "sellerProp-module--ChatName--22214";
export var CloseButton = "sellerProp-module--CloseButton--b71c7";
export var Editbutton = "sellerProp-module--Editbutton--d7797";
export var Editbutton1 = "sellerProp-module--Editbutton1--4eb1f";
export var ImageView3 = "sellerProp-module--ImageView3--96736";
export var Link = "sellerProp-module--Link--6a37b";
export var LinkList = "sellerProp-module--LinkList--4de0a";
export var ListPrice = "sellerProp-module--ListPrice--523a6";
export var SellerLicenceNumbername = "sellerProp-module--SellerLicenceNumbername--6749f";
export var active = "sellerProp-module--active--7a15a";
export var activeNum = "sellerProp-module--activeNum--0796b";
export var activeOffer = "sellerProp-module--activeOffer--82b50";
export var activity = "sellerProp-module--activity--a98e4";
export var activityName = "sellerProp-module--activityName--2ead1";
export var agentCarouselCard = "sellerProp-module--agentCarouselCard--7f6df";
export var agentCarouselCard2 = "sellerProp-module--agentCarouselCard2--d20a8";
export var agentCity = "sellerProp-module--agentCity--dc937";
export var agentDetails = "sellerProp-module--agentDetails--d873a";
export var agentDetails1 = "sellerProp-module--agentDetails1--daf5e";
export var agentDetails2 = "sellerProp-module--agentDetails2--63559";
export var agentDetails4 = "sellerProp-module--agentDetails4--56e3e";
export var agentDetails5 = "sellerProp-module--agentDetails5--8b419";
export var agentLicense = "sellerProp-module--agentLicense--89847";
export var agentName = "sellerProp-module--agentName--dd966";
export var agentNumber = "sellerProp-module--agentNumber--f5589";
export var agentProfile = "sellerProp-module--agentProfile--86dae";
export var agentProfile1 = "sellerProp-module--agentProfile1--bd3e1";
export var agentProfile2 = "sellerProp-module--agentProfile2--4a865";
export var agentText = "sellerProp-module--agentText--642aa";
export var agenthead = "sellerProp-module--agenthead--eae6a";
export var alignTable = "sellerProp-module--alignTable--f1016";
export var arrowIcon = "sellerProp-module--arrowIcon--07647";
export var arrowIcon1 = "sellerProp-module--arrowIcon1--69f32";
export var avatarColor = "sellerProp-module--avatarColor--c762a";
export var bathsstyle = "sellerProp-module--bathsstyle--a9945";
export var bathsstyle2 = "sellerProp-module--bathsstyle2--8cb73";
export var beds = "sellerProp-module--beds--8e62f";
export var bedsstyle = "sellerProp-module--bedsstyle--774a3";
export var bedsstyle2 = "sellerProp-module--bedsstyle2--75388";
export var bodyCarouselCard = "sellerProp-module--bodyCarouselCard--82085";
export var calbutton = "sellerProp-module--calbutton--53414";
export var calbutton1 = "sellerProp-module--calbutton1--b04e3";
export var card = "sellerProp-module--card--400d9";
export var cardBack = "sellerProp-module--cardBack--6b6eb";
export var cardRow = "sellerProp-module--cardRow--0cdb4";
export var cardview = "sellerProp-module--cardview--85c94";
export var carouselImage = "sellerProp-module--carouselImage--554c2";
export var carouselImage2 = "sellerProp-module--carouselImage2--f1359";
export var carouselRes = "sellerProp-module--carouselRes--7f865";
export var carouselResList = "sellerProp-module--carouselResList--beb42";
export var chatIcon = "sellerProp-module--chatIcon--6db4c";
export var chatIcons = "sellerProp-module--chatIcons--5f1fd";
export var chatbut = "sellerProp-module--chatbut--396db";
export var chatbutton = "sellerProp-module--chatbutton--ac7db";
export var chatdata = "sellerProp-module--chatdata--3b9e0";
export var chatdetail = "sellerProp-module--chatdetail--0070c";
export var chatinput = "sellerProp-module--chatinput--5ccf2";
export var circle = "sellerProp-module--circle--7b856";
export var circle1 = "sellerProp-module--circle1--a6438";
export var circle2 = "sellerProp-module--circle2--f3336";
export var circularBack = "sellerProp-module--circularBack--1f2e1";
export var claim = "sellerProp-module--claim--9626b";
export var closeIcon1 = "sellerProp-module--closeIcon1--2fd9d";
export var closebutton2 = "sellerProp-module--closebutton2--424a1";
export var colDiv = "sellerProp-module--colDiv--99c0e";
export var dashboardActive = "sellerProp-module--dashboardActive--c5939";
export var dashboardActive1 = "sellerProp-module--dashboardActive1--67d1f";
export var dashboardActive2 = "sellerProp-module--dashboardActive2--c4c17";
export var demandedCalculator = "sellerProp-module--demandedCalculator--508fd";
export var descriptioncard1 = "sellerProp-module--descriptioncard1--038f2";
export var descriptioncard3 = "sellerProp-module--descriptioncard3--66d8f";
export var descriptioncard4 = "sellerProp-module--descriptioncard4--6bf7c";
export var detCard = "sellerProp-module--detCard--4efa9";
export var detCard1 = "sellerProp-module--detCard1--ea4ec";
export var detCard1Div = "sellerProp-module--detCard1Div--d7eb7";
export var detailIcon = "sellerProp-module--detailIcon--b894a";
export var detailsView = "sellerProp-module--detailsView--27f9b";
export var docFirst = "sellerProp-module--docFirst--cbdf2";
export var docFirst1 = "sellerProp-module--docFirst1--69098";
export var docIcon = "sellerProp-module--docIcon--d1abd";
export var docOne2 = "sellerProp-module--docOne2--15d1e";
export var docOne3 = "sellerProp-module--docOne3--e29b2";
export var docSecond = "sellerProp-module--docSecond--1e0fc";
export var docSecond1 = "sellerProp-module--docSecond1--e416c";
export var docThird = "sellerProp-module--docThird--bfaae";
export var docThird1 = "sellerProp-module--docThird1--124df";
export var dots2 = "sellerProp-module--dots2--8a557";
export var dropIcon = "sellerProp-module--dropIcon--bf337";
export var estimatedValue = "sellerProp-module--estimatedValue--05344";
export var estimatedprice = "sellerProp-module--estimatedprice--70151";
export var fa = "sellerProp-module--fa--4c390";
export var fav = "sellerProp-module--fav--d8157";
export var favorite = "sellerProp-module--favorite--283fa";
export var favorite1 = "sellerProp-module--favorite1--ef52e";
export var favorite2 = "sellerProp-module--favorite2--d3aa2";
export var favorite3 = "sellerProp-module--favorite3--a7aff";
export var featurehead = "sellerProp-module--featurehead--86376";
export var footerdes = "sellerProp-module--footerdes--da8f9";
export var homeImage = "sellerProp-module--homeImage--2e127";
export var homePrice = "sellerProp-module--homePrice--0c563";
export var homedescription1 = "sellerProp-module--homedescription1--faf57";
export var homedescription2 = "sellerProp-module--homedescription2--e01a6";
export var homedescription3 = "sellerProp-module--homedescription3--25fcf";
export var homedescription4 = "sellerProp-module--homedescription4--faa40";
export var houseSale = "sellerProp-module--houseSale--34d3f";
export var housetext = "sellerProp-module--housetext--4a146";
export var housetext1 = "sellerProp-module--housetext1--0e1b9";
export var iconBack = "sellerProp-module--iconBack--42335";
export var iconBack1 = "sellerProp-module--iconBack1--00b9d";
export var iconBack2 = "sellerProp-module--iconBack2--6babe";
export var iconBack3 = "sellerProp-module--iconBack3--939df";
export var iconCircle = "sellerProp-module--iconCircle--c6a1a";
export var iconCircle2 = "sellerProp-module--iconCircle2--ac6d0";
export var iconUp = "sellerProp-module--iconUp--b3a94";
export var imageBack1 = "sellerProp-module--imageBack1--bd823";
export var imageList = "sellerProp-module--imageList--9ea99";
export var imagedetails = "sellerProp-module--imagedetails--50b44";
export var imagetext = "sellerProp-module--imagetext--51683";
export var incredibletext = "sellerProp-module--incredibletext--05158";
export var innerbutton1 = "sellerProp-module--innerbutton1--147e2";
export var inputpara = "sellerProp-module--inputpara--7d7ae";
export var kewyeestimatortext = "sellerProp-module--kewyeestimatortext--47faf";
export var keyEstimator = "sellerProp-module--keyEstimator--79936";
export var keyEstimatorbtn = "sellerProp-module--keyEstimatorbtn--254bb";
export var liList = "sellerProp-module--liList--8919b";
export var likeCount = "sellerProp-module--likeCount--cf629";
export var link = "sellerProp-module--link--da08d";
export var listBack = "sellerProp-module--listBack--456f1";
export var listData = "sellerProp-module--listData--ba6a1";
export var listDocs = "sellerProp-module--listDocs--a2d94";
export var listaddress = "sellerProp-module--listaddress--1b2ef";
export var listaddressprice = "sellerProp-module--listaddressprice--54ade";
export var listdetailbeds = "sellerProp-module--listdetailbeds--7ca5b";
export var listdetailbeds2 = "sellerProp-module--listdetailbeds2--163b0";
export var listedaddress1 = "sellerProp-module--listedaddress1--d9dc5";
export var listedaddress2 = "sellerProp-module--listedaddress2--26c72";
export var listeprice = "sellerProp-module--listeprice--0d099";
export var listingAddress = "sellerProp-module--listingAddress--93dca";
export var listingAgent = "sellerProp-module--listingAgent--3823c";
export var listingName = "sellerProp-module--listingName--0ab35";
export var listingStaus = "sellerProp-module--listingStaus--be255";
export var listingView = "sellerProp-module--listingView--7f020";
export var listnumberprice = "sellerProp-module--listnumberprice--65ea6";
export var listtext = "sellerProp-module--listtext--a48a1";
export var memberIcon = "sellerProp-module--memberIcon--ed16a";
export var memberSale = "sellerProp-module--memberSale--7fc3d";
export var memberSale2 = "sellerProp-module--memberSale2--88cc5";
export var memberbut = "sellerProp-module--memberbut--a315f";
export var memberbut2 = "sellerProp-module--memberbut2--d5c8d";
export var memberdetails = "sellerProp-module--memberdetails--e33db";
export var meterBar = "sellerProp-module--meterBar--e4955";
export var mobileImage = "sellerProp-module--mobileImage--7ba9b";
export var modalContent = "sellerProp-module--modal-content--c8957";
export var modelbox = "sellerProp-module--modelbox--7a27a";
export var navbarList = "sellerProp-module--navbarList--facd3";
export var numfour = "sellerProp-module--numfour--8c8c4";
export var numone = "sellerProp-module--numone--e9784";
export var numthree = "sellerProp-module--numthree--0b8ac";
export var numtwo = "sellerProp-module--numtwo--cb15a";
export var numzero = "sellerProp-module--numzero--1c8df";
export var off = "sellerProp-module--off--17549";
export var on = "sellerProp-module--on--23a74";
export var otherbut = "sellerProp-module--otherbut--6ec24";
export var otherbut1 = "sellerProp-module--otherbut1--1ff39";
export var ownedpropadddress = "sellerProp-module--ownedpropadddress--47bd1";
export var pacContainer = "sellerProp-module--pac-container--6303f";
export var phoneNUmberSeller = "sellerProp-module--phoneNUmberSeller--012dd";
export var phonenumber = "sellerProp-module--phonenumber--f18d3";
export var popupsearch = "sellerProp-module--popupsearch--1f50e";
export var popupselect = "sellerProp-module--popupselect--a3098";
export var pricedetail = "sellerProp-module--pricedetail--a0bff";
export var profileImage = "sellerProp-module--profileImage--335ea";
export var profileMain = "sellerProp-module--profileMain--bd618";
export var propCard = "sellerProp-module--propCard--681fc";
export var propCard1 = "sellerProp-module--propCard1--69028";
export var propImage = "sellerProp-module--propImage--9c604";
export var propback = "sellerProp-module--propback--e5c26";
export var recentCard = "sellerProp-module--recentCard--830ee";
export var recentImage = "sellerProp-module--recentImage--6aab4";
export var recently = "sellerProp-module--recently--84aaf";
export var recently1 = "sellerProp-module--recently1--39c61";
export var schedulbut = "sellerProp-module--schedulbut--07f1f";
export var search = "sellerProp-module--search--0836a";
export var searchIcon = "sellerProp-module--searchIcon--c9624";
export var searchbox = "sellerProp-module--searchbox--a7f6e";
export var searchbox2 = "sellerProp-module--searchbox2--3e310";
export var selectAgent = "sellerProp-module--selectAgent--0d059";
export var selectdistance = "sellerProp-module--selectdistance--5fc5e";
export var sellerActive = "sellerProp-module--sellerActive--e87d1";
export var sellerlistingcontainer = "sellerProp-module--sellerlistingcontainer--43b51";
export var sideBar = "sellerProp-module--sideBar--15e75";
export var sideBar1 = "sellerProp-module--sideBar1--cb1bb";
export var simCard = "sellerProp-module--simCard--cb845";
export var simCard1 = "sellerProp-module--simCard1--b52a0";
export var simCard2 = "sellerProp-module--simCard2--09c91";
export var similar = "sellerProp-module--similar--15011";
export var similar1 = "sellerProp-module--similar1--2370a";
export var similarRecent = "sellerProp-module--similarRecent--9d318";
export var similarback = "sellerProp-module--similarback--1b4e1";
export var similarback1 = "sellerProp-module--similarback1--d0d46";
export var similarback2 = "sellerProp-module--similarback2--d752b";
export var similarback3 = "sellerProp-module--similarback3--669a6";
export var smallCard = "sellerProp-module--smallCard--dc630";
export var smallCard1 = "sellerProp-module--smallCard1--6dbb0";
export var sqftstyle = "sellerProp-module--sqftstyle--610ba";
export var sqftstyle2 = "sellerProp-module--sqftstyle2--ea06b";
export var successtext = "sellerProp-module--successtext--f12cc";
export var successtext2 = "sellerProp-module--successtext2--d6023";
export var suggestedtext = "sellerProp-module--suggestedtext--aba37";
export var time = "sellerProp-module--time--b2b13";
export var timebut1 = "sellerProp-module--timebut1--00804";
export var timebut2 = "sellerProp-module--timebut2--f9c91";
export var tourbut = "sellerProp-module--tourbut--c307b";
export var tourconfirm = "sellerProp-module--tourconfirm--c7db9";
export var toursearch = "sellerProp-module--toursearch--657dd";
export var tourtext = "sellerProp-module--tourtext--cbae4";
export var tourtext1 = "sellerProp-module--tourtext1--04034";
export var transferbut = "sellerProp-module--transferbut--7c151";
export var ulList = "sellerProp-module--ulList--d8879";
export var unClaim = "sellerProp-module--unClaim--4eb23";
export var unClaim1 = "sellerProp-module--unClaim1--9e9cb";
export var upload = "sellerProp-module--upload--195d5";
export var upload1 = "sellerProp-module--upload1--94587";
export var upload2 = "sellerProp-module--upload2--00e3a";
export var upload3 = "sellerProp-module--upload3--b9fb0";
export var viewbut = "sellerProp-module--viewbut--fe026";
export var viewbutton = "sellerProp-module--viewbutton--0d4c9";
export var viewdetails = "sellerProp-module--viewdetails--be607";
export var viewdetails1 = "sellerProp-module--viewdetails1--88443";
export var viewmytourbut = "sellerProp-module--viewmytourbut--51273";
export var viewtext = "sellerProp-module--viewtext--19fe8";
export var vitalize = "sellerProp-module--vitalize--a20a6";
export var vitalizeBtn = "sellerProp-module--vitalizeBtn--be60e";
export var vitalizetext = "sellerProp-module--vitalizetext--2930b";
export var week = "sellerProp-module--week--7bc6d";
export var week1 = "sellerProp-module--week1--3784e";
import React, { useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import * as style1 from '../../pages/dashboard/detail.module.css';
// import { notificationDelete,deleteGroup } from '../Api/ListingApi'
import { deleteGroup } from "../../components/Api/ListingApi";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastPopup from "../../components/popup/ToastPopup";
function DeleteGroupPopup(props) {
  const { showDelete,toggleDelete,networklist,reload} = props;
  const [success, setSucess] = useState(false);
  const[messages,setMessages]=useState("")
  const toggleSuccess = () => setSucess(p => !p);
  const deleteButton = async () => {
    let id = networklist._id;
    await deleteGroup(id).then((res) => {
        toggleDelete()
        reload()
      setMessages(res.data.message)

        toggleSuccess()
    }
    ).catch((error) => {
        console.log(error)
    })
}


  return (
    <div>
       <ToastContainer />
      <ToastPopup success={success} toggleSuccess={toggleSuccess} messages={messages} />

      <Modal show={showDelete} onHide={toggleDelete} className='ModalDiv'>
      <div className="PopUpBorderDiv">

        <Modal.Header closeButton className="team-popup-margin teampopup-title " >
          <Modal.Title className='form-field-label form-label-team '></Modal.Title>
        </Modal.Header>
        <Modal.Body className="team-popup-margin pb-0" >
          <div>
            <div className={style1.successtext}>
              <>
                <p className={style1.accepttext}>Are you sure you want to Delete?</p>
                {/* <p className={style1.accepttext}>{para2}</p> */}
              </>
            </div>
          </div>
          <Button className={style1.nobutton} onClick={toggleDelete}>
            No
          </Button>
          <Button className={style1.yesbutton} onClick={deleteButton}>
            Yes
          </Button>
        </Modal.Body>
        </div>
      </Modal>
    </div>
  )
}
export default DeleteGroupPopup
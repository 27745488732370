import React from 'react'
import { Label } from 'reactstrap'
import ReactGA from "react-ga";

function gaEventTracker(category="Event Category") {
    const trackEvent = (action = "action", label = "label") => {
        ReactGA.event({ category, action, label })

    }

    return trackEvent
}

export default gaEventTracker
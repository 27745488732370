import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { Chat, Channel, Window, MessageList, MessageInput, LoadingIndicator } from "stream-chat-react";
import "stream-chat-react/dist/css/index.css";
import { StreamChat } from "stream-chat";
import { useDispatch } from "react-redux";
import AvatarImage from '../Avatar/avatarImage';
import { chattingUserData } from '../../state/dashboardViewAction';

export default function ChatPopup(props) {
    const { show, toggleShow, agent, user } = props;
    const [client, setClient] = useState(null);
    const [channel, setChannel] = useState(null);
    const apikey = process.env.STREAM_CHAT_APIKEY;
    // const filter = { type: 'messaging', member: { $in: [user.id, agent.id] } }
    // const userDetails = useSelector(state => state.user.userDetail);
    // const chatUser = useSelector(state => state.dashboard.chat_disconnect);
    const dispatch = useDispatch()

    const hanldeImage = (img) => {
        let data = img !== null && img.split('/')[0]
        if (data === "uploads") {
            return process.env.API_IMAGE_URL + img
        }
        else {
            return img
        }
    }
    useEffect(() => {
        async function init() {
            const chatClient = StreamChat.getInstance(apikey);
            await chatClient.connectUser(user, chatClient.devToken(user.id, user.image));
            const channel = chatClient.channel('messaging',
                {
                    members: [user.id, agent.id],

                })

            await channel.watch();

            setChannel(channel);
            setClient(chatClient);
        }

        init();
    }, [props]);

    if (!client) return <LoadingIndicator />;

    const handleClose = () => {
        dispatch(chattingUserData(null))
        client.disconnectUser()
        toggleShow()
    }
    return (
        <div>
            <Modal show={show} onHide={handleClose} size="md" backdrop="static" className="ModalDiv">
                <div className="PopUpBorderDiv">
                    <Modal.Header closeButton className="team-popup-margin teampopup-title">
                        <Modal.Title className='form-field-label form-label-team' style={{ fontFamily: "DejaVuSansBold" }} >Chat</Modal.Title>
                    </Modal.Header><hr />
                    <Modal.Body className="team-popup-margin p-0 chatPopup">
                        <Chat client={client} theme="messaging light">
                            <Channel channel={channel}>
                                <Window>
                                    <div className='d-flex align-items-center chatImage'>
                                        {agent.image ?
                                            (agent.image.includes('mls') ? <img src={agent.image !== undefined && hanldeImage(agent.image)} alt='tour2' className="tour" width={75} height={75} style={{ borderRadius: "40px" }} /> :
                                                <img src={agent.image !== undefined && hanldeImage(agent.image)} alt='tour2' className="tour" width={75} height={75} style={{ borderRadius: "40px" }} crossorigin='anonymous' />)
                                            : <div style={{ width: '75px', height: '75px', position: 'relative' }}><AvatarImage fontSize={'38px'} data={agent.name ? agent.name : ''} /></div>
                                        }
                                        <h5 className='text-capitalize' style={{ marginLeft: "10px", fontFamily: "DejaVuSansBold" }}>{agent.name}</h5>
                                    </div>
                                    <MessageList />
                                    <MessageInput />
                                </Window>
                            </Channel>
                        </Chat>
                    </Modal.Body>
                    <Modal.Footer className="team-popup-margin teampop-footer" ></Modal.Footer>
                </div>
            </Modal>
        </div>
    )
}

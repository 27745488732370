import { Link } from "gatsby";
import React, { useEffect, useState } from "react";
import * as styles from './header.module.css';
import { getSocialIcons } from "./Api/ListingApi";
import "./Footer.css"


export default function Footer({ isLogin }) {
    const [data, setData] = useState([])

    useEffect(() => {
        async function fetchData() {
            listingApis();
        } fetchData()
    }, []);

    const listingApis = async () => {
        await getSocialIcons().then((res) => {
            setData(res.data.data.social)
        })
    }
    return (
        <>
            <div>
                {/* <div className="row footerdes align-items-start"> */}
                {/* <>
                    {window.location.pathname !== "/homepage" && window.location.pathname !== "/termscondition/" && window.location.pathname !== "/privacypolicy" &&
                        <div className="col-md-5 footer-link">
                            {!isLogin ?
                                <>
                                    <Link to="/" className={styles.footerNav} activeStyle={{ color: '#0490fb' }}>Home</Link>
                                    <Link to="/signin" className={styles.footerNav} activeStyle={{ color: '#0490fb' }}>Login</Link>
                                    <Link to="/contact" className={styles.footerNav} activeStyle={{ color: '#0490fb' }} >Contact</Link>
                                    <Link to="/termscondition/" className={styles.footerNav} activeStyle={{ color: '#0490fb' }}>Terms</Link>
                                    <Link to="/privacypolicy" className={styles.footerNav} activeStyle={{ color: '#0490fb' }} >Privacy</Link>
                                </>
                                : <>
                                    <Link to="/" className={styles.footerNav} activeStyle={{ color: '#0490fb' }}>Home</Link>
                                    <Link to="/termscondition/" className={styles.footerNav} activeStyle={{ color: '#0490fb' }} >Terms</Link>
                                    <Link to="/privacypolicy" className={styles.footerNav} activeStyle={{ color: '#0490fb' }} >Privacy</Link>
                                    <Link to="/contact" className={styles.footerNav} activeStyle={{ color: '#0490fb' }} >Contact</Link>
                                </>}
                        </div>
                    }
                </>
                <>
                    {window.location.pathname !== "/homepage" && window.location.pathname !== "/termscondition/" && window.location.pathname !== "/privacypolicy" &&
                        data.map((item, i) => (

                            <div className="col-md-2 text-center " key={i} style={{ width: "20px" }}>
                                <a href={item.page_url} target="_blank" rel="noreferrer"> <img className="socialIcons" src={process.env.API_IMAGE_URL + item.app_icon} crossOrigin="anonymous" alt="social"></img></a>
                            </div>

                        ))

                    }
                </>
                <div className="col-md-5">
                    {window.location.pathname !== "/homepage" && window.location.pathname !== "/privacypolicy" && window.location.pathname !== "/termscondition/" ?
                        <p className="footercopy">Copyright  <i className="fa fa-copyright"></i> 2023 KeyWe</p>
                        : <p className="footercopy1">Copyright  <i className="fa fa-copyright"></i> 2023 KeyWe</p>}
                </div> */}


                <footer className="atf-footer-area atf-pattern-area">
                    <div className="container-fluid">
                        <div className="atf-footer-top mt-5">
                            <div className="row">
                                <div className="col-lg-3 col-md-6 col-12 atf-footer-link wow fadeIn" data-wow-duration="1s"
                                    data-wow-delay="0.2s" data-wow-offset="0">
                                    <div className="atf-footer-box">
                                        <Link to="/">
                                            <h5><img className="atf-footer-logo" src="/images/keyweUpdatedlogo.png" width="100"
                                                height="100" alt="GoKeywe" /></h5></Link>
                                    </div>
                                    <div className="atf-footer-social-icon mt-3 text-left">
                                        <a href="https://www.facebook.com/people/Go-KeyWe/100091772771110/" target="_blank" className="icon" data-toggle="tooltip" data-placement="bottom"
                                            title="Coming Soon"><img src="/images/facebook.png" width="100"
                                                height="100" alt="facebook" />
                                        </a>
                                        <a href="https://twitter.com/go_keywe" target="_blank" className="icon" data-toggle="tooltip" data-placement="bottom"
                                            title="Coming Soon"><img src="/images/twitter.png" width="100"
                                                height="100" alt="twitter" /></a>
                                        <a href="https://www.linkedin.com/company/keywe-inc/" target="_blank" className="icon" data-toggle="tooltip" data-placement="bottom"
                                            title="Coming Soon"><img src="/images/linkedin.png" width="100"
                                                height="100" alt="linkedin" />
                                        </a>
                                        <a href="https://www.instagram.com/go_keywe/" target="_blank" className="icon" data-toggle="tooltip" data-placement="bottom"
                                            title="Coming Soon"><img src="/images/insta.png" width="100"
                                                height="100" alt="instagram" />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12 atf-footer-link wow fadeIn mt-3 pl-5"
                                    data-wow-duration="1s" data-wow-delay="0.3s" data-wow-offset="0">
                                    <h5>Company</h5>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-12 atf-footer-link wow fadeIn mt-2"
                                            data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                            <ul className="atf-list-menu">
                                                <li style={{ listStyleType: "none" }}> <a href="https://gokeywe.com/pro/about.html">About</a></li>
                                                <li style={{ listStyleType: "none" }}> <a href="https://gokeywe.com/pro/comingsoon.html">Research</a></li>
                                                <li style={{ listStyleType: "none" }}> <a href="https://gokeywe.com/pro/news.html">News</a></li>
                                                {/* <li style={{ listStyleType: "none" }}> <a href="https://gokeywe.com/pro/blog.html">Blog</a></li> */}
                                                <li style={{ listStyleType: "none" }}>
                                                    <Link to="/blog" activeClassName="activemenu" >Blog</Link>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-12 atf-footer-link wow fadeIn mt-2"
                                            data-wow-duration="1s" data-wow-delay="0.2s" data-wow-offset="0">
                                            <ul className="atf-list-menu lastmenu">
                                                <li style={{ listStyleType: "none" }}> <a href="https://gokeywe.com/pro">Pro</a></li>
                                                <li style={{ listStyleType: "none" }}> <a href="https://gokeywe.com/pro/careers.html">careers</a></li>
                                                <li style={{ listStyleType: "none" }}> <a href="https://gokeywe.com/pro/faq.html">FAQ</a></li>
                                                <li style={{ listStyleType: "none" }}> <a href="https://gokeywe.com/pro/contact.html">Contact</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12 atf-footer-link text-left wow fadeIn mt-3"
                                    data-wow-duration="1s" data-wow-delay="0.5s" data-wow-offset="0">
                                    <h5>Contact Info</h5>
                                    <div className="atf-footer-con mt-3">
                                        <ul className="location-icon">
                                            <li style={{ listStyleType: "none" }}><img src="/images/pin.png" width="100" height="100" alt="locationimg"
                                                className="medialoction-img" />Los Gatos, California</li>

                                            <li style={{ listStyleType: "none" }}><img src="/images/mail.png" width="100" height="100"
                                                className="medialoction-img" alt="mail" /><a
                                                    href="mailto:info@keywe.com" style={{ textDecoration: "none" }}>info@gokeywe.com</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="atf-footer-boottom mt-4">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 col-xl-12 col-12 my-auto text-lg-center wow fadeIn" data-wow-duration="1s"
                                    data-wow-delay="0.2s" data-wow-offset="0">
                                    <p className="copysection">
                                        <a href="#" style={{ cursor: "default" }}>Copyright © 2023 KeyWe</a>
                                        <a href="https://gokeywe.com/pro/terms.html" style={{ textDecoration: "none" }}>Terms of Service</a>
                                        <a href="https://gokeywe.com/pro/privacy.html" style={{ textDecoration: "none" }}>Privacy Policy</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
            <noscript>{`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5DCZ5QT"
          height="0" width="0" style="display:none;visibility:hidden"></iframe>`}</noscript>
        </>
    )
}


import React, { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import * as style1 from '../../pages/dashboard/detail.module.css';
import { unclaimAgent,  } from "../Api/ListingApi"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function UnClaimPopup(props) {
  const { userSuccess, toggleUserSuccess, data, refresh } = props;


  const HandleUnclaim = async () => {
    let userId = localStorage.getItem('userId')
    let datas = {
      agent_id: data.agent_id._id
    }
    await unclaimAgent(userId, datas).then((res) => {
      toggleUserSuccess()
      refresh()
      toast.error("Agent has been removed successfully", {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });
    }).catch((error) => {
      console.log(error)
    })
  }


  return (
    <div>
      <ToastContainer />
      <Modal show={userSuccess} onHide={toggleUserSuccess} className='ModalDiv'>
        <div style={{ border: "1px solid rgb(4, 144, 251)", borderRadius: "13px" }}>

          <Modal.Header closeButton className="team-popup-margin teampopup-title " >
            <Modal.Title className='form-field-label form-label-team '></Modal.Title>
          </Modal.Header>
          <Modal.Body className="team-popup-margin pb-0" >
            <div>
              <div className={style1.successtext}>
                <p className={style1.accepttext} style={{ margin: 0 }}>Are you sure you want to Remove this agent?</p>
              </div>
              <div className="mt-3">
                <p style={{ textAlign: "center", margin: 0 }}>when you remove this agent </p>
                <p style={{ textAlign: "center", margin: 0 }}>all the communication will be removed.</p>
              </div>
            </div>
            <Button className={style1.nobutton} onClick={toggleUserSuccess}>
              No
            </Button>
            <Button className={style1.yesbutton} onClick={HandleUnclaim}>
              Yes
            </Button>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  )
}
export default UnClaimPopup
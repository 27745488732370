import React, { useState, useEffect } from 'react';
import RegistrationForm from '../../components/form/RegistrationForm';
import { Link, navigate } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';
import { signupPost, userDetailInfo, LinkedInLogin, signinDetails } from '../../components/Api/formApi';
import Seo from "../../components/seo";
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../components/Loader';
import { useDispatch } from 'react-redux';
import { userResponse } from '../../state/userSlice';
import { updateProfile } from '../../components/Api/ListingApi';
import ToastPopup from '../../components/popup/ToastPopup';

const Registration = () => {
    const [loader, setLoader] = useState(false);
    const [codeValue, setCodeValue] = useState(false)
    let dispatch = useDispatch();
    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const code = urlParams.get('code')

        if (code && !codeValue) {
            setCodeValue(true)
            socialLinkedInSignin(code)
        }
    })
    const [success, setSucess] = useState(false);
    const [messages, setMessages] = useState("")
    const toggleSuccess = () => setSucess(p => !p);

    function commonFunctionRegistration(result, socialType) {
        if (result) {
            var uid = result.user.uid;
            var displayName = result.user.displayName;
            var email = result.user.email;

            var postData = {
                name: displayName,
                uid: uid,
                email: email,
                provider: socialType,
                role: "user",
            };
            signupPost(postData)
                .then((res) => {
                    if (res.status === 200) {
                        userDetailInfo(uid)
                            .then((res) => {
                                if (res.status === 200) {
                                    dispatch(userResponse(res.data.data));
                                    localStorage.setItem('userId', res.data.data._id)
                                    try {
                                        firebase.messaging().firebaseDependencies.installations.getToken().then(async (res) => {
                                            await updateProfile(uid, { push_token: res }).then().catch((error) => { })
                                        })
                                    }
                                    catch (error) {
                                        console.log('error', error)
                                    }
                                }
                            })
                        var a = [];
                        a.push(JSON.stringify({ email: email, uid: uid, userType: "user", provider: socialType }));
                        localStorage.setItem("userInfo", a);
                        setMessages("Registered Successfully")
                        if (res.status === 200) {
                            toggleSuccess()
                            setTimeout(() => toggleSuccess(), 3000)
                        }
                        else {
                            toggleSuccess()
                            setTimeout(() => toggleSuccess(), 3000)
                        }
                        setTimeout(() => {
                            setLoader(false);
                            navigate("/");
                        }, 1000)
                    } else {
                        commonFunctionLogin(result, socialType)
                    }
                })
                .catch((err) => {
                    setLoader(false);
                    setMessages(err.message)

                    toggleSuccess()
                    setTimeout(() => toggleSuccess(), 3000)
                })
        }
    }

    function commonFunctionLogin(result, socialType) {
        if (result) {
            var uid = result.user.uid;
            var email = result.user.email;
            signinDetails(uid)
                .then((usersDetails) => {
                    if (usersDetails.status === 200) {
                        userDetailInfo(uid)
                            .then((res) => {
                                if (res.data.data === null) {
                                    commonFunctionRegistration(result, socialType)
                                }
                                else {
                                    if (res.status === 200) {
                                        dispatch(userResponse(res.data.data));

                                        localStorage.setItem('userId', res.data.data._id)
                                        try {
                                            firebase.messaging().firebaseDependencies.installations.getToken().then(async (res) => {
                                                await updateProfile(uid, { push_token: res }).then().catch((error) => { })

                                            })
                                        }
                                        catch (error) {
                                            console.log('error', error)
                                        }
                                        let claimStatus = usersDetails.data.data.customClaims.type;
                                        var a = [];
                                        a.push(JSON.stringify({ email: email, uid: uid, userType: claimStatus, provider: socialType }));
                                        localStorage.setItem("userInfo", a);
                                        if (claimStatus) {
                                            setMessages(`Login as ${res.data.data.role} Successfully`)
                                            toggleSuccess()
                                            setTimeout(() => toggleSuccess(), 3000)
                                            setTimeout(() => {
                                                setLoader(false);
                                                if (claimStatus === "agent") {
                                                    navigate("/buyers");
                                                } else {
                                                    navigate("/");
                                                }
                                            }, 1000)

                                        }
                                    }
                                }

                            })
                    }
                })
                .catch((err) => {
                    setLoader(false);
                    setMessages(err.message)
                    toggleSuccess()
                    setTimeout(() => toggleSuccess(), 3000)
                })
        } else {
            setLoader(false);
            setMessages('Email address is not verified')
            // toggleSuccess()
            setTimeout(() => toggleSuccess(), 3000)
        }
    }


    const socialGoogleSignin = () => {
        setLoader(true);

        var provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider).then(function (result) {
            if (result) {
                commonFunctionRegistration(result, "google");
            } else {
                commonFunctionLogin(result, "google")
            }
        })
            .catch((error) => {
                setLoader(false);
                setMessages(error.message)
                // toggleSuccess()
                setTimeout(() => toggleSuccess(), 3000)

            });
    }

    const socialFacebookSignin = () => {
        setLoader(true);
        var provider = new firebase.auth.FacebookAuthProvider();

        firebase
            .auth()
            .signInWithPopup(provider)
            .then((result) => {
                if (result) {
                    commonFunctionRegistration(result, "facebook");
                }
            })
            .catch((error) => {
                setLoader(false);
                setMessages(error.message)
                // toggleSuccess()
                setTimeout(() => toggleSuccess(), 3000)
                // ...
            });

    }

    const socialTwitterSignin = () => {
        setLoader(true);
        var provider = new firebase.auth.TwitterAuthProvider();

        firebase
            .auth()
            .signInWithPopup(provider)
            .then((result) => {
                if (result) {
                    commonFunctionRegistration(result, "twitter");
                }
            }).catch((error) => {
                setLoader(false);
                setMessages(error.message)
                // toggleSuccess()
                setTimeout(() => toggleSuccess(), 3000)
            });

    }

    const socialAppleSignin = () => {
        setLoader(true);
        var provider = new firebase.auth.OAuthProvider('apple.com');

        firebase
            .auth()
            .signInWithPopup(provider)
            .then((result) => {
                if (result) {
                    commonFunctionRegistration(result, "apple");
                }
            })
            .catch((error) => {
                setLoader(false);
                setMessages(error.message)
                // toggleSuccess()
                setTimeout(() => toggleSuccess(), 3000)
            });
    }
    const socialLinkedInSignin = (code) => {
        setLoader(true);
        let datas = {
            "redirect_uri": window.location.protocol + "//" + window.location.host + '/signup',
            "code": code
        }

        LinkedInLogin(datas).then(data => {
            let values = {
                user: {
                    uid: data.data.data.localId,
                    email: data.data.data.email,
                    displayName: data.data.data.displayName,
                }
            }
            commonFunctionRegistration(values, "linkedin")
        }).catch(error => {
            setLoader(false);
        })
    }

    const openInNewTab = () => {
        window.open("https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=7846itss6897ul&scope=r_emailaddress,r_liteprofile&state=123456&redirect_uri=" + window.location.protocol + "//" + window.location.host + '/signup', '_self').focus();

    }

    return (
        <div className='container position-relative'>
            <ToastPopup success={success} toggleSuccess={toggleSuccess} messages={messages} />
            {loader ? <Loader /> : null}
            <Seo title="Sign Up" />
            <div className='logoupdate1 position-absolute'>
                <Link to="/" ><img className="register-logo" src="/images/keyweUpdatedlogo.png" alt="logo" /></Link></div>
            <div className='row bgWhite' id='SignUpContainerDiv'>
                <div className='col-lg-6 col-xl-6 col-md-6 p-0' >
                    <div className='register-leftside'>
                        <div className='inner-text'>
                            <p className='reg-welcome WelcomeBacktext'>Welcome Back!</p>
                            <Link to="/signin"><button className='signin-btn SignINButtons'>LogIn</button></Link>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col-xl-6 col-md-6 p-0'>
                    <div className='register-rightside SignUpRight'>
                        <div className='register-iconsection'>
                            <p className='registration font-weight-bold signupregister '>Registration</p>
                            <div className="registerIcons ReGisterIcons">
                                {/* <div className='SignUpSocialIcons' style={{ display: "flex", gap: "1rem" }}>
                                    <div style={{cursor:"pointer"}}><img alt='social' onClick={socialFacebookSignin} onKeyPress={socialFacebookSignin} src="/images/Group 353.png" aria-hidden/></div>
                                    <div style={{cursor:"pointer"}}><img alt='social' onClick={socialTwitterSignin} onKeyPress={socialTwitterSignin} src="/images/Group 354.png" aria-hidden/></div>
                                    <div style={{cursor:"pointer"}}><img alt='social' onClick={openInNewTab} onKeyPress={openInNewTab} src="/images/Group 355.png" aria-hidden/></div>
                                    <div style={{cursor:"pointer"}}><img alt='social' onClick={socialGoogleSignin} onKeyPress={socialGoogleSignin} src="/images/Group 356.png" aria-hidden/></div>
                                    <div style={{cursor:"pointer"}}><img alt='social' onClick={socialAppleSignin} onKeyPress={socialAppleSignin} src="/images/Group 357.png" aria-hidden/></div>
                                </div> */}
                            </div>
                            <div className='hr-section HrSectiondiv' style={{ width: "90%", margin: "43px 0 31px 0" }} >
                                {/* <span className='hr-text horizontalText' >
                                    OR
                                </span> */}
                            </div>
                        </div>
                        <RegistrationForm />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Registration;
import React, { useEffect,useState } from "react"
import GoogleMapReact from "google-map-react"
import axios from "axios"
import * as styles from "../../pages/seller/sellerProp.module.css"
import Dummy from "../../../static/images/house4.png"
// import axios from 'axios'

const AnyReactComponent = ({ text }) => <div className="mapView"><i className="fa fa-map-marker f-24 color-red" aria-hidden="true"></i>
</div>

function PropMap(props) {
  const [googleImg, setGoogleImg]=useState("")

  const data={
    lat: props.lat !== undefined && props.lat !== null && props.lat,
    lng: props.lng !== undefined && props.lng !== null && props.lng
  }
  // console.log(props.address);
  // useEffect(async()=>{
  //   await axios.get(` https://maps.googleapis.com/maps/api/streetview?size=400x300&location=${props.address}&key=AIzaSyCVpTy7dON49XuSmtftSLfjgw2QxTSia2M`).then((res) => {
  // console.log(res);
  // })
  // },[])
  // console.log("data lat",data)
  const ImageCard=(address)=>{
    // console.log(address,"address");
   
    if(address!==undefined){
      var address1=address.replace("#","")
      axios
      .get(`https://maps.googleapis.com/maps/api/streetview?size=449x290&location=${address1}&key=AIzaSyCVpTy7dON49XuSmtftSLfjgw2QxTSia2M`, {
        headers: {
          "Content-Type": 'application/json;charset=UTF-8'
        }
      })
      .then((response) => {
      setGoogleImg(`https://maps.googleapis.com/maps/api/streetview?size=449x290&location=${address1}&key=AIzaSyCVpTy7dON49XuSmtftSLfjgw2QxTSia2M`)
      //  console.log("response",response.status);
  
      })
      .catch((error) => {
          setGoogleImg(Dummy)
          // console.log("response",error)
      });
    }

    return(
        googleImg
    )
 }
    return (
      <div style={{ display: "flex" }}>
        <div className={styles.carouselImage} >
          {/* <img src={`https://=${props.address}&key=AIzaSyCVpTy7dON49XuSmtftSLfjgw2QxTSia2M`} alt="thumbnailImage"/> */}
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.FIREBASE_API_KEY,
              libraries:['places'],
            }}
            center={data}
            defaultZoom={18}
          >
            <AnyReactComponent
              lat={data.lat}
              lng={data.lng}
              text="My Marker"
            />
          </GoogleMapReact>
        </div>
      </div>
    )
}

export default PropMap

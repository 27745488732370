import React, { useState } from 'react';
import * as style1 from '../../pages/dashboard/detail.module.css';
import { Modal, Button } from 'react-bootstrap';
import ProperyDetails from './propertDetails';
import { navigate } from "gatsby";
import { CreateCBagents } from '../Api/ListingApi';
import { useDispatch } from 'react-redux';
import { userResponse } from '../../state/userSlice';
import { useSelector } from 'react-redux';
import { userDetailInfo } from '../Api/formApi';
import { createBrowserHistory } from 'history';

function ColdWellPopup(props) {
  const { success, toggleSuccess, propertyId, listView, propertDetails } = props;
  console.log("propertDetails", propertDetails)
  const [openProperty, setOpenProperty] = useState(false)
  const userDetails = useSelector(state => state.user.userDetail);
  var Pro = props.propertyId;
  let dispatch = useDispatch();

  const hanldeOpenDetails = () => {
    // setOpenProperty(p => !p)
    window.open(`/homedetails/${(propertDetails.address).replaceAll(", ", '-').replaceAll(" ", '-').replaceAll("#", '%23')}`, "_blank")
  }

  const handleSubmit = async (pro) => {
    // console.log("pro", pro)
    let userId = localStorage.getItem('userId')
    let getParams = {
      "user_id": userId,
    }
    if (propertDetails.companyID !== undefined && propertDetails.companyID == "1") {
      getParams.cb_active = true
    }
    if (propertDetails.companyID !== undefined && propertDetails.companyID == "2") {
      getParams.kw_active = true
    }
    if (propertDetails.companyID !== undefined && propertDetails.companyID == "4") {
      getParams.in_active = true
    }
    if (propertDetails.companyID !== undefined && propertDetails.companyID == "3") {
      getParams.co_active = true
    }
    if (propertDetails.companyID !== undefined && propertDetails.companyID == "5") {
      getParams.ta_active = true
    }
    await CreateCBagents(getParams).then((res) => {
    })

    let uid = userDetails.uid
    await userDetailInfo(uid)
      .then((res) => {
        localStorage.setItem('userId', res.data.data._id)
        if (res.status === 200) {
          dispatch(userResponse(res.data.data));
        }
      })
    if (listView) {
      if (window.location.pathname !== "/interestedproperty") {

        hanldeOpenDetails()
        //  toggleSuccess()
      }
      else {
        if (window.location.pathname == "/interestedproperty")
        {
          props.open()
        }
        
        // toggleSuccess()
      }
    }
    else {

      hanldeOpenDetails()
      // toggleSuccess()
    }
  }

  return (
    <div>
      {openProperty && <ProperyDetails openProperty={openProperty} hanldeOpenDetails={hanldeOpenDetails} id={propertyId} propertDetails={propertDetails} toggleClose={toggleSuccess} />}
      {openProperty === false && <Modal show={success} onHide={toggleSuccess}>


        <Modal.Header closeButton className="team-popup-margin teampopup-title " >
          <Modal.Title className='form-field-label form-label-team '></Modal.Title>
        </Modal.Header>
        <Modal.Body className="team-popup-margin pb-0" >
          <div>
            <div className={style1.successtext}>

              <p className={style1.accepttext}> {(propertDetails !== undefined && propertDetails.brokerage_exclusive !== false && propertDetails !== undefined && propertDetails.companyID === "1") ? "Are you a client of ColdWell Banker ?" : (propertDetails !== undefined && propertDetails.brokerage_exclusive !== false && propertDetails !== undefined && propertDetails.companyID === "2") ? "Are you a client of willliams  ?" : (propertDetails !== undefined && propertDetails.brokerage_exclusive !== false && propertDetails !== undefined && propertDetails.companyID === "3") ? "Are you a client of Compass ?" : (propertDetails !== undefined && propertDetails.brokerage_exclusive !== false && propertDetails !== undefined && propertDetails.companyID === "4") ? "Are you a client of Intero Real Estate Services ?" : (propertDetails !== undefined && propertDetails.brokerage_exclusive !== false && propertDetails !== undefined && propertDetails.companyID === "5") ? "Are you a client of the Agency ?" : ""} </p>

            </div>
          </div>
          {propertDetails !== undefined && propertDetails.brokerage_exclusive !== false && propertDetails !== undefined && propertDetails.companyID !== undefined && propertDetails !== undefined && propertDetails.companyID !== "" &&
            <Button className={style1.nobutton} onClick={() => toggleSuccess()}>
              No
            </Button>}
          {propertDetails !== undefined && propertDetails.brokerage_exclusive !== false && propertDetails !== undefined && propertDetails.companyID !== undefined && propertDetails !== undefined && propertDetails.companyID !== "" &&
            <Button className={style1.yesbutton} onClick={() => { handleSubmit(Pro); toggleSuccess() }}>
              Yes
            </Button>
          }
        </Modal.Body>
      </Modal>}
    </div>
  );
}

export default React.memo(ColdWellPopup);
import React, { useEffect, useState } from "react"
import * as styles from "../dashboard.module.css"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import "bootstrap/dist/css/bootstrap.css"
import { exclusiveProperty, imageLike, imageLikeList } from "../../../components/Api/ListingApi"
import { navigate } from "gatsby"
import { useSelector } from "react-redux"
import AcceptPopup from '../../../components/popup/AcceptPopup';
import axios from 'axios';
// import { exclusiveImage } from "../../../../static/images/house2.png"
import Cards from "../../../components/card/card"
import SkeletonVerticalCard from "../../../components/card/skeletonVerticalCard"
import PropertyDetails from "../../../components/popup/propertDetails"
import { createBrowserHistory } from 'history';

function ExclusiveProperty() {
    const responsive = {
        desktop: {
            breakpoint: { max: 4000, min: 1024 },
            items: 4,
            slidesToSlide: 3, // optional, default to 1.
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2, // optional, default to 1.
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
        },
    }
    const history = createBrowserHistory();
    const [exclusiveProp, setExclusiveProp] = useState([])
    const userDetails = useSelector(state => state.user.userDetail);
    // console.log("userDetails", userDetails)
    const [openPopup, setOpenPopup] = useState(false)
    const [message, setMessage] = useState("")
    const [loader, setLoader] = useState(false);
    const [key, setKey] = useState(false)
    const [likes, setLikes] = useState([])
    const [openProperty, setOpenProperty] = useState(false)
    const [propertyId, setPropertyId] = useState("")
    const [propertDetails, setPropertyDetails] = useState({})
    const array = ["1", "2", "3", "4"]
    useEffect(() => {
        async function fetchData() {
            getGeoInfo()
        } fetchData()
    }, []);
    useEffect(() => {
        let userId = localStorage.getItem("userId")
        handleImageLikeList(userId)
    }, [])
    const getGeoInfo = () => {
        axios.get("https://ipapi.co/json/").then((response) => {
            let data = response.data;
            let getParams = {
                // longitude: -121.8853,
                // latitude: "37.3387",
                longitude: data.longitude,
                latitude: data.latitude,

            }
            if (userDetails.role === "agent") {
                getParams.agent_id = userDetails._id;
            }

            exclusiveProperty(getParams).then(res => {
                setExclusiveProp(res.data.data)
                // console.log("res.data.data", res.data.data)
            })
        }).catch((error) => {
            console.log(error);
        });
    };
    const hanldeOpenPopup = () => {
        setOpenPopup(p => !p)


    }
    const handleRoute = (data) => {
        let userId = localStorage.getItem("userId")
        if (data.listing_status === 9 && userId === null) {
            setMessage("In order to view this property details")
            hanldeOpenPopup()
            setTimeout(() => setOpenPopup(false), 3000)
        }
        else {
            navigate(`/dashboard/detail/${data._id}`)
        }
    }
    const hanldeImage = (img) => {
        let data = img !== null && img !== undefined && img.split('/')[0]
        if (data === "uploads") {
            return process.env.API_IMAGE_URL + img
        }
        else {
            return img
        }
    }

    const handleLike = async (id) => {
        let userId = localStorage.getItem('userId')
        // setIsFavorite(false)
        if (userId !== null) {
            await imageLike(userId, id).then((res) => {
                getGeoInfo()
                handleImageLikeList()
            }).catch((error) => {
                console.log('error', error)
            })
        }
        else {
            setMessage("In order to like this property details")
            hanldeOpenPopup()
            setTimeout(() => setOpenPopup(false), 3000)
        }
    }

    const handleImageLikeList = async () => {
        let userId = localStorage.getItem('userId')
        var value = []
        if (userId !== null && userId !== undefined) {
            await imageLikeList(userId).then((res) => {
                setLikes(res.data.data)
                value = res.data.data
            }).catch((error) => {
                console.log(error)
            })
            return value
        }
    }
    // hanldeOpenDetails(id, data)
    const hanldeOpenDetails = (id, data) => {
        let userId = localStorage.getItem('userId')
        if (userDetails.role === "user" && userDetails.cb_active !== false) {
            setOpenProperty(p => !p)
            setPropertyId(id)
            setPropertyDetails(data)
            // history.push(`/${data.address}`)
            navigate(`/homedetails/${(data.address).replaceAll(", ", '-').replaceAll(" ", '-').replaceAll("#", '%23')}`)
        } else if (userDetails.role === "agent") {
            setOpenProperty(p => !p)
            setPropertyId(id)
            setPropertyDetails(data)
            // history.push(`/${data.address}`)
            navigate(`/homedetails/${(data.address).replaceAll(", ", '-').replaceAll(" ", '-').replaceAll("#", '%23')}`)
        }
        else {
            hanldeOpenPopup()
        }

    }
    const hanldeOpenDetailsClose = (id, data) => {
        setOpenProperty(p => !p)
        setPropertyId(id)
        setPropertyDetails(data)
        history.push(`/`)
    }


    return (
        <>
            <AcceptPopup success={openPopup} toggleSuccess={hanldeOpenPopup} claimAlertMessage={true} message={message} />
            {/* {openProperty && propertDetails !== {} && <PropertyDetails openProperty={openProperty} hanldeOpenDetails={hanldeOpenDetailsClose} id={propertyId} propertDetails={propertDetails} />} */}
            {exclusiveProp.length !== 0 &&
                <div className="px-5">
                    <h3 className={styles.exclusiv}>Exclusive Properties</h3>
                    <Carousel
                        swipeable={false}
                        draggable={false}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        autoPlaySpeed={1000}
                        keyBoardControl={true}
                        customTransition="all .5"
                        transitionDuration={500}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["mobile"]}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-padding-40-px">
                        {/* {exclusiveProp.map((data, i) => (
                            <div key={i}>

                                <div className={styles.houses}>
                                    {data.thumbnail_image !== undefined && data.thumbnail_image !== null ?
                                        <>
                                            {data.thumbnail_image !== undefined && data.thumbnail_image !== null && data.thumbnail_image.split('/')[0] === "https:" ? <img src={data.thumbnail_image !== undefined && data.thumbnail_image !== null && hanldeImage(data.thumbnail_image)} alt='house' className={styles.house1} onClick={() => handleRoute(data)} aria-hidden /> :
                                                <img alt='house' className={styles.house1} onClick={() => handleRoute(data)} src={data.thumbnail_image !== undefined && data.thumbnail_image !== null && hanldeImage(data.thumbnail_image)} crossOrigin='anonymous' aria-hidden />}</>
                                        :
                                        <img alt='house' className={styles.house1} src="/images/house2.png" crossOrigin='anonymous' />}
                                    <div className={styles.innerbutton1}>
                                        <button type="button" className={styles.memberbutton}>Exclusive</button>
                                    </div>
                                </div>

                            </div>
                        ))} */}
                        {key ? array.map((item) => (
                            <div >
                                <div className={styles.CarouselCard1}>
                                    <SkeletonVerticalCard purpose={"card"} />
                                </div></div>
                        )) :
                            exclusiveProp.map((data, index) => {
                                return (
                                    <div key={index}>
                                        <div className={styles.CarouselCard1}>
                                            <Cards data={data} I={index} loader={() => setLoader(true)} reLoadPage={getGeoInfo} logout={handleLike} handleLike={() => hanldeOpenDetails(data._id, data)} id={data._id} likes={likes.includes(data._id)} />
                                        </div>
                                    </div>
                                )
                            })}
                    </Carousel>
                </div>
            }
        </>
    )

}
export default ExclusiveProperty
import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { CityLatLng, blogCityList } from '../../../components/Api/ListingApi'
import { get } from 'lodash'

export default function FindhomeCity() {
    const [city, setCity] = useState([])
    useEffect(() => {
        cityList()
    }, [])
    const cityList = () => {
        let getParam = {
            pagesize: 20,
            page: 1,
            sort_value: -1,
            sort_field: "updatedAt"
        }
        blogCityList(getParam).then((res) => {
            // console.log("response", res.data.data);
            setCity(res.data.data)
        }).catch((err) => {
            console.log(err);
        })
    }

    return (
        <div>
            <h3 className='mt-3' style={{ fontSize: "33px" }}>Find your dream home in popular cities</h3>
            <div className='row mt-3'>
                {/* <div className='col-md-2'>{ */}

                {city.length !== 0 && city.map((item) => (
                    <div className='col-md-3' >
                        <> <Link to={`/city/${item.url}`} state={{ props: item }} className='cityNames' style={{ "lineHeight": "2.4" }}>{item.title} Real Estate</Link><br /></>
                    </div>))
                }

                {/* <Link className='cityNames'>Oakland Real Estate</Link><br/>
                    <Link className='cityNames'>Fermont Real Estate</Link><br/>
                    <Link className='cityNames'>Sunnyvale Real Estate</Link><br/>
                    <Link className='cityNames'>Santa Clara Real Estate</Link><br/>
                    <Link className='cityNames'>San Mateo Real Estate</Link><br/>
                    <Link className='cityNames'>Daly City Real Estate</Link><br/>
                    <Link className='cityNames'>Redwood City Real Estate</Link><br/> */}
                {/* </div> */}
                {/* <div className='col-md-2'>
                    <Link className='cityNames'>Mountain View Real Estate</Link><br/>
                    <Link className='cityNames'>Milpitas Real Estate</Link><br/>
                    <Link className='cityNames'>Palo Alto Real Estate</Link><br/>
                    <Link className='cityNames'>South Jose Real Estate</Link><br/>
                    <Link className='cityNames'>Cupertino  Real Estate</Link><br/>
                    <Link className='cityNames'>Gilroy Real Estate</Link><br/>
                    <Link className='cityNames'>Morgon Hill Real Estate</Link><br/>
                    <Link className='cityNames'>Campell Real Estate</Link><br/>

                </div>
                <div className='col-md-2'>
                    <Link className='cityNames'>San Bruno Real Estate</Link><br/>
                    <Link className='cityNames'>Pacifica Real Estate</Link><br/>
                    <Link className='cityNames'>Palo Alto Real Estate</Link><br/>
                    <Link className='cityNames'>Foster City Real Estate</Link><br/>
                    <Link className='cityNames'>Menlo Park  Real Estate</Link><br/>
                    <Link className='cityNames'>Los Altos Real Estate</Link><br/>
                    <Link className='cityNames'>Burlingame Real Estate</Link><br/>
                    <Link className='cityNames'>Saratoga Real Estate</Link><br/>

                </div>
                <div className='col-md-2'>
                    <Link className='cityNames'>San Carlos Real Estate</Link><br/>
                    <Link className='cityNames'>East Palo Alto Real Estate</Link><br/>
                    <Link className='cityNames'>Belmont Real Estate</Link><br/>
                    <Link className='cityNames'>Millbrae Real Estate</Link><br/>
                    <Link className='cityNames'>Half Moon Bay  Real Estate</Link><br/>
                    <Link className='cityNames'>Hillsborough Real Estate</Link><br/>
                    <Link className='cityNames'>Los Altos Hill Real Estate</Link><br/>
                    <Link className='cityNames'>Atherton Real Estate</Link><br/>

                </div>
                <div className='col-md-2'>
                    <Link className='cityNames'>Woodside Real Estate</Link><br/>
                    <Link className='cityNames'>Brisbane Real Estate</Link><br/>
                    <Link className='cityNames'>Portola Valley Real Estate</Link><br/>
                    <Link className='cityNames'>Monte Sereno Real Estate</Link><br/>
                    <Link className='cityNames'>Cupertino  Real Estate</Link><br/>
                    <Link className='cityNames'>Colma Real Estate</Link><br/>
                </div> */}

            </div>
        </div>
    )
}

import React, { useState } from "react"
// import dots from "../../../static/images/Group 237.png"
import memberIcon from "../../../static/images/memberIcon.png"
import phonintrest from "../../../static/images/PhoneImage.png"
import msgint from "../../../static/images/ChatImage.png"
import Group236 from "../../../static/images/sharing.png"
import closebutton from "../../../static/images/Icon ionic-ios-close.png"
import * as styled from "../buyers/buyer.module.css"
import { Button, Card } from "react-bootstrap"
import { Link, navigate } from "gatsby";
import AvatarImage from "../../components/Avatar/avatarImage"
// import { useLocation } from '@reach/router';
import ChatPopup from '../../components/popup/ChatPopup'
import { StreamChat } from "stream-chat";
import "stream-chat-react/dist/css/index.css";
import { useSelector } from "react-redux";
import SharePopup from "../../components/popup/sharePopup"
import * as style1 from "../../pages/dashboard/detail.module.css"
import Loader from "../../components/Loader"
import Dummy from "../../../static/images/house4.png"
import PropertyDetails from "../../components/popup/propertDetails"
import { createBrowserHistory } from 'history';
import PropMap from "../../components/Seller/PropMap"

function OutboundDetail(props) {
    const [open, setOpen] = useState(false)
    const showDetail = () => {
        setOpen(true)
    }
    const history = createBrowserHistory();
    // const location = useLocation();
    const userDetails = useSelector(state => state.user.userDetail);
    const chattingUserData = useSelector(state => state.dashboard.chat_disconnect)
    const outboundDetail = props.data
    const [chatAgent, setChatAgent] = useState({})
    const [chatUser, setChatUser] = useState({})
    const [chatId, setChatId] = useState("")
    const [openChat, setOpenChat] = useState(false)
    const [loader, setLoader] = useState(false);
    const [openProperty, setOpenProperty] = useState(false)
    const showChatDetail = () => {
        if (openChat === false) {
            setLoader(true);
        }
        else {
            setLoader(false);

        }
        setOpenChat(p => !p)
    }
    const handleChat = async (id, name, lastName, image) => {
        let names = name
        await chattingUserData !== null && chattingUserData.disconnectUser()
        const apikey = process.env.STREAM_CHAT_APIKEY;

        const chatClient = StreamChat.getInstance(apikey);
        await chatClient.connectUser({ id: id, name: names, image: image }, chatClient.devToken(id));
        await chatClient.disconnectUser()

        setChatAgent({ id: id, name: names, image: image })
        setChatUser({ id: userDetails._id, name: userDetails.name, image: hanldeImage(userDetails.img) })
        setChatId(id + userDetails._id)
        showChatDetail()
    }
    const hanldeImage = (img) => {
        // let data = img !== null && img.split('/')[0]
        let data = img ? img.split('/')[0] : null;
        if (data === "uploads") {
            return process.env.API_IMAGE_URL + img
        }
        else {
            return img
        }
    }
    const [shows, setShows] = useState(false)
    const toggleShows = () => setShows(p => !p)

    const makeCall = (PhoneNumber) => {
        if (PhoneNumber !== null && PhoneNumber !== "") {
            window.location.href = 'tel://' + PhoneNumber;

        }
    }
    const hanldeOpenDetails = (data) => {
        // setOpenProperty(p => !p)
        // history.push(`/referralFee/${data.address}`) // when popup open
        window.open(`/homedetails/${(data.address).replaceAll(", ", '-').replaceAll(" ", '-').replaceAll("#", '%23')}`, "_blank")
    }
    const hanldeOpenDetailsClose = () => {
        setOpenProperty(p => !p)
        history.push(`/referralFee`) // when popup open

    }
    return (
        <div className="prospectback mb-5">
            <SharePopup shows={shows} toggleShows={toggleShows} id={outboundDetail.property_id !== null && outboundDetail.property_id._id} />
            {/* {openProperty && outboundDetail !== {} && <PropertyDetails openProperty={openProperty} hanldeOpenDetails={hanldeOpenDetailsClose} id={outboundDetail.property_id._id._id} propertDetails={outboundDetail.property_id} />} */}
            <Card className="prospectCard" >
                <div>

                    <div className="row" style={{ alignItems: "center" }}>
                        <div className="col-md-6" style={{ textAlign: "right" }}>
                            {outboundDetail.agent_id !== null ?
                                <>
                                    {outboundDetail.agent_id.img ?
                                        <img alt="no_image" src={process.env.API_IMAGE_URL + outboundDetail.agent_id.img} className={styled.avatarimage} crossOrigin="anonymous" />

                                        : <>{outboundDetail.agent_id.name ? <div style={{ width: '100px', height: '100px', position: "relative", margin: "auto", marginRight: "18px" }}><AvatarImage fontSize={'40px'} data={outboundDetail.agent_id.name ? outboundDetail.agent_id.name : ''} /></div>
                                            : null}</>
                                    }</> :
                                <>
                                    {outboundDetail.alter_id.img ?
                                        <img alt="no_image" src={process.env.API_IMAGE_URL + outboundDetail.alter_id.img} className={styled.avatarimage} crossOrigin="anonymous" />

                                        : <>{outboundDetail.alter_id.name ? <div style={{ width: '100px', height: '100px', position: "relative", margin: "auto", marginRight: "18px" }}><AvatarImage fontSize={'40px'} data={outboundDetail.alter_id.name ? outboundDetail.alter_id.name : ''} /></div>
                                            : null}</>
                                    }
                                </>}
                        </div>
                        <div className="col-md-6">
                            {outboundDetail.agent_id !== null ?
                                <h6 style={{ marginBottom: "15px", fontFamily: "DejaVuSansBold" }}>{outboundDetail.agent_id.name}</h6> :
                                <h6 style={{ marginBottom: "15px", fontFamily: "DejaVuSansBold" }}>{outboundDetail.alter_id.name}</h6>}

                            <div style={{ cursor: "pointer", display: "flex" }}>
                                <img src={msgint} alt="msgint" className="msgint1" onClick={() => { handleChat(outboundDetail.agent_id._id, outboundDetail.agent_id.name, outboundDetail.agent_id.last_name, outboundDetail.agent_id.img ? hanldeImage(outboundDetail.agent_id.img) : ""); }} onKeyPress={() => { handleChat(outboundDetail.agent_id._id, outboundDetail.agent_id.name, outboundDetail.agent_id.last_name, outboundDetail.agent_id.img ? hanldeImage(outboundDetail.agent_id.img) : ""); }} aria-hidden />
                                {outboundDetail.agent_id !== null ?
                                    <>
                                        {outboundDetail.agent_id.phone_number !== undefined && outboundDetail.agent_id.phone_number !== null && outboundDetail.agent_id.phone_number !== "" &&
                                            <span style={{ cursor: "pointer" }} className={styled.tooltip}>
                                                <img onClick={() => makeCall((outboundDetail.agent_id.phone_number !== undefined && outboundDetail.agent_id.phone_number !== null && outboundDetail.agent_id.phone_number !== "") ? outboundDetail.agent_id.phone_number : null)} onKeyPress={() => makeCall((outboundDetail.agent_id.phone_number !== undefined && outboundDetail.agent_id.phone_number !== null && outboundDetail.agent_id.phone_number !== "") ? outboundDetail.agent_id.phone_number : null)} aria-hidden src={phonintrest} alt="phonintrest" className={styled.phonintrest} />
                                                {outboundDetail.agent_id.phone_number !== undefined && outboundDetail.agent_id.phone_number !== null && outboundDetail.agent_id.phone_number !== "" ?
                                                    <span className={styled.tooltiptext}>{outboundDetail.agent_id.phone_number}</span> : null
                                                }

                                            </span>
                                        }
                                    </> :
                                    <>
                                        {outboundDetail.alter_id.phone_number !== undefined && outboundDetail.alter_id.phone_number !== null && outboundDetail.alter_id.phone_number !== "" &&
                                            <span style={{ cursor: "pointer" }} className={styled.tooltip}>
                                                <img onClick={() => makeCall((outboundDetail.alter_id.phone_number !== undefined && outboundDetail.alter_id.phone_number !== null && outboundDetail.alter_id.phone_number !== "") ? outboundDetail.alter_id.phone_number : null)} onKeyPress={() => makeCall((outboundDetail.alter_id.phone_number !== undefined && outboundDetail.alter_id.phone_number !== null && outboundDetail.alter_id.phone_number !== "") ? outboundDetail.alter_id.phone_number : null)} src={phonintrest} alt="phonintrest" className={styled.phonintrest} aria-hidden />
                                                {outboundDetail.alter_id.phone_number !== undefined && outboundDetail.alter_id.phone_number !== null && outboundDetail.alter_id.phone_number !== "" ?
                                                    <span className={styled.tooltiptext}>{outboundDetail.alter_id.phone_number}</span> : null
                                                }

                                            </span>
                                        }
                                    </>}

                            </div>
                        </div>
                    </div>
                    <div className="interaddress mb-4">
                        <h6 style={{ fontFamily: "DejaVuSansBold", marginBottom: "12px" }}> {outboundDetail.property_id !== null && outboundDetail.property_id.address.split(',').slice(0).shift()}</h6>
                        <p>{outboundDetail.property_id !== null && outboundDetail.property_id.address.split(",").slice(1).join(",")}</p>
                        <p>${String(outboundDetail.property_id !== null && outboundDetail.property_id.price.$numberDecimal).replace(
                            /(.)(?=(\d{3})+$)/g,
                            "$1,"
                        )}</p>
                    </div>
                    <div>
                        <Button variant="primary" className={styled.viewbut2} onClick={showDetail}>View Property Details</Button>
                    </div>
                    {open ? (
                        <div>
                            <div className={styled.imagedetails}>
                                {/* <Link to={`/dashboard/detail/${outboundDetail.property_id._id}`} style={{ textDecoration: "none" }}> */}
                                {/* {console.log("condition", outboundDetail.property_id.thumbnail_image !== null, outboundDetail.property_id.thumbnail_image !== undefined)} */}
                                {outboundDetail.property_id.thumbnail_image !== null && outboundDetail.property_id.thumbnail_image !== undefined ?
                                    <>
                                        {/* {outboundDetail.property_id !== null && outboundDetail.property_id.thumbnail_image.split("/")[0] !== "uploads" ? */}
                                        {outboundDetail.property_id?.thumbnail_image?.split("/")[0] !== "uploads" ?
                                            <img src={hanldeImage(outboundDetail.property_id !== null && outboundDetail.property_id.thumbnail_image)} alt="mask192" className={styled.inthomeImage} onClick={() => hanldeOpenDetails(outboundDetail.property_id)} /> :
                                            <img src={hanldeImage(outboundDetail.property_id !== null && outboundDetail.property_id.thumbnail_image)} alt="mask192" className={styled.inthomeImage} crossOrigin="anonymous" onClick={() => hanldeOpenDetails(outboundDetail.property_id)} />}
                                    </> :
                                    <PropMap lng={outboundDetail.property_id.location.coordinates[0]} lat={outboundDetail.property_id.location.coordinates[1]} />}
                                {/* </Link> */}
                                <img src={closebutton} alt="closebutton" className={styled.closebutton2} onClick={() => setOpen(false)} onKeyPress={() => setOpen(false)} aria-hidden />
                                <div className="shareButton2" >
                                    <img src={Group236} alt="group236" className="shareIcon2" onClick={toggleShows} onKeyPress={toggleShows} aria-hidden />
                                </div>
                                {(outboundDetail.property_id.listing_status === 3 || outboundDetail.property_id.listing_status === "3") &&
                                    <div className={styled.memberdetails}>
                                        <img src={memberIcon} alt="memberIcon" className={styled.memberIcon} />
                                        <button type="button" className={styled.memberbut2}>Exclusive</button>
                                    </div>
                                }
                            </div>
                            <div className={styled.housedetail}>
                                <div className="d-flex" style={{ marginLeft: "17px" }}>
                                    <i className="fa fa-circle ClaimActiveIcon " style={{ color: outboundDetail.property_id.listing_status === 0 ? "orange" : outboundDetail.property_id.listing_status === 1 || outboundDetail.property_id.listing_status === 2 ? "#03B45B" : outboundDetail.property_id.listing_status === 4 ? "yellow" : outboundDetail.property_id.listing_status === 6 ? "grey" : "red" }} ></i>
                                    <p className={style1.housetext1}>{outboundDetail.property_id !== null && outboundDetail.property_id.listing_status === 0 ? "Draft" : outboundDetail.property_id.listing_status === 1 ? "Active" : outboundDetail.property_id.listing_status === 2 ? "Active Contingent" : outboundDetail.property_id.listing_status === 3 ? "Exclusive" : outboundDetail.property_id.listing_status === 4 ? "Pending" : outboundDetail.property_id.listing_status === 5 ? "Sold" : outboundDetail.property_id.listing_status === 6 ? "Expired" : outboundDetail.property_id.listing_status === 7 ? "Cancelled" : outboundDetail.property_id.listing_status === 8 ? "Withdrawn" : "Keywe Exclusive"}</p>
                                </div>
                                <p className={styled.pricehouse}>${String(outboundDetail.property_id !== null && outboundDetail.property_id.price.$numberDecimal).replace(/(.)(?=(\d{3})+$)/g, "$1,")}</p>
                            </div>
                            <div className={styled.headContent}>
                                <h5 className={styled.intaddess}>{outboundDetail.property_id !== null && outboundDetail.property_id.address.split(',').slice(0).shift()},</h5>
                                <h5 className={styled.intaddess}>{outboundDetail.property_id !== null && outboundDetail.property_id.address.split(",").slice(1).join(",")}</h5>
                            </div>
                            <div className={styled.dashboardActive2}>
                                <i
                                    className="fa fa-circle"
                                    style={{ color: "green" }}
                                ></i>
                                <p style={{ marginLeft: "5px" }}>
                                    Actively Showing
                                </p>
                            </div>
                            <div>
                                <Card
                                    className={styled.detCard}
                                    style={{
                                        borderRadius: "5px",
                                        boxShadow:
                                            "0 1px 4px rgb(82 80 80 / 40%), inset 0 0 40px rgb(135 128 128 / 8%)",
                                    }}
                                >
                                    <div className="row m-0">
                                        <div className="col-md-4 cardtext2 mt-3">
                                            <p style={{ fontFamily: "DejaVuSansBold" }}>{outboundDetail.property_id !== null && outboundDetail.property_id.beds}</p>
                                            <p style={{ color: "#898686" }}>Beds</p>
                                        </div>
                                        <div className="col-md-4 cardtext2 mt-3">
                                            <p style={{ fontFamily: "DejaVuSansBold" }}>{outboundDetail.property_id !== null && outboundDetail.property_id.baths}</p>
                                            <p style={{ color: "#898686" }}>Baths</p>
                                        </div>
                                        <div className="col-md-4 cardtext2 mt-3">
                                            <p style={{ fontFamily: "DejaVuSansBold" }}>{String(outboundDetail.property_id !== null && outboundDetail.property_id.square_feet.$numberDecimal).replace(
                                                /(.)(?=(\d{3})+$)/g,
                                                "$1,"
                                            )}</p>
                                            <p style={{ color: "#898686" }}>Sq. Feet </p>
                                        </div>
                                    </div>
                                </Card>
                            </div>
                        </div>
                    ) : null}
                </div>
            </Card>
            <>
                {loader ? <Loader /> : null}
                {openChat && <ChatPopup show={openChat} toggleShow={showChatDetail} agent={chatAgent} user={chatUser} id={chatId} />}</>
        </div>
    )
}
export default OutboundDetail


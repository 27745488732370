import React, { useEffect, useState } from "react"
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import { createHistory, useLocation } from "@reach/router";
import "../blogs/blogstyle.css"
import { blogList, singleBlog } from "../../components/Api/ListingApi";
import { InlineShareButtons } from "sharethis-reactjs";
import moment from "moment-timezone";
import { Link, navigate } from "gatsby";
import { useHistory } from "react-router-dom";
import { createBrowserHistory } from 'history';
import Seo from "../../components/seo";
import BlogSeo from "../../components/BlogSeo";
function BlogDetail(props) {

    const history = createBrowserHistory()

    const [storage, setStorage] = useState(false);
    const [listBlog, setListBlog] = useState([])
    const [singleRes, setSingleRes] = useState([])
    const [contnet, setContent] = useState(null)
    const [title, setTitle] = useState(singleRes.title)
    const[image ,setImage]=useState("")
    // {console.log(" location id",history.push)}
    // useEffect(async () => {
    //     // 
    //     await metaTitle()
    //     await metaDescription()
    //     await metaUrl()
    //     await metaSite()
    //     // react-helmet does not prepend so need to do it manually
    //     // await metaImage()
    // }, [props.id]);

    // const metaImage = () => {
    //     const meta = document.createElement('meta');
    //     meta.setAttribute('property', 'og:image');
    //     meta.setAttribute('content', process.env.API_IMAGE_URL + singleRes.thumbNileImage);
    //     const head = document.querySelector('head');
    //     head.insertBefore(meta, head.firstChild);
    // }
    // const metaSite = () => {
    //     const meta = document.createElement('meta');
    //     meta.setAttribute('property', 'og:site_name');
    //     meta.setAttribute('content', "Go KeyWe");
    //     const head = document.querySelector('head');
    //     head.insertBefore(meta, head.firstChild);
    // }
    // const metaUrl = () => {
    //     const meta = document.createElement('meta');
    //     meta.setAttribute('property', 'og:url');
    //     meta.setAttribute('content', props.id);
    //     const head = document.querySelector('head');
    //     head.insertBefore(meta, head.firstChild);
    // }
    // const metaDescription = () => {
    //     const meta = document.createElement('meta');
    //     meta.setAttribute('property', 'og:description');
    //     meta.setAttribute('content', singleRes.metaDescription);
    //     const head = document.querySelector('head');
    //     head.insertBefore(meta, head.firstChild);
    // }
    // const metaTitle = () => {
    //     const meta = document.createElement('meta');
    //     meta.setAttribute('property', 'og:title');
    //     meta.setAttribute('content', title);
    //     const head = document.querySelector('head');
    //     head.insertBefore(meta, head.firstChild);
    // }

// const history = useHistory()
const [url ,setUrl]=useState("")
    useEffect(() => {
        singleBlogDetail()
        setUrl(window.location.pathname)
    
    }, [id])

    // useEffect(()=>{
    //     getSrcValue(singleRes)
    // },[singleRes])
    // const [detail, setDetail] = useState([])

    // console.log("propsdata", props);
    useEffect(async () => {
        const storedData = localStorage.getItem("userInfo");
        if (!storedData) {
            setStorage(false);
        } else {
            setStorage(true);
        }

        await blog()
    }, []);
    const blog = () => {
        let getParam = {
            pagesize: 20,
            page: 1,
            sort_value:-1,
            sort_field: "updatedAt",
        }
        blogList(getParam).then((res) => {
            // console.log("bloglist", res.data.data);
            setListBlog(res.data.data)
        }).catch((err) => {
            console.log(err);
        })
    }
    const [id, setId] = useState(props.id)
    // console.log("id", id);
    var _id = props.id
    const singleBlogDetail = () => {
        // console.log("location", props.id)
        singleBlog(id).then((res) => {
            setContent(res.data.data.content.replace(/<[^>]*>/g, '\n').replace(/&nbsp;/g, " "))
            setTitle(res.data.data.title)
            setId(res.data.data.url)
            // console.log(res.data.data.content.replace(/<[^>]*>/g, '\n').replace(/&nbsp;/g, " "))
            setSingleRes(res.data.data)
            const parser = new DOMParser();
            const doc = parser.parseFromString(res.data.data.content, 'text/html');
            // console.log(doc);
            const imageElement = doc.querySelector('img');
            // console.log(imageElement.src);
            setImage(imageElement.src)
        })
    }
   
    // const dynamicClassName = 'highlight';
    // const modifiedJSX = React.createElement('p', { className: dynamicClassName }, contnet);
    // const collection = document.getElementsByTagName("li");
    // var content=singleRes.content.replace('<p', '<p className="paraContent"')
    // const modifiedJSX = React.createElement('p', { className: dynamicClassName }, backendJSX.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, " "));
    const convertTime = (createdAt) => {
        const date = moment(createdAt, "DD/MM/YYYY");
        const formattedDate = date.format("MMMM D, YYYY");

        // console.log(formattedDate);
        return formattedDate

    }
    const handleNavigate = (id) => {
        history.push(`/blog/${id}`)
        singleBlog(id).then((res) => {
            setContent(res.data.data.content.replace(/<[^>]*>/g, '\n').replace(/&nbsp;/g, " "))
            setTitle(res.data.data.title)
            setId(res.data.data.url)
            // console.log(res.data.data.content.replace(/<[^>]*>/g, '\n').replace(/&nbsp;/g, " "))
            setSingleRes(res.data.data)
        })
    }

    return (

        <div>{url!=="" &&singleRes.url &&
            
            <BlogSeo title={singleRes.metaTitle} description={singleRes.metaDescription} url={props.id} data={singleRes} image={image} />}
            <Navbar isLogin={storage} />
            <div className="Secondary-page blogdetail">
                <div className="atf-special-offer terms-section">
                    <div className="container center-container3">
                        <div className="row section-vertical-middle py-3">

                            <div className="col-xl-12 col-lg-12 col-12 text-left">
                                <div className="row section-vertical-top ">
                                    <div className="col-xl-9 col-lg-9 col-9 text-left">

                                        <div className="atf-special-content atf-section-title mx-5 my-auto mb-5">
                                            <div className="blog-detail-section mb-5">
                                                {singleRes.thumbNileImage !== undefined && singleRes.thumbNileImage !== null && singleRes.thumbNileImage !== "" &&
                                                    <div className="blog-banner mb-5">
                                                        <img src={singleRes.thumbNileImage} alt="real estate market" crossOrigin="anonymous" className="image" title="real estate market" />
                                                    </div>}
                                                <h1 className="text-center">{singleRes.title}</h1>
                                                {/* <h1 className="text-center">{singleRes.title.charAt(0).toUpperCase()+singleRes.title.slice(1)}</h1> */}
                                                <div className="text-center blogdetaildate">{convertTime(singleRes.published_date)}</div>
                                                <h3 className="text-center" style={{marginTop:"12px"}}>{singleRes.author}</h3>
                                                {/* <p className="fontStyleForContent" >{singleRes.content.replace(/<[^>]*>/g, '\n').replace(/&nbsp;/g, " ")}</p> */}
                                                <div className="highlight mt-2" dangerouslySetInnerHTML={{ __html: singleRes.content }}></div>
                                                {/* <div dangerouslySetInnerHTML={{ __html: modifiedJSX }} /> */}
                                                {/* {modifiedJSX} */}
                                                {/* </p> */}
                                                {/* <p className="fontStyleForContent" >{singleRes.content.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, " ") }</p> */}

                                                {/* <p>Buying a house is one of the most significant decisions we make in our lives. It means having a steady career and managing our finances in such a way that our EMIs are taken care of. So, is this the right time to pursue a dream house?</p>  */}


                                            </div>

                                            <div className="social-share-icon">
                                                <div className="shareIcon">Share on</div>
{/* {console.log(history.location)}
{console.log(url)} */}
                                                {/* <div className="sharethis-inline-share-buttons"></div> */}
                                                <InlineShareButtons
                                                    config={{
                                                        alignment: 'center',
                                                        color: 'social',
                                                        enabled: true,
                                                        font_size: 17,
                                                        language: 'en',
                                                        networks: [
                                                            'facebook',
                                                            'twitter',
                                                            'gmail',
                                                            'linkedin',
                                                        ],
                                                        padding: 10,
                                                        radius: 25,
                                                        size: 40,
                                                        url: window.location.href,
                                                        description: 'Blog sharing link',
                                                        title: 'Blog sharing link',
                                                        message: 'Blog sharing link',
                                                        subject: 'Blog sharing link',
                                                        //   username: 'custom twitter handle' 
                                                    }}
                                                />
                                              
                                            </div>

                                            {/* <div className="prev-next">
                            	<a href="blog-detail6.html"><img src="assets/img/blog/Left-arrow.png"/> Previous Post</a>
                            	<a href="blog-detail2.html">Next Post <img src="assets/img/blog/Right_arrow.png"/></a>
                            </div> */}


                                        </div>
                                    </div>
                                    {/* </div> */}

                                    <div className="col-xl-3 col-lg-3 col-3 text-left">
                                        {/* <div className="col-xl-12 col-lg-12 col-12 text-left"> */}
                                        <div className="atf-special-content atf-section-title my-auto mb-5 p-4 blog-border" >
                                            <h2>Recent Blogs</h2>

                                            {listBlog.map((item, i) => (
                                                <div className="d-flex" style={{ alignItems: "baseline" }}>
                                                    {/* <i class="fa-solid fa-chevron-right"></i> */}
                                                    <i class="fa fa-chevron-right fa-regular" style={{ color: "#010204", paddingRight: "10px" }}></i>
                                                    {/* <Link to={`/blogDetail/${item._id}`} state={{ props: item, index: i }} className="recentBlogs" style={{textDecoration: "none", color:"black"}}>{item.title} Real Estate</Link> */}

                                                    <p onClick={() => { handleNavigate(item.url) }} style={{ cursor: "pointer" }} className="recentBlogs">{item.title}</p>
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer isLogin={storage} />
        </div>
    )
}

export default BlogDetail
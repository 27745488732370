// import { useDispatch } from "react-redux";
import { dashboardAddressSearchReset, dashboardSinglePropertyDetailsReset } from "../../src/state/dashboardViewAction";
import { singlePropertyClear } from "../../src/state/detailSlice";


export function handleCheckBrokarage(id, userDetails) {
  console.log("companyid", id)
  if (id == 1) {
    if (userDetails.cb_active !== true) {
      return true
    }
    else {
      return false
    }
  }
  if (id == 2) {
    if (userDetails.kw_active !== true) {
      return true
    }
    else {
      return false
    }
  }
  if (id == 3) {
    if (userDetails.co_active !== true) {
      return true
    }
    else {
      return false
    }
  }
  if (id == 4) {
    if (userDetails.in_active !== true) {
      return true
    }
    else {
      return false
    }
  }
  if (id == 5) {
    if (userDetails.ta_active !== true) {
      return true
    }
    else {
      return false
    }
  }
}


export function ResetPropertyDetails(dispatch) {
  // const dispatch = useDispatch();
  dispatch(dashboardAddressSearchReset());
  dispatch(singlePropertyClear());
  dispatch(dashboardSinglePropertyDetailsReset());
}
import React from "react"
import { useSiteMetadata } from "../hooks/use-site-metadata"
// import { useSelector, useDispatch } from 'react-redux';

export const SEOMetaTag = ({ title, description, image, url }) => {
  // const dispatch = useDispatch();
  console.log("meta tags", title, description, image, url);
  // const claimdetailResult = useSelector(state => state.detail.result)
  // console.log("claimdetailResult", claimdetailResult);
  const { title: dtitle, description: ddescription, image: dimage, url: durl } = useSiteMetadata()
  console.log("checking",dtitle);
  const seo = {
    title: title || dtitle,
    description: description || ddescription,
    image: image || dimage,
    url: "https://www.gokeywe.com" || durl,
  }
  console.log(seo);
  return (
    <>
      <title>{seo.title}</title>
      <meta name="title" content={seo.title} />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      <meta name="url" content={seo.url} />
    </>
  )
}
import React, { useEffect, useState } from 'react';
import LoginForm from '../../components/form/LoginForm';
import { Link, navigate } from 'gatsby';
import Seo from "../../components/seo";
import firebase from 'gatsby-plugin-firebase';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../components/Loader';
import { signinDetails, userDetailInfo, LinkedInLogin, signupPost } from '../../components/Api/formApi';
import { useDispatch, useSelector } from 'react-redux';
import { userResponse } from '../../state/userSlice';
import { updateProfile } from '../../components/Api/ListingApi';
import ToastPopup from "../../components/popup/ToastPopup";
import SignUpPopup from '../../components/popup/signUpPopup';

const Login = () => {
    const [loader, setLoader] = useState(false);
    const [codeValue, setCodeValue] = useState(false)
    let dispatch = useDispatch();
    const [success, setSucess] = useState(false);
    const [messages, setMessages] = useState("")
    const toggleSuccess = () => setSucess(p => !p);
    const [show, setShow] = useState(false)
    const toggleShow = () => setShow(p => !p);
    const store_url = useSelector((state) => state.dashboard.store_url)
    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const code = urlParams.get('code')

        if (code && !codeValue) {
            setCodeValue(true)
            socialLinkedInSignin(code)
        }
    })

    function commonFunctionLogin(result, socialType) {
        if (result) {
            var uid = result.user.uid;
            var email = result.user.email;
            signinDetails(uid)
                .then((usersDetails) => {
                    if (usersDetails.status === 200) {
                        userDetailInfo(uid)
                            .then((res) => {
                                if (res.data.message === "Invalid uid") {
                                    commonFunctionRegistration(result, socialType)
                                }
                                else {
                                    if (res.status === 200) {
                                        dispatch(userResponse(res.data.data));
                                        localStorage.setItem('userId', res.data.data._id)
                                        try {
                                            firebase.messaging().firebaseDependencies.installations.getToken().then(async (res) => {
                                                await updateProfile(uid, { push_token: res }).then().catch((error) => { })
                                            })
                                        }
                                        catch (error) {
                                            console.log(error)
                                        }
                                        let claimStatus = usersDetails.data.data.customClaims.type;
                                        var a = [];
                                        a.push(JSON.stringify({ email: email, uid: uid, userType: claimStatus, provider: socialType }));
                                        localStorage.setItem("userInfo", a);
                                        if (claimStatus) {
                                            setMessages(`Login as ${res.data.data.role} Successfully`)
                                            toggleSuccess()
                                            setTimeout(() => toggleSuccess(), 3000)
                                            setTimeout(() => {
                                                setLoader(false);
                                                if (claimStatus === "agent") {
                                                    navigate("/buyers");
                                                } else {
                                                    if (store_url !== "")
                                                        navigate(store_url)
                                                    else
                                                        navigate("/");
                                                }
                                            }, 1000)

                                        }
                                    }
                                }

                            }).catch((err) => {
                                console.log("error", err.message)
                            })
                    }
                })
                .catch((err) => {
                    setLoader(false);
                    setMessages(err.message)
                    toggleSuccess()
                    setTimeout(() => toggleSuccess(), 3000)
                })
        } else {
            setLoader(false);
            setMessages('Email address is not verified')
            toggleSuccess()
            setTimeout(() => toggleSuccess(), 3000)
        }
    }

    function commonFunctionRegistration(result, socialType) {
        if (result) {
            var uid = result.user.uid;
            var displayName = result.user.displayName;
            var email = result.user.email;

            var postData = {
                name: displayName,
                uid: uid,
                email: email,
                provider: socialType,
                role: "user",
            };

            signupPost(postData)
                .then((res) => {
                    if (res.status === 200) {
                        userDetailInfo(uid)
                            .then((res) => {
                                if (res.status === 200) {
                                    dispatch(userResponse(res.data.data));
                                    localStorage.setItem('userId', res.data.data._id)
                                    try {
                                        firebase.messaging().firebaseDependencies.installations.getToken().then(async (res) => {
                                            await updateProfile(uid, { push_token: res }).then().catch((error) => { })
                                        })
                                    }
                                    catch (error) {
                                    }
                                }
                            })
                        var a = [];
                        a.push(JSON.stringify({ email: email, uid: uid, userType: "user", provider: socialType }));
                        localStorage.setItem("userInfo", a);
                        setMessages('Registered Successfully')
                        toggleSuccess()
                        setTimeout(() => toggleSuccess(), 3000)
                        setTimeout(() => {
                            setLoader(false);
                            navigate("/");
                        }, 1000)
                    }
                })
                .catch((err) => {
                    setLoader(false);
                    setMessages(err.message)
                    toggleSuccess()
                    setTimeout(() => toggleSuccess(), 3000)
                })
        }
    }

    const socialGoogleSignin = () => {
        setLoader(true);

        var provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider).then(function (result) {
            if (result) {

                signinDetails(result.user.uid).then((res) => {
                    if (Object.keys(res.data.data).length !== 0) {
                        commonFunctionLogin(result, "google");
                    }
                    else {
                        commonFunctionRegistration(result, "google")
                    }
                })
            }
        })
            .catch((error) => {
                setLoader(false);
                setMessages(error.message)
                // toggleSuccess()
                setTimeout(() => toggleSuccess(), 3000)
                // ...
            });
    }

    const socialFacebookSignin = () => {
        setLoader(true);
        var provider = new firebase.auth.FacebookAuthProvider();
        firebase.auth().signInWithPopup(provider).then((result) => {
            if (result) {
                signinDetails(result.user.uid).then((res) => {
                    if ((res.data.data).length !== 0) {
                        commonFunctionLogin(result, "facebook");
                    }
                    else {
                        commonFunctionRegistration(result, "facebook")
                    }
                })
            }
        })
            .catch((error) => {
                setLoader(false);
                setMessages(error.message)
                // toggleSuccess()
                setTimeout(() => toggleSuccess(), 3000)
            });
    }

    const socialTwitterSignin = () => {
        setLoader(true);
        var provider = new firebase.auth.TwitterAuthProvider();
        firebase.auth().signInWithPopup(provider).then((result) => {
            if (result) {
                signinDetails(result.user.uid).then((res) => {
                    if ((res.data.data).length !== 0) {
                        commonFunctionLogin(result, "twitter");
                    }
                    else {
                        commonFunctionRegistration(result, "twitter")
                    }
                })

            }
        }).catch((error) => {
            setLoader(false);
            setMessages(error.message)
            // toggleSuccess()
            setTimeout(() => toggleSuccess(), 3000)
        });
    }

    const socialAppleSignin = () => {
        setLoader(true);

        var provider = new firebase.auth.OAuthProvider('apple.com');
        firebase
            .auth()
            .signInWithPopup(provider)
            .then((result) => {
                if (result) {
                    commonFunctionLogin(result, "apple");
                }
            })
            .catch((error) => {
                setLoader(false);
                setMessages(error.message)
                // toggleSuccess()
                setTimeout(() => toggleSuccess(), 3000)
            });
    }

    const socialLinkedInSignin = (code) => {
        let url = new URL(window.location.href);
        url.searchParams.delete('code');
        setLoader(true);
        let datas = {
            "redirect_uri": window.location.protocol + "//" + window.location.host + '/signin',
            "code": code
        }
        LinkedInLogin(datas).then(data => {
            commonFunctionLogin({ user: { uid: data.data.data.localId, email: data.data.data.email } }, "linkedin")
        }).catch(error => {
            setLoader(false);
        })
    }
    const openInNewTab = () => {
        window.open("https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=7846itss6897ul&scope=r_emailaddress,r_liteprofile&state=123456&redirect_uri=" + window.location.protocol + "//" + window.location.host + '/signin', '_self').focus();
    }

    return (
        <div className='container position-relative'>
            <ToastPopup success={success} toggleSuccess={toggleSuccess} messages={messages} />
            <SignUpPopup show={show} toggleShow={toggleShow} />
            {loader ? <Loader /> : null}
            <Seo title="Login" />
            <div className='logoupdate position-absolute'>
                <Link to="/" ><img className="register-logo" src="/images/keyweUpdatedlogo.png" alt="logo" /></Link></div>
            <div className='row bgWhite' id='SignOUtContainerDiv' >
                <div className='col-lg-6 col-xl-6 col-md-6 p-0' >
                    <div className='register-leftside'>
                        <div className='inner-text LeftInnerText'>
                            <p className='reg-welcome welcomeRegister NewUserRegister'>New User Registration</p>
                            <p className='personal-info PleaseCreate'>Please create a account with your personal info</p>
                            {/* <Link to="/signup"><button className='signup-btn SignUPButton' >Sign up</button></Link> */}
                            <button className='signup-btn SignUPButton' onClick={toggleShow}>Sign up</button>

                        </div>
                    </div>
                </div>
                <div className='col-lg-6 col-xl-6 col-md-6 p-0'>
                    <div className='register-rightside SignInRight' >
                        <div className='register-iconsection'>
                            <p className='registration WelcomeBack font-weight-bold' style={{ marginBottom: "30px", marginRight: "58px" }}>Welcome Back!</p>
                            <div className="registerIcons ReGisterIcons">
                                {/* <div className='SignUpSocialIcons SiginSocialIcons' style={{ display: "flex", gap: "1.3rem" }}>
                                    <div style={{cursor:"pointer"}}><img alt='social' onClick={socialFacebookSignin} onKeyPress={socialFacebookSignin} src="/images/Group 353.png" aria-hidden/></div>
                                    <div style={{cursor:"pointer"}}><img alt='social' onClick={socialTwitterSignin} onKeyPress={socialTwitterSignin} src="/images/Group 354.png" aria-hidden/></div>
                                    <div style={{cursor:"pointer"}}><img alt='social' onClick={openInNewTab} onKeyPress={openInNewTab} src="/images/Group 355.png" aria-hidden/></div>
                                    <div style={{cursor:"pointer"}}><img alt='social' onClick={socialGoogleSignin} onKeyPress={socialGoogleSignin} src="/images/Group 356.png" aria-hidden/></div>
                                    <div style={{cursor:"pointer"}}><img alt='social' onClick={socialAppleSignin} onKeyPress={socialAppleSignin} src="/images/Group 357.png" aria-hidden/></div>


                                </div> */}

                            </div>
                            <div className='hr-section HrSectiondiv SignInForm' style={{ width: "90%", margin: "32px 0 31px 0" }} >
                                {/* <span className='hr-text horizontalText'>
                                    OR
                                </span> */}
                            </div>
                        </div>
                        <LoginForm />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;
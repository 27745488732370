import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    search: {
        name: '',
        displayName: '',
        cityName: '',
    },
    addressSearch: [],
    singlePropertyAddressSearch: [],
    // addressSearch: {
        // street_name: "",
        // street_number: "",
        // city: "",
        // zipcode: "",
        // state: "",
        // street_suffix: "",
        // country: "",
        // displayName: '',
    // },
    price: {
        min: null,
        max: null,
        displayMin: null,
        displayMax: null
    },
    room: {
        bed: '',
        bath: ''
    },
    homeType: '',
    details: {
        squareFeetMin: '',
        squareFeetMax: '',
        pricesquareFeetMin: '',
        pricesquareFeetMax: '',
        lotSizeMin: '',
        lotSizeMax: '',
        yearBuildMin: '',
        yearBuildMax: '',
        costFinance: '',
        poolType: '',
        amenitiesString: '',
        houseViewString: '',
        storiesMin: '',
        storiesMax: '',
        walkScore: '',
        bikeScore: '',
    },
    page: {
        list: true,
        image: false,
        map: false
    },
    googleSearch: {
        lat: null,
        lng: null,
        zoom: null
    },
    listing_status: null,
    netWork_fileName: "",
    street_name: "", city: "", country: "", state: "", zipcode: "",
    store_url: "",
    messageCount: null,
    chat_disconnect: null,
    netWork_Details: "",
    note_count: null,
    store_single_property_details: {},
};

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        dashboardSearch: (state, action) => {
            // console.log('redux dashboardSearch', action)
            state.search = action.payload
        },
        dashboardAddressSearch: (state, action) => {
            // console.log('redux dashboardAddressSearch', action);
            state.addressSearch = action.payload;
            state.singlePropertyAddressSearch = action.payload;
        },
        dashboardPrice: (state, action) => {
            state.price = action.payload
        },
        dashboardRoom: (state, action) => {
            state.room = action.payload
        },
        dashboardHomeType: (state, action) => {
            state.homeType = action.payload
        },
        dashboardDetails: (state, action) => {
            state.details = action.payload
        },
        dashboardPage: (state, action) => {
            state.page = action.payload
        },
        dashboardGoogleSearch: (state, action) => {
            state.googleSearch = action.payload
        },
        dashboardListingStatus: (state, action) => {
            state.listing_status = action.payload
        },
        NetWorkFileName: (state, action) => {
            state.netWork_fileName = action.payload
        },
        dashboardUrl: (state, action) => {
            state.store_url = action.payload
        },
        getMessageCount: (state, action) => {
            state.messageCount = action.payload
        },
        chattingUserData: (state, action) => {
            state.chat_disconnect = action.payload
        },
        NetWorkDetails: (state, action) => {
            state.netWork_Details = action.payload
        },
        notificationCount: (state, action) => {
            state.note_count = action.payload
        },
        dashboardSinglePropertyDetails: (state, action) => { //Attom
            state.store_single_property_details = action.payload
        },
        dashboardSinglePropertyDetailsReset: (state, action) => {
            // console.log('redux dashboardSinglePropertyDetailsReset');

            state.store_single_property_details = {}
        },
        dashboardAddressSearchReset: (state, action) => {
            // console.log('redux dashboardAddressSearchReset', action);
            state.addressSearch = []
        },
        singlePropertyAddressSearchReset: (state, action) => {
            // console.log('redux dashboardAddressSearchReset', action);
            state.singlePropertyAddressSearch = []
        },
        dashboardReset: state => {
            return {
                ...state,
                search: { name: '', displayName: '', cityName: '' }
                , listing_status: ""
                , price: { min: null, max: null, displayMin: null, displayMax: null }
                , room: { bed: "", bath: "" }
                , homeType: ""
                , details: { squareFeetMin: '', squareFeetMax: '', pricesquareFeetMin: '', pricesquareFeetMax: '', lotSizeMin: '', lotSizeMax: '', yearBuildMin: '', yearBuildMax: '', costFinance: '', poolType: '', amenitiesString: '', houseViewString: '', storiesMin: '', storiesMax: '', walkScore: '', bikeScore: '', }
                , page: { list: true, image: false, map: false }
                , googleSearch: { lat: null, lng: null, zoom: null }
                , netWork_fileName: ""
                , street_name: "", city: "", country: "", state: "", zipcode: ""
                , addressSearch: []
                // addressSearch: {
                    // street_name: "",
                    // street_number: "",
                    // city: "",
                    // zipcode: "",
                    // state: "",
                    // street_suffix: "",
                    // country: "",
                    // displayName: '',
                // },
            }
        }
    },
})

// Action creators are generated for each case reducer function
export const { 
    dashboardSearch, dashboardPrice, dashboardRoom, dashboardHomeType, dashboardDetails, 
    dashboardPage, dashboardGoogleSearch, dashboardListingStatus, NetWorkFileName, NetWorkDetails, 
    dashboardReset, dashboardUrl, getMessageCount, chattingUserData, notificationCount, dashboardAddressSearch, dashboardSinglePropertyDetails,
    dashboardSinglePropertyDetailsReset, dashboardAddressSearchReset, singlePropertyAddressSearchReset
} = dashboardSlice.actions;
// export const user = "user";

export const dashboardReducer = dashboardSlice.reducer;
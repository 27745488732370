import React, { useEffect, useState } from "react"
import Layout from "../../components/Layout"
import * as styles from "../../pages/seller/sellerProp.module.css"
import "react-multi-carousel/lib/styles.css"
import "bootstrap/dist/css/bootstrap.css"
import { listedProperty, imageLikeList } from "../../components/Api/ListingApi"
import Seo from "../../components/seo"
// import Loader from '../../components/Loader'
import ListPropDetails from "./ListPropDetails"
import Cards from "../../components/card/card"
import SkeletonVerticalCard from "../../components/card/skeletonVerticalCard"

function ListedProperty() {

  // useState function //
  const [open, setOpen] = useState(false);
  const [isdataFounded, setisDataFounded] = useState(false);
  const [loader, setLoader] = useState(false);
  const [listing, setListing] = useState([]);
  const [details, SetDetails] = useState({});
  const [anotherPros, setAnotherPros] = useState(false)

  //useEffect function //
  useEffect(() => {
    setLoader(true);
    async function fetchData() {
      await listingApi();
    } fetchData()
  }, [])

  //variable DEclaration //
  const array = ["1", "2", "3", "4", "5", "6"]

  // list property Detail Close function //
  const closeDetail = () => {
    setOpen(false)
  }

  //card choose function //
  const toggleClick = (data, i) => {
    // setIndex(i);
    // setIsActive(true);
    setOpen(true)
    SetDetails(data)
  }

  // Image Like Api
  const handleImageLikeList = async (userId) => {
    var value = []
    await imageLikeList(userId).then((res) => {
      value = res.data.data
    }).catch((error) => {
      console.log(error)
    })
    return value
  }

  // Listed property list function //
  const listingApi = async () => {
    let userId = localStorage.getItem('userId')
    if (userId !== null) {
      // setUserId(userId)
      var response = await handleImageLikeList(userId)
    }
    setLoader(true);
    await listedProperty(userId).then((res) => {
      setLoader(false);
      let data = [...res.data.data]
      data.length !== 0 ? setisDataFounded(false) : setisDataFounded(true)
      if (userId !== null) {
        if (response.length !== 0) {
          response.forEach((item) => {
            data.forEach((item2) => {
              if (item === item2.property_id._id) {
                item2.isLike = item
              }
            })
          })
        }
      }
      setListing(res.data.data)
      // console.log("listed property",res.data.data)

    }).catch((error) => {
      console.log(error)
    })
    setLoader(false)
  }

  // Listed proprty details open function //
  const OpnAnotherProps = () => {
    setAnotherPros(!anotherPros)
  }

  // const hanldeLike = async (id) => {
  //   let userId = localStorage.getItem('userId')
  //   setIslike(false)
  //   await imageLike(userId, id).then((res) => {
  //   }).catch((error) =>{
  //     console.log(error)
  //   } )
  //   listingApi();
  // }
  // const hanldeImage = img => {
  //   let data =img !== null && img.split("/")[0]
  //   if (data === "uploads") {
  //     return process.env.API_IMAGE_URL + img
  //   } else {
  //     return img
  //   }
  // }
  
  return (
    <>
      <div>
        <div id="mySidenav" className="sidenav">
        </div>
        <Layout>
          <Seo title="Listed Properties" />
          <div className="row ms-3 mb-4 m-0">
            <div className={`${open ? 'col-md-8' : 'col-md-12'}`}>
              <div className="row" style={{ height: "902px", overflow: "auto", }} >
                {loader ?
                  array.map((item) => (
                    <div className={`${open ? 'col-md-6' : 'col-md-4'}`} tabIndex="0">
                      <div className={styles.carouselResList}>
                        <div className={styles.CarouselCard1}>
                          <SkeletonVerticalCard purpose={"card"} />
                        </div></div></div>
                  ))
                  :
                  listing.map((data, i) =>
                    data.property_id !== null &&
                    <div className={`${open ? 'col-md-6' : 'col-md-4'}`} key={i}  role="button" tabIndex="0">
                      <div className={styles.carouselResList}>
                        <div className={styles.CarouselCard1}>
                          <div  onClick={() => { toggleClick(data, i) }} onKeyDown={() => { toggleClick(data, i) }}>
                            <Cards data={data} I={i}  />
                            {/* <Card className={styles.carouselCard} style={{
                            borderRadius: "25px", border: (isActive && index == i) ? "2px solid rgb(4, 144, 251)" : "1px solid white", boxShadow: "0 1px 4px rgb(82 80 80 / 40%), inset 0 0 40px rgb(135 128 128 / 8%)",
                          }} onClick={showDetail}>
                            <div className={styles.favorite}>
                              {data.property_id.thumbnail_image !== null && data.property_id.thumbnail_image.includes('mls') ? <img src={data.property_id.thumbnail_image !== undefined && hanldeImage(data.property_id.thumbnail_image)} alt="EditGroup1 " className={styles.carouselImage} /> :
                                <img className={styles.carouselImage} src={data.property_id.thumbnail_image !== undefined && hanldeImage(data.property_id.thumbnail_image)} alt="EditGroup1 " crossOrigin='anonymous' />}

                                </div>
                            <div className="row m-0">
                              <div className="col-md-8 cardtext1">
                                <p className='addrList' id={styles.listedaddress1} style={{ fontFamily: "DejaVuSansCondensed", fontSize: "14px" }}>{data.property_id.address.split(',').slice(0).shift()},</p>
                                <p className='addrList' id={styles.listedaddress2} style={{ fontFamily: "DejaVuSansCondensed", fontSize: "14px" }}>{data.property_id.address.split(",").slice(1).join(",")}</p>
                              </div>
                              <div className="col-md-4 cardtext1">
                                <div className={styles.dashboardActive} id={styles.ActiveIconsStatus}>
                                {data.property_id.listing_status === 3 ?
                                 <img src={mlsdotimage} alt='mlsdotimage' className='mlsImage' />:
                                  <i className="fa fa-circle " style={{ color: data.property_id.listing_status === 0 ? "orange" : data.property_id.listing_status === 1 || data.property_id.listing_status === 2 ? "#03B45B" : data.property_id.listing_status === 4 ? "yellow" : "red" }}></i>}
                                  <p className={styles.listingStaus}>{data.property_id.listing_status === 0 ? "Draft" : data.property_id.listing_status === 1 ? "Active" : data.property_id.listing_status === 2 ? "Active Contingent" : data.property_id.listing_status === 3 ? "Exclusive" : data.property_id.listing_status === 4 ? "Pending" : data.property_id.listing_status === 5 ? "Sold" : data.property_id.listing_status === 6 ? "Expired" : data.property_id.listing_status === 7 ? "Cancelled" : "Withdrawn"}</p>
                                </div>
                              </div>
                            </div>
                            <div className="row m-0">
                              <div className="col-md-6">
                                <p className={styles.homePrice} id={styles.pricedetail}>${String(data.property_id.price.$numberDecimal).replace(/(.)(?=(\d{3})+$)/g, "$1,")}</p>
                              </div>
                              <div className="col-md-6 cardtext2">
                                <div className={styles.beds}>
                                  <div>
                                    <p style={{ fontFamily: "DejaVuSansBold" }} className={styles.bedsstyle}>{data.property_id.beds}</p>
                                    <p style={{ color: "#898686" }} className={styles.bedsstyle2}>Beds</p>
                                  </div>
                                  <div>
                                    <p style={{ fontFamily: "DejaVuSansBold" }} className={styles.bathsstyle}>{data.property_id.baths}</p>
                                    <p style={{ color: "#898686" }} className={styles.bathsstyle2}>Baths</p>
                                  </div>
                                  <div>
                                    <p style={{ fontFamily: "DejaVuSansBold" }} className={styles.sqftstyle}>{String(data.property_id.square_feet.$numberDecimal).replace(
                                      /(.)(?=(\d{3})+$)/g, "$1,"
                                    )}</p>
                                    <p style={{ color: "#898686" }} className={styles.sqftstyle2}>Sq.</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card> */}
                          </div>
                        </div>
                      </div>

                    </div>
                  )}
                {isdataFounded && <div className='no-result2' style={{ marginTop: "25%" }}>You have no listed properties</div>}

              </div>
            </div>
            {open && (
              <div className="col-lg-4 col-xl-4 col-md-4 p-0">
                <ListPropDetails data={details} anotherPros={OpnAnotherProps} close={closeDetail} />
              </div>
            )}
          </div>
        </Layout>
      </div>
    </>
  )
}

export default ListedProperty

import React, { useState, useEffect } from 'react'
import Navbar from '../../components/Navbar';
// import Group236 from "../../../static/images/Group 236.png";
// import Dummy from "../../../static/images/house4.png"
// import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import attachment from "../../../static/images/attachment.png";
import Footer from '../../components/Footer';
import * as style from "../../pages/agents/agent.module.css";
import { RiArrowLeftLine } from "react-icons/ri";
import { Button } from "react-bootstrap";
import CurrencyInput from 'react-currency-input-field';
import { addProperty, uploadPropertyFile, updateImages, deleteFile, userList, addressCheck, GetSplitAddresss } from "../../components/Api/ListingApi"
import { navigate } from "@reach/router";
import Autocomplete from "react-google-autocomplete";
import axios from 'axios';
import ToastPopup from '../../components/popup/ToastPopup';
import estimateTool from "../../../static/images/estimateTool.png";
import { Tooltip } from '@material-ui/core';

export default function AddProperty() {
    const [selectedOption, setSelectedOption] = useState('brokerage_exclusive');

    const handleRadioChange = (e) => {
        setSelectedOption(e.target.value);
    };
    console.log("selectedOption", selectedOption);
    var isBrokerageExclusive = selectedOption === 'brokerage_exclusive';
    var isKeyWeExclusive = selectedOption === 'keyWe_exclusive';
    const [storage, setStorage] = useState(false);
    const [loader, setLoader] = useState(false);
    const [isUpdated, setUpdated] = useState(false)
    const [thumbimage, setThumbImage] = useState("")
    // const [update, setUpdate] = useState("")
    // const [newUploads, setNewUpdloads] = useState(false)
    const [newImages, setNewImage] = useState([])
    const [messages, setMessages] = useState("")
    const [key, setKey] = useState(0)
    const [splitAddress, setSplitAddress] = useState([])
    const [success, setSucess] = useState(false);
    const toggleSuccess = () => setSucess(p => !p);
    const initialValues = {
        price: "",
        home_type: "",
        listing_status: "",
        beds: null,
        baths: null,
        square_feet: null,
        basement_sq_ft: null,
        garage_sq_ft: null,
        hoa_doc_fee_amount: null,
        lot_size: null,
        year_built: null,
        description: "",
        matter_port: "",
        signed_disclosure: "",
        view_signed_disclosure: false,
        image_url: [""],
        thumbnail_image_url: "",
        address: "",
        owner_email: "",
    }
    const [addPropertyData, setAddPropertyData] = useState(initialValues)
    // console.log("addPropertyData", addPropertyData)
    const [signedDisclosure, setSignedDisclosure] = useState(addPropertyData.signed_disclosure)
    const [checked, setChecked] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [place, setPlace] = useState('')
    const [isPlace, setIsPlace] = useState(false)
    const [searchName, setSearchName] = useState({
        owner_email: "",
        owner_first_name: "",
        owner_last_name: ""
    })
    const [userName, setuserName] = useState([])
    const [open, setOpen] = useState(false)


    // console.log('formErrors', formErrors)
    const handleChanged = () => {
        setChecked(true);
    };
    useEffect(() => {
        // console.log("handleSave", Object.keys(formErrors).length === 0 && isSubmit);
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            handleSave();
        }
    }, [formErrors]);
    useEffect(() => {
        setLoader(true);
        setTimeout(() => { setLoader(false) }, 1000)
    }, []);
    useEffect(() => {
        const storedData = localStorage.getItem("userInfo");
        if (!storedData) {
            setStorage(false);
        } else {
            setStorage(true);
        }
        // list()
    }, []);
    useEffect(() => {
        setAddPropertyData({ ...addPropertyData, ["address"]: place })

    }, [place])
    const handleChange = e => {
        // console.log("place", e)
        e.preventDefault();
        const { name, value } = e.target;
        // console.log("name", name)
        // console.log("value", value)

        setAddPropertyData({ ...addPropertyData, [name]: value });
    };
    const handleChanges = e => {
        let name = e.target.name
        let value = e.target.value
        // console.log(value);
        setSearchName({ ...searchName, [name]: value })
    }
    const handlecurrency2 = (value, name) => {
        setAddPropertyData({ ...addPropertyData, [name]: value })
    }

    const handlecurrency3 = (value, name) => {
        setAddPropertyData({ ...addPropertyData, [name]: value })
    }

    const onSubmit = (e) => {
        // console.log('onsubmit')
        e.preventDefault();
        setFormErrors(validate(addPropertyData));
        setIsSubmit(true);
    };

    const handleSave = async () => {
        let userId = localStorage.getItem('userId')
        let getParams = {
            price: addPropertyData.price,
            home_type: addPropertyData.home_type,
            listing_status: addPropertyData.listing_status,
            beds: addPropertyData.beds,
            baths: addPropertyData.baths,
            square_feet: addPropertyData.square_feet,
            basement_sq_ft: addPropertyData.basement_sq_ft,
            garage_sq_ft: addPropertyData.garage_sq_ft,
            hoa_doc_fee_amount: addPropertyData.hoa_doc_fee_amount,
            lot_size: addPropertyData.lot_size,
            year_built: addPropertyData.year_built,
            description: addPropertyData.description,
            matter_port: addPropertyData.matter_port,
            signed_disclosure: signedDisclosure,
            view_signed_disclosure: checked,
            image_url: newImages,
            thumbnail_image_url: thumbimage,
            address: addPropertyData.address.replace(', USA', ""),
            owner_email: searchName.owner_email,
            owner_first_name: searchName.owner_first_name,
            owner_last_name: searchName.owner_last_name,
            keyWe_exclusive: isKeyWeExclusive,
            brokerage_exclusive: isBrokerageExclusive
        }

        // console.log("splitAddress !== undefined && splitAddress.length", splitAddress !== undefined, splitAddress.length !== 0)
        // console.log("splitAddress !== undefined && splitAddress.length", splitAddress)
        console.log("getParams", getParams)

        if (splitAddress !== undefined && splitAddress.length) {
            (splitAddress || []).forEach((item) => {
                if (item.types.includes('route')) {
                    getParams.street_name = item.short_name;
                }
                if (item.types.includes('street_number')) {
                    getParams.street_number = item.long_name;
                }
                if (item.types.includes('administrative_area_level_1')) {
                    getParams.state = item.short_name;
                }
                if (item.types.includes('postal_code')) {
                    getParams.zipcode = item.long_name;
                }
                if (item.types.includes('locality')) {
                    getParams.city = item.long_name;
                }
            })
        }

        // setFormErrors(validate(initialValues));
        // console.log("getparams add", getParams)
        await addProperty(userId, getParams).then((res) => {
            if (res.data.status == 1) {
                setMessages(res.data.message)
                toggleSuccess()
                setTimeout(() => setSucess(false), 5000)
                handleRoute()
            }
            else {
                setMessages(res.data.message)
                toggleSuccess()
                setTimeout(() => setSucess(false), 5000)
            }
            // console.log("add property res", res)
        }).catch((error) => {
            setLoader(false);
            console.log(error);
        })
    }

    const validate = (values) => {
        const errors = {};
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!values.address) {
            errors.address = 'Address is Required';
        }
        // if (!values.owner_email) {
        //     errors.owner_email = 'Email is Required';
        // } else if (!regex.test(values.owner_email)) {
        //     errors.owner_email = 'Please enter valid email address';
        // }
        if (!values.lot_size) {
            errors.lot_size = 'Lot size is Required';
        }
        if (!values.square_feet) {
            errors.square_feet = 'Square Feet is Required';
        }
        // if (!values.basement_sq_ft) {
        //     errors.basement_sq_ft = 'Basement Square feet is Required';
        // }
        // if (!values.garage_sq_ft) {
        //     errors.garage_sq_ft = 'Garage square feet is Required';
        // }
        // if (!values.hoa_doc_fee_amount) {
        //     errors.hoa_doc_fee_amount = 'HOA doc fee amount is Required';
        // }
        if (!values.beds) {
            errors.beds = 'Beds is Required';
        }
        if (!values.baths) {
            errors.baths = 'Baths is Required';
        }
        if (!values.year_built) {
            errors.year_built = 'Year build is Required';
        }
        if (!values.price) {
            errors.price = 'Price is Required';
        }
        // if (!values.matter_port) {
        //     errors.matter_port = 'Matter port is Required';
        // }
        if (!values.home_type) {
            errors.home_type = 'Hometype is Required';
        }
        if (!values.listing_status) {
            errors.listing_status = 'Listing status is required';
        }
        // if (!signedDisclosure) {
        //     errors.signed_disclosure = 'Signed disclosure is Required';
        // }
        // if (!values.description) {
        //     errors.description = 'Description is Required';
        // }
        return errors;
    };


    const hanldFileThumbnail = async (event) => {
        const formData = new FormData()
        if (event.target.files.length) {
            for (let i = 0; i < event.target.files.length; i++) {
                formData.append("name", event.target.files[i].name)
                formData.append("file", event.target.files[i])
            }
            setLoader(true)
            await uploadPropertyFile(formData).then((res) => {
                setUpdated(true)
                setThumbImage(res.data.data[0])
                setLoader(false)
            }).catch((error) => {
                setLoader(false);
                console.log(error);
            })
        }
    }
    // console.log("newImages", newImages)

    const hanldFileUploadImg = async (event) => {
        const formData = new FormData()
        if (event.target.files.length) {
            for (let i = 0; i < event.target.files.length; i++) {
                formData.append("name", event.target.files[i].name)
                formData.append("file", event.target.files[i])
            }
            await uploadPropertyFile(formData).then((res) => {
                // console.log("response", res.data.data)
                // let getParams = {
                //     image_url: res.data.data,
                // }
                setNewImage(res.data.data)
                setKey(0)
                    // updateImages(getParams).then((res) => {
                    //     setMessages(res.data.message)
                    // })
                    .catch((error) => {
                        setLoader(false);
                    })
            }).catch((error) => {
            })
        }
    }
    const handleRoute = () => {
        navigate(`/myList`)
    }

    const hanldFileUpload3 = async (event) => {
        if (event.target.files.length) {
            const formData = new FormData()
            formData.append("name", event.target.files[0].name)
            formData.append("file", event.target.files[0])
            setLoader(true)
            await uploadPropertyFile(formData).then((res) => {
                setSignedDisclosure(res.data.data[0])
                setLoader(false);
            }).catch((error) => {
                alert(error);
            })
        }
    }

    const deleteDisclosure = async (item) => {
        let userId = localStorage.getItem("userId")
        let name = item.split('/')[2]
        let getParams = {
            user_id: userId,
            folder: "others",
            name: name
        }
        deleteFile(getParams).then((res) => {
            setSignedDisclosure("")
            setMessages('Signed Disclosure deleted Successfully')
        }).catch((error) => {
            console.log(error);
        })
    }
    const handleUserName = async (e) => {
        // console.log(e.target.value);
        let getPrarams = {
            search: e.target.value
        }
        // console.log("getPrarams", getPrarams)
        await userList(getPrarams).then((res) => {
            // console.log("response user", res)
            // console.log("respose age", res.data.data.map((item) => item))
            setuserName(res.data.data)
        }).catch((err) => {
            console.log(err)
            // setuserName([])
        })
    }
    const handleSubmit = async (data) => {
        // console.log("data checking", data.replaceAll("#", ""))
        await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${data.replaceAll("#", "")}&key=AIzaSyCVpTy7dON49XuSmtftSLfjgw2QxTSia2M`).then((res) => {
            // console.log("address", res.data.results[0].address_components)
            setSplitAddress(res.data.results[0].address_components)
            let getParams = {
                address: data.replaceAll(', USA', "")
            }
            // console.log("getParams", getParams)
            let getCityName = res.data.results[0].address_components.filter((item) => item.types.includes('locality') || item.types.includes('administrative_area_level_1'))
            let cityName = getCityName[0].long_name + ', ' + getCityName[1].short_name;
            if (res.data.results[0].address_components !== undefined && res.data.results[0].address_components.length) {
                (res.data.results[0].address_components || []).forEach((item) => {
                    // console.log("item", item)
                    if (item.types.includes('route')) {
                        getParams.street_name = item.short_name;
                    }
                    if (item.types.includes('street_number')) {
                        getParams.street_number = item.long_name;
                    }
                    if (item.types.includes('administrative_area_level_1')) {
                        getParams.state = item.short_name;
                    }
                    if (item.types.includes('postal_code')) {
                        getParams.zipcode = item.long_name;
                    }
                    if (item.types.includes('locality')) {
                        getParams.city = item.long_name;
                    }
                })

            }
            addressCheck(getParams).then((res) => {
                if (res.data.status == 1) {
                    let item = res?.data?.data ? res.data.data[0] : ""
                    // console.log("item item", item)
                    if (item.source !== "atom") {
                        setAddPropertyData({
                            "price": item?.price?.$numberDecimal ? item.price.$numberDecimal : "",
                            "home_type": item?.sub_class ? item.sub_class : "",
                            "beds": item?.beds ? item.beds : "",
                            "baths": item?.baths ? item.baths : "",
                            "square_feet": item?.square_feet?.$numberDecimal ? item.square_feet.$numberDecimal : "",
                            "basement_sq_ft": item?.basement_sq_ft?.$numberDecimal ? item.basement_sq_ft.$numberDecimal : "",
                            "garage_sq_ft": item?.garage_sq_ft?.$numberDecimal ? item.garage_sq_ft.$numberDecimal : "",
                            "lot_size": item?.lot_size?.$numberDecimal ? item.lot_size.$numberDecimal : "",
                            "year_built": item?.year_built ? item.year_built : "",
                            "description": item?.description ? item.description : "",
                            "matter_port": item?.matter_port ? item.matter_port : "",
                            "address": item?.address ? item.address : addPropertyData.address,
                        })
                    } else {
                        setAddPropertyData({
                            "price": item.price,
                            "beds": item.beds,
                            "baths": item.baths,
                            "square_feet": item.square_feet,
                            "lot_size": item.lot_size,
                            "year_built": item.year_built,
                            "address": item?.address ? item.address : addPropertyData.address,
                        })
                    }
                }

                else {
                    if (res.data.status == 0) {
                        setMessages(res.data.message)
                        toggleSuccess()
                        setTimeout(() => setSucess(false), 5000)
                    }
                }
            })

        }).catch((error) => {
            console.log(error)
        });
    }



    return (
        <div>
            {/* {loader ? <Loader /> : null} */}
            <Navbar isLogin={storage} />
            <ToastPopup success={success} toggleSuccess={toggleSuccess} messages={messages} />
            <div className="pb-5">
                <div className="d-flex" style={{ paddingTop: "15px", cursor: "pointer" }} onClick={handleRoute}>
                    <RiArrowLeftLine style={{ fill: '', padding: "0 0 0 15px", width: "57px", cursor: "pointer" }} size="25px" />
                    <h4 className="PhotoMedia1" >Home View </h4>
                </div>
                <div>
                    <h4 className="homesFacts mt-5">Seller Information</h4>
                    <div className="row mt-5">
                        <div className="col-md-3 position-relative ">
                            <h5 className="editBeds1 mb-3">Seller Email</h5>
                            <input type="text" className="form-control editForm" placeholder="Seller Email" id="exampleInputEmail1" aria-describedby="emailHelp" name="owner_email" onChange={(e) => { setSearchName(e.target.value); handleUserName(e); handleChanges(e) }} value={searchName.owner_email} style={{ width: "91%", marginLeft: "40px", position: "relative" }} onFocus={() => setOpen(true)} ></input>
                            {open && userName.length !== 0 &&
                                <div className='agentNameSearch1'>
                                    {userName.length !== 0 ? userName.map((item, i) => (
                                        <p onClick={() => { setSearchName(item); setOpen(false) }} style={{ padding: '8px 15px', cursor: "pointer", marginBottom: "0px", color: searchName === item.email ? "white" : "", background: searchName === item.owner_email ? "#4B91FB" : "" }} key={i} value={item.owner_email}>{item.owner_email}</p>
                                    )) : <p style={{ textAlign: "center", paddingTop: "13px", color: "#414141" }}>No Results found</p>}
                                </div>
                            }
                        </div>
                        <div className="col-md-3">
                            <h5 className=" MatterPort mb-3">First Name</h5>
                            <input type="text" className="form-control editForm" placeholder="First Name" id="exampleInputEmail1" aria-describedby="emailHelp" name="owner_first_name" onChange={handleChanges} value={searchName.owner_first_name} style={{ width: "90%", }} onFocus={() => setOpen(false)} ></input>
                        </div>
                        <div className="col-md-3">
                            <h5 className="MatterPort mb-3">Last Name</h5>
                            <input type="text" className="form-control editForm" placeholder="Last Name" id="exampleInputEmail1" aria-describedby="emailHelp" name="owner_last_name" onChange={handleChanges} value={searchName.owner_last_name} style={{ width: "91%" }} onFocus={() => setOpen(false)} ></input>
                        </div>
                    </div>
                </div>

                <div>
                    <h4 className="homesFacts mt-5">Home Facts</h4>
                    <div className="row m-0">
                        <div className="col-md-6">
                            <div className="mt-3">
                                <h5 className="editBeds">Address <span style={{ color: "red" }}>*</span></h5>
                                <Autocomplete
                                    className="form-control editForm"
                                    apiKey={process.env.FIREBASE_API_KEY}
                                    value={addPropertyData.address}
                                    name="address"
                                    onChange={handleChange}
                                    onPlaceSelected={(place) => {
                                        setAddPropertyData({ ...addPropertyData, ["address"]: place.formatted_address })
                                            ; handleSubmit(place.formatted_address)
                                    }}
                                    options={{
                                        types: ["geocode", "establishment"],
                                        componentRestrictions: { country: "us" },
                                    }}
                                    onFocus={() => setOpen(false)}
                                    style={{ width: "90%", marginLeft: "30px" }}
                                />
                                {/* <input type="text" className="form-control editForm" value={addPropertyData.address} onChange={handleChange} placeholder="Address" name="address" id="exampleInputEmail1" aria-describedby="emailHelp" maxLength="60" style={{ width: "90%", marginLeft: "30px" }}></input> */}
                                <p className='error-msg-add' style={{ marginLeft: "28px" }}>{formErrors.address}</p>
                            </div>


                            <div className="row mt-5">
                                <div className="col-md-6">
                                    <h5 className="editBeds">Beds <span style={{ color: "red" }}>*</span></h5>
                                    <input type="text" className="form-control editForm" value={addPropertyData.beds} onChange={handleChange} placeholder="Beds" name="beds" id="exampleInputEmail1" aria-describedby="emailHelp" maxLength="4" style={{ width: "90%", marginLeft: "30px" }} onFocus={() => setOpen(false)}></input>
                                    <p className='error-msg-add' style={{ marginLeft: "28px" }}>{formErrors.beds}</p>
                                </div>
                                <div className="col-md-6">
                                    <h5>Baths <span style={{ color: "red" }}>*</span></h5>
                                    <input type="text" className="form-control editForm" value={addPropertyData.baths} onChange={handleChange} placeholder="Baths" name="baths" id="exampleInputEmail1" aria-describedby="emailHelp" maxLength="4" style={{ width: "86%" }} onFocus={() => setOpen(false)}></input>
                                    <p className='error-msg-add'>{formErrors.baths}</p>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-6">
                                    <h5 className="editBeds">Lot Size <span style={{ color: "red" }}>*</span></h5>
                                    {/* <input type="number" className="form-control editForm" value={addPropertyData.lot_size} onChange={handleChange} placeholder="Lot size" id="exampleInputEmail1" name="lot_size" aria-describedby="emailHelp" style={{ width: "90%", marginLeft: "30px" }}></input> */}
                                    <CurrencyInput
                                        name="lot_size"
                                        placeholder="Lot size"
                                        className="form-control editForm"
                                        value={addPropertyData.lot_size}
                                        onValueChange={(value, name) => { handlecurrency2(value, name) }}
                                        decimalsLimit={2}
                                        maxLength={10}
                                        onFocus={() => setOpen(false)}
                                        style={{ width: "90%", marginLeft: "30px" }} />
                                    <p className='error-msg-add' style={{ marginLeft: "28px" }}>{formErrors.lot_size}</p>
                                </div>
                                <div className="col-md-6">
                                    <h5> Square Feet <span style={{ color: "red" }}>*</span></h5>
                                    <CurrencyInput
                                        name="square_feet"
                                        placeholder='Square feet'
                                        className="form-control editForm"
                                        value={addPropertyData.square_feet}
                                        onValueChange={(value, name) => { handlecurrency2(value, name) }}
                                        decimalsLimit={2}
                                        maxLength={10}
                                        onFocus={() => setOpen(false)}
                                        style={{ width: "86%" }} />
                                    <p className='error-msg-add'>{formErrors.square_feet}</p>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-6">
                                    <h5 className="editBeds">Year Built <span style={{ color: "red" }}>*</span></h5>
                                    <input type="text" className="form-control editForm" value={addPropertyData.year_built} onChange={handleChange} placeholder="Year build" id="exampleInputEmail1" name="year_built" aria-describedby="emailHelp" style={{ width: "90%", marginLeft: "30px" }} onFocus={() => setOpen(false)}></input>
                                    <p className='error-msg-add' style={{ marginLeft: "28px" }}>{formErrors.year_built}</p>
                                </div>
                                <div className="col-md-6">
                                    <h5 >Price <span style={{ color: "red" }}>*</span></h5>
                                    <CurrencyInput
                                        prefix="$"
                                        name="price"
                                        placeholder='Price'
                                        value={addPropertyData.price}
                                        onValueChange={(value, name) => { handlecurrency2(value, name) }}
                                        className="form-control editForm"
                                        decimalsLimit={2}
                                        maxLength={10}
                                        style={{ width: "86%" }}
                                        onFocus={() => setOpen(false)} />
                                    <p className='error-msg-add'>{formErrors.price}</p>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6">
                            <h5 className="hoatype">HOA Dues(per month) </h5>
                            <CurrencyInput
                                prefix="$"
                                name="hoa_doc_fee_amount"
                                placeholder="Hoa Dues"
                                className="form-control editForm"
                                value={addPropertyData.hoa_doc_fee_amount}
                                decimalsLimit={2}
                                maxLength={10}
                                onValueChange={(value, name) => { handlecurrency3(value, name) }}
                                style={{ width: "95%" }}
                                onFocus={() => setOpen(false)} />
                            {/* <p className='error-msg-add'>{formErrors.hoa_doc_fee_amount}</p> */}
                            <div className="row mt-5">
                                <div className="col-md-6">
                                    <h5 className="basementText">Basement Sq.Ft.</h5>
                                    <CurrencyInput
                                        name="basement_sq_ft"
                                        value={addPropertyData.basement_sq_ft}
                                        onValueChange={(value, name) => { handlecurrency3(value, name) }}
                                        placeholder="Basement Sq feet"
                                        className="form-control editForm"
                                        decimalsLimit={2}
                                        maxLength={10}
                                        style={{ width: "90%" }}
                                        onFocus={() => setOpen(false)} />
                                    {/* <p className='error-msg-add'>{formErrors.basement_sq_ft}</p> */}
                                </div>
                                <div className="col-md-6">
                                    <h5>Garage sq.ft.</h5>
                                    <CurrencyInput
                                        name="garage_sq_ft"
                                        placeholder="Garage sq feet"
                                        className="form-control editForm"
                                        value={addPropertyData.garage_sq_ft}
                                        onValueChange={(value, name) => { handlecurrency3(value, name) }}
                                        decimalsLimit={2}
                                        maxLength={10}
                                        style={{ width: "86%" }}
                                        onFocus={() => setOpen(false)} />
                                    {/* <p className='error-msg-add'>{formErrors.garage_sq_ft}</p> */}
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-6">
                                    <h5 className="MatterPort">Signed Disclosure</h5>
                                    <div className={style.attachment} style={{ alignItems: "center" }}>
                                        <input type="file" className="form-control editForm" id="exampleInputEmail1" onChange={hanldFileUpload3} aria-describedby="emailHelp" name="signed_disclosure" accept="application/pdf,image/gif,image/jpeg, image/jpg, .doc, .pdf, image/*" style={{ width: "2%", opacity: 0, position: "absolute" }} onFocus={() => setOpen(false)}></input>
                                        <img src={attachment} className={style.fileuploadImgNew} alt="uploadFile"></img>
                                        {signedDisclosure !== "" && signedDisclosure !== null &&
                                            <div className={style.fileInput} ><div className={style.divCont}>
                                                <p className={style.fileuploadNew}>{signedDisclosure}</p></div>
                                                <div className={style.fileView} >
                                                    <a href={`${process.env.API_IMAGE_URL + signedDisclosure}`} target="_blank" rel="noreferrer" className={style.linkTag} role="button" tabIndex="0">view</a>
                                                    <a className={style.linkTag} rel="noreferrer" onClick={() => deleteDisclosure(signedDisclosure)} role="button" tabIndex="0" onKeyPress={() => deleteDisclosure(signedDisclosure)}>delete</a>
                                                </div>
                                            </div>
                                        }
                                        {/* <p className='error-msg-add1'>{formErrors.signed_disclosure}</p> */}
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <h5 className="MatterPort mb-3">Matterport</h5>
                                    <input type="text" className="form-control editForm" placeholder='Matterport' value={addPropertyData.matter_port} onChange={handleChange} id="exampleInputEmail1" aria-describedby="emailHelp" name="matter_port" style={{ width: "86%" }} onFocus={() => setOpen(false)} ></input>
                                    {/* <p className='error-msg-add'>{formErrors.matter_port}</p> */}
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md-6">
                                    <h5 className="hometype1">Home Type <span style={{ color: "red" }}>*</span></h5>
                                    <select className="form-select" style={{ width: "90%" }} onChange={handleChange} value={addPropertyData.home_type} id="sel1" name="home_type"  >
                                        <option value="">---Home Type---</option>
                                        <option value="101">Single Family</option>
                                        <option value="102">CondoMinimum</option>
                                        <option value="702">Multi Family</option>
                                        <option value="103">Townhome</option>
                                        <option value="403">Lots / Land</option>
                                        <option value="105">Manufactured</option>
                                        <option value="109">Others</option>
                                    </select>
                                    <p className='error-msg-add'>{formErrors.home_type}</p>
                                </div>
                                <div className="col-md-6">
                                    <h5 className="hometype1">Listing Status<span style={{ color: "red" }}>*</span></h5>
                                    <select className="form-select" style={{ width: "90%" }} onChange={handleChange} value={addPropertyData.listing_status} id="sel1" name="listing_status"  >
                                        <option value="">---Listing Status---</option>
                                        <option value="0">Draft</option>
                                        <option value="3">Exclusive</option>
                                    </select>
                                    <p className='error-msg-add'>{formErrors.listing_status}</p>
                                </div>
                            </div>


                        </div>
                        <div className="row mt-5">
                            <div className="col-md-3">
                                <h5 className="DescHomeType" style={{ marginLeft: "30px" }}>Viewing Permission</h5>
                                <div style={{ marginLeft: "30px" }}>
                                    <input type="radio" onChange={(e) => handleRadioChange(e)} name="brokerage_exclusive" id="brokerage_exclusive" value={"brokerage_exclusive"} checked={isBrokerageExclusive} />
                                    <label for="Brokerage_exclusive" style={{ marginLeft: "5px" }}>Brokerage exclusive</label>
                                    <Tooltip title="Only the agents at your brokerage and their clients can see this listing." followCursor>
                                        <img src={estimateTool} alt="tooltip " crossOrigin='anonymous' className="estimateTool" style={{ marginLeft: "5px" }} />
                                    </Tooltip>
                                </div>
                                <div style={{ marginLeft: "30px", marginTop: "10px" }}>
                                    <input type="radio" onChange={(e) => handleRadioChange(e)} name="keyWe_exclusive" id="keyWe_exclusive" value={"keyWe_exclusive"} checked={isKeyWeExclusive} />
                                    <label for="KeyWe_exclusive" style={{ marginLeft: "5px" }}>KeyWe exclusive</label>
                                    <Tooltip title="Any agent or user on KeyWe can see this listing." sx={{ bgcolor: '#4B91FB', }}>
                                        <img src={estimateTool} alt="tooltip " crossOrigin='anonymous' className="estimateTool" style={{ marginLeft: "5px" }} />
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="col-md-9">
                                <h5 className="DescHomeType" >Describe your home type</h5>
                                <textarea className="form-control z-depth-1" style={{ width: "195%", height: "105px" }} value={addPropertyData.description} onChange={handleChange} id="exampleFormControlTextarea6" name="description" rows="3" placeholder="Write something here..." onFocus={() => setOpen(false)}></textarea>
                                {/* <p className='error-msg-add' style={{ marginLeft: "30px" }}>{formErrors.description}</p> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="EditGroupImages1" style={{ height: "780px" }}>
                    <h4 className="PhotoMedia mt-3">Thumbnail photo</h4>
                    {thumbimage && <img className="imageUpload2" src={process.env.API_IMAGE_URL + thumbimage} alt="No thumbanil Image" height={700} crossOrigin="anonymous" />}
                    <div>
                        <button className="uploadButtton1 mt-5">Upload Thumbnail</button>
                        <input type="file" name="media" className="inputUpload1 mt-5" onChange={(event) => hanldFileThumbnail(event)} accept="image/jpeg, image/jpg,  image/png"></input></div>
                </div>
                <div className="photoText">
                    <h4 className="PhotoMedia">Additional photos</h4>
                    <input type="file" name="Photos_media" className="inputUpload" onChange={(event) => hanldFileUploadImg(event)} multiple accept="image/jpeg, image/jpg,  image/png"></input>
                    <button className="uploadButtton">Upload Photo</button>
                </div>
                <div className="row m-0 overflow-auto maindiv">
                    {newImages.map((data, i) => (
                        <div className="EditGroupImages1 mt-3" key={i}>
                            <img src={process.env.API_IMAGE_URL + data} alt="EditGroup1 " crossOrigin='anonymous' className="EditGroup" />
                            {/* <img src={Group236} className="uploadImgFile1" alt="uploadImgFile"></img>
                        <input type="file" name="image_name" className="uploadImgFile" onChange={(event) => hanldFileUpload2(event, i)} accept="image/jpeg, image/jpg,  image/png" onClick={() => setImgId(item._id)} ></input>
                        <img src={deleteIcon} className="uploadImgFile2" alt="uploadImgFile" onClick={() => { hanldDeleteFile(item._id, item.MediaURL); }} onKeyPress={() => { hanldDeleteFile(item._id, item.MediaURL); }} aria-hidden></img> */}
                        </div>
                    ))}
                </div>
                <div className="col-md-4 newhomwedit">
                    <h5 >View Signed Disclosure</h5>
                    <input type="checkbox" name="view_signed_disclosure" onChange={handleChanged} value={addPropertyData.view_signed_disclosure} style={{ marginRight: "23px" }} />
                </div>
                <div className="div-home">
                    <Button className="editHomes1" onClick={onSubmit}>Save</Button>
                    <Button className="editHomes2" onClick={handleRoute} >Cancel</Button>
                </div>
            </div>
            <Footer isLogin={storage} />
        </div>
    )
}

import React, { useState, useEffect } from "react";
import { Card, Tab, Tabs } from "react-bootstrap";
import * as style2 from "./buyerdetail.module.css"
import callimage from '../../../static/images/PhoneImage.png';
import msgimage from '../../../static/images/ChatImage.png';
import AvatarImage from "../../components/Avatar/avatarImage";
import { recentlyViewedProperty, recentlyTouredProperty } from '../../components/Api/ListingApi'
import { useSelector } from "react-redux";
import ChatPopup from "../../components/popup/ChatPopup";
import { StreamChat } from "stream-chat";
import "stream-chat-react/dist/css/index.css";
import { Link, navigate } from "gatsby";
import * as styled from "../buyers/buyer.module.css"
import Dummy from "../../../static/images/house4.png"
import ProperyDetails from "../../components/popup/propertDetails";
import { createBrowserHistory } from 'history';

function TourbuyerDetail(props) {
    const tourData = props.data
    const [recentlyViewed, setRecentlyViewed] = useState([])
    const [recentlyToured, setRecentlyToured] = useState([])
    const [chatAgent, setChatAgent] = useState({})
    const [chatUser, setChatUser] = useState({})
    const [chatId, setChatId] = useState("")
    const userDetails = useSelector(state => state.user.userDetail);
    const [openChat, setOpenChat] = useState(false)
    const [openProperty, setOpenProperty] = useState(false)
    const [propertyId, setPropertyId] = useState("")
    const [propertDetails, setPropertyDetails] = useState({})
    const history = createBrowserHistory();
    const showChatDetail = () => {
        setOpenChat(p => !p)
    }

    useEffect(() => {
        async function fetchData() {
            await getRecentlyViewed();
            await getTourProperty();
        } fetchData()
    }, [props.data]);

    const getTourProperty = async () => {
        let agent_id = tourData.agent_id._id
        await recentlyTouredProperty(agent_id).then((res) => {
            setRecentlyToured(res.data.data)

        }).catch((error) => {
            console.log(error)
        })
    }

    const getRecentlyViewed = async () => {
        let agent_id = tourData.agent_id._id
        await recentlyViewedProperty(agent_id).then((res) => {
            setRecentlyViewed(res.data.data)
        }).catch((error) => {
            console.log(error)
        })
    }
    const hanldeImage = (img) => {
        let data = img !== null && img.split('/')[0]
        if (data === "uploads") {
            return process.env.API_IMAGE_URL + img
        }
        else {
            return img
        }
    }
    const handleChat = async (id, name, lastName, image) => {
        let names = name + " " + (lastName !== null ? lastName : " ")
        const apikey = process.env.STREAM_CHAT_APIKEY;

        const chatClient = StreamChat.getInstance(apikey);
        await chatClient.connectUser({ id: id, name: names, image: image }, chatClient.devToken(id));
        await chatClient.disconnectUser()

        setChatAgent({ id: id, name: names, image: image })
        setChatUser({ id: userDetails._id, name: userDetails.name, image: hanldeImage(userDetails.img) })
        setChatId(id + userDetails._id)
        showChatDetail()
    }
    const hanldeOpenDetails = (id, data) => {
        setOpenProperty(p => !p)
        setPropertyId(id)
        setPropertyDetails(data)
        // history.push(`/homedetails/${data.address}`)
        navigate(`/homedetails/${(data.address).replaceAll(", ", '-').replaceAll(" ", '-').replaceAll("#", '%23')}`)
    }
    const hanldeOpenDetailsClose = (id, data) => {
        setOpenProperty(p => !p)
        setPropertyId(id)
        setPropertyDetails(data)
        history.push(`/agenttour`)
    }
    return (
        <>
            {openProperty && <ProperyDetails openProperty={openProperty} hanldeOpenDetails={hanldeOpenDetailsClose} id={propertyId} propertDetails={propertDetails} />}
            <div className={style2.detailCardBack} >
                <Card className={style2.tourdetailcard}>
                    <div >
                        <div className={style2.saradetails}>
                            {tourData.agent_id.img ?
                                <img alt="profileimage" src={process.env.API_IMAGE_URL + tourData.agent_id.img} className={style2.sarahImage} crossOrigin="anonymous" />
                                : <>{tourData.agent_id.name ? <div style={{ width: '88px', height: '88px' }}><AvatarImage fontSize={'38px'} data={tourData.agent_id.name ? tourData.agent_id.name : ''} /></div>
                                    : null}</>
                            }
                            <div className={style2.nameDetail}>
                                <p className={style2.detaName}>{tourData.agent_id.name}</p>
                                <p style={{ textTransform: "capitalize" }}>{tourData.agent_id.role}</p>
                                <img src={msgimage} alt="callimage" onClick={() => { handleChat(tourData.agent_id._id, tourData.agent_id.name, tourData.agent_id.last_name, tourData.agent_id.img ? hanldeImage(tourData.agent_id.img) : ""); }} onKeyPres={() => { handleChat(tourData.agent_id._id, tourData.agent_id.name, tourData.agent_id.last_name, tourData.agent_id.img ? hanldeImage(tourData.agent_id.img) : ""); }} aria-hidden />
                                {tourData.agent_id.name.phone_number !== null && tourData.agent_id.name.phone_number !== "" && tourData.agent_id.name.phone_number !== undefined &&

                                    <span style={{ cursor: "pointer" }} className={styled.tooltip}>
                                        <img src={callimage} alt="phonintrest" className={styled.phonintrest} />

                                        {tourData.agent_id.name.phone_number !== null && tourData.agent_id.name.phone_number !== "" && tourData.agent_id.name.phone_number !== undefined ?
                                            <span className={styled.tooltiptext}>{tourData.agent_id.name.phone_number}</span>

                                            : null}
                                    </span>
                                }
                            </div>
                        </div>
                        <div className={style2.propdetail}>
                            <h6 className={style2.propdetail1}>Area</h6>
                            <div>
                                <h6 className={style2.propdetail2}>{tourData.property_id.address.split(',').slice(0).shift()}</h6>
                                <h6 className={style2.propdetail2}>{tourData.property_id.address.split(",").slice(1).join(",")}</h6>
                            </div>

                        </div>
                        <hr className={style2.hrtag} />
                        <div className={style2.propdetail}>
                            <h6 className={style2.propdetail1}>Budget</h6>
                            <h6 className={style2.propdetail2}>{tourData.property_id.price.$numberDecimal}</h6>
                        </div>
                        <hr className={style2.hrtag} />
                        <div className={style2.propdetail}>
                            <h6 className={style2.propdetail1}>Prequalified</h6>
                            <h6 className={style2.propdetail2}>{ }</h6>
                        </div>
                        <hr className={style2.hrtag} />
                        <div className={style2.propdetail}>
                            <h6 className={style2.propdetail1}>Type</h6>
                            <h6 className={style2.propdetail2}>{tourData.property_id.beds} Bed,  {tourData.property_id.baths} Bath</h6>
                        </div>
                        <hr className={style2.hrtag} />
                        <div>
                            <Tabs defaultActiveKey="Recently Toured" id="uncontrolled-tab-example" className="mb-3 " style={{ background: 'transparent', paddingLeft: "14px" }}>

                                <Tab eventKey="Recently Toured" title="Recently Toured" style={{ marginBottom: "25px", height: "358px", overflow: "auto" }} >
                                    {recentlyToured.length !== 0 ? recentlyToured.map((data, i) => (
                                        <div className={style2.recenttourdetail1} key={i}>
                                            <div onClick={() => { hanldeOpenDetails(data.property_id._id, data.property_id) }} style={{ cursor: "pointer" }}>
                                                {(data.property_id !== null && data.property_id.thumbnail_image !== undefined && data.property_id.thumbnail_image !== null) ? (data.property_id.thumbnail_image.split('/')[0] === "https:" ? <img src={data.property_id !== null && data.property_id.thumbnail_image !== undefined && hanldeImage(data.property_id.thumbnail_image)} alt="agent" className={style2.recenttourImage} /> :
                                                    <img alt="agent" className={style2.recenttourImage} src={data.property_id !== null && data.property_id.thumbnail_image !== undefined && hanldeImage(data.property_id.thumbnail_image)} crossOrigin='anonymous' />
                                                ) : <img alt="agent" className={style2.recenttourImage} src={Dummy} crossOrigin='anonymous' />}
                                            </div>

                                            {data.property_id !== null && data.property_id !== undefined &&
                                                <div className={style2.touraddress}>
                                                    <p>${data.property_id.price !== null && data.property_id.price !== undefined && data.property_id.price.$numberDecimal}</p>
                                                    <p>{data.property_id.address !== null && data.property_id.address !== undefined && data.property_id.address.split(',').slice(0).shift()},</p>
                                                    <p>{data.property_id.address !== null && data.property_id.address !== undefined && data.property_id.address.split(",").slice(1).join(",")}</p>
                                                    <p>{data.property_id.beds !== null && data.property_id.beds !== undefined && data.property_id.beds} bed, {data.property_id.beds !== null && data.property_id.baths} bath</p>
                                                </div>}
                                        </div>
                                    )) : <p className='no-result2' style={{ marginTop: "30%" }}>No Result Found</p>}
                                </Tab>
                                <Tab eventKey="Recently Viewed" title="Recently Viewed" style={{ marginBottom: "25px", height: "358px", overflow: "auto" }} >
                                    {recentlyViewed.length !== 0 ? recentlyViewed.map((data, i) => (
                                        <div className={style2.recenttourdetail1} key={i} >
                                            {/* {console.log("data",data.property_id !== null && data.property_id!==undefined &&data.property_id.thumbnail_image !== undefined && data.property_id.thumbnail_image !== null && data.property_id.thumbnail_image)} */}
                                            <div onClick={() => { hanldeOpenDetails(data.property_id._id, data.property_id) }} style={{ cursor: "pointer" }}>
                                                {(data.property_id !== null && data.property_id.thumbnail_image !== undefined && data.property_id.thumbnail_image !== null) ? (data.property_id.thumbnail_image.split('/')[0] === "https:" ? <img src={data.property_id !== null && data.property_id.thumbnail_image !== undefined && hanldeImage(data.property_id.thumbnail_image)} alt="agent" className={style2.recenttourImage} /> :
                                                    <img alt="agent" className={style2.recenttourImage} src={data.property_id !== null && data.property_id.thumbnail_image !== undefined && hanldeImage(data.property_id.thumbnail_image)} crossOrigin='anonymous' />
                                                ) : <img alt="agent" className={style2.recenttourImage} src={Dummy} crossOrigin='anonymous' />}
                                            </div>
                                            {data.property_id !== null && data.property_id !== undefined &&
                                                <div className={style2.touraddress}>
                                                    <p>${data.property_id.price !== null && data.property_id.price !== undefined && data.property_id.price.$numberDecimal}</p>
                                                    <p>{data.property_id.address !== null && data.property_id.address !== undefined && data.property_id.address.split(',').slice(0).shift()},</p>
                                                    <p>{data.property_id.address !== null && data.property_id.address !== undefined && data.property_id.address.split(",").slice(1).join(",")}</p>
                                                    <p>{data.property_id.beds !== null && data.property_id.beds !== undefined && data.property_id.beds} bed, {data.property_id.beds !== null && data.property_id.baths} bath</p>
                                                </div>}
                                        </div>

                                    )) : <p className='no-result2' style={{ marginTop: "30%" }}>No Result Found</p>}
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </Card>
                {openChat && <ChatPopup show={openChat} toggleShow={showChatDetail} agent={chatAgent} user={chatUser} id={chatId} />}
            </div>
        </>
    )
}
export default TourbuyerDetail
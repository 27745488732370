import React, { useEffect, useState } from 'react';
import { Row, Col } from "react-bootstrap"
import * as style2 from "./buyerdetail.module.css"
import ListingDetail from './listingDetail';
import {  RiArrowLeftLine} from "react-icons/ri";
import {tourListingApi} from "../../components/Api/ListingApi"
import AvatarImage from "../../components/Avatar/avatarImage";

export default function TourListing() {
    const [detailsVisible, setDetailsVisible] = useState(false);
    const [buyersVisible, setBuyersVisible] = useState(true);
    
    const [data, setData] = useState([]);
    // const [listing, setListing] = useState("")
    const [listingItem, setListingItem] = useState({})
    // const [listingDetail, setListingDetail] = useState();
    const [isActivee, setIsActivee] = useState(false);
    const [indexx, setIndexx] = useState(0);
  
    useEffect(() => {
        async function fetchData() {
            await listingApi();
        } fetchData()
      
    }, [])
  
    const listingApi = async (search) => {
      let userId =localStorage.getItem('userId')
      let getParams={
        pagesize:20,
        page:1,
        sort_field:"_id",
        sort_value:-1,
        search:search
      }
    await tourListingApi(userId,getParams)
      .then((res) => {
        // setListing(res.data.data)
        setData(res.data.data)
  
      }).catch((error) => {
        console.log(error)
      }) 
    }
    const listClick = (item,index,e) => {
        setDetailsVisible(true)
        setBuyersVisible(false)
        setListingItem(item)
        setIndexx(index)
        setIsActivee(true);
    }
    const back = () => {
        setDetailsVisible(false)
        setBuyersVisible(true)
        // setListingDetail(data)
    }

    const hanldeImage = (img) => {
        let item =img !== null &&  img.split('/')[0]
        if (item === "uploads") {
          return process.env.API_IMAGE_URL + img
        }
        else {
          return img
        }
      }
    

   
return (
        <div>
            {
                buyersVisible &&
                <div className='tourbuyer mb-5' >
                    <div >
                        {data.map((item, index) =>
                            <Row key={index}
                                style={{
                                    border: (isActivee && indexx === index) ? "1px solid rgb(4, 144, 251)" : "1px solid white" ,
                                    borderRadius: "20px",
                                    boxShadow: "1px 2px 4px 2px #e4e5e7",
                                    margin: "30px 0 0 0",
                                    padding: "20px 10px",
                                    background: "#fff",
                                }} onClick={(e)=>{listClick(item,index,e);}}>
                                    
                                <Col lg="5">
                                    
                                    <Row>
                                        <Col lg="4 zoom"> 
                                        {item.property_id.thumbnail_image !== undefined && item.property_id.thumbnail_image.includes('mls') ? <img src={item.property_id.thumbnail_image !== undefined && hanldeImage(item.property_id.thumbnail_image)} alt="EditGroup1 "  className={style2.homeImage} id={style2.LisAvatImage} style={{ borderRadius: "10px" ,height: "161px",width: "184px"}} /> :
                    <img  src={item.property_id.thumbnail_image !== undefined && hanldeImage(item.property_id.thumbnail_image)} alt="EditGroup1 " crossOrigin='anonymous'  className={style2.homeImage} id={style2.LisAvatImage} style={{ borderRadius: "10px" ,height: "161px",width: "184px"}} />}

                                        </Col>
                                        <Col lg="8" className='sellertour-margin ' >

                                            <div className={style2.sellertouraddress}>
                                                <div>
                                                <h6 className={style2.addresslist}>Addresss</h6>

                                                </div>
                                            <div className='sellertour-text' id="address">{item.property_id.address}</div>
                                            <div className={style2.pricedetail}>
                                                <h6>Price</h6>
                                                <h5 className='sellerlistprice'>${String(item.property_id.price.$numberDecimal).replace(
                              /(.)(?=(\d{3})+$)/g,
                              "$1,"
                          )}</h5>
                                            </div>
                                            </div>
                                            
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="1" className='sellertour-margin ' id={style2.sellertourbeds}>
                                    <h5 className={style2.roomCount}>{item.property_id.beds}</h5>
                                    <h6 style={{ marginLeft: "-7px" }}>Beds</h6>
                                </Col>
                                <Col lg="1" className='sellertour-margin ' id={style2.sellertourbeds}>
                                    <h5 className={style2.roomCount}>{item.property_id.baths}</h5>
                                    <h6 style={{ marginLeft: "-7px" }}>Baths</h6>
                                </Col>
                                <Col lg="1" className='sellertour-margin ' id={style2.sellertourbeds}>
                                    <h5 className={style2.roomCount}>{item.property_id.square_feet.$numberDecimal}</h5>
                                    <h6>Sq.Feet</h6>
                                </Col>

                                <Col lg="2" style={{width:"140px"}}>

                                {item.user_id.img ?
                                  < img alt="no_image" src={process.env.API_IMAGE_URL + item.user_id.img} className={style2.sellertourimage} style={{height: "88px", width:"88px", borderRadius:"50%"}} crossOrigin="anonymous" />
                                  : <>{item.user_id.name ? <div style={{ width: '88px', height: '88px',borderRadius:"50%", position: "relative" }}><AvatarImage fontSize={'40px'} data={item.user_id.name ? item.user_id.name : ''} /></div>
                                    : null}</>
                                }
                                </Col>
                                <Col lg="2">
                                    <div className={style2.sellerdetails}>
                                       
                                        <h6>{item.user_id.role.charAt(0).toUpperCase() + item.user_id.role.slice(1)}</h6>

                                     
                                        <div className='sellertour-text'>{item.user_id.name.charAt(0).toUpperCase() + item.user_id.name.slice(1)}</div>
                                    </div>
                                </Col>
                            </Row>
                        )}
                    </div>
                </div >
            }
            {
                detailsVisible &&
                <>
                 <div className='networkbackicon' onClick={back} onKeyPress={back} role="button" tabIndex="0">                   
                        <RiArrowLeftLine style={{ fill: '' }} size="25px" />
                        <p className='backtext m-0 ps-1'>Back</p>
                </div>            
                    <ListingDetail data={listingItem} />
                </>
            }
        </div >
    )
}

import React, { useState } from "react"
import * as styles from "../../pages/seller/sellerProp.module.css"
import "react-multi-carousel/lib/styles.css"
import "bootstrap/dist/css/bootstrap.css"
import closebutton from "../../../static/images/deleteIcon.png"
import Group236 from "../../../static/images/sharing.png"
import memberIcon from "../../../static/images/memberIcon.png"
import * as style1 from '../../pages/dashboard/detail.module.css';
import SharePopup from "../popup/sharePopup"
// import PropMap from "./PropMap"
import axios from 'axios'
import Dummy from "../../../static/images/house4.png"
import PropMap from "./PropMap3"
import newBrokaragelogo from "../../../static/images/newBrokaragelogo.png"

function HouseDetails(props) {
  const { lat, lng } = props
  // console.log("lat lng", props)
  const close = () => {
    props.close()
  }
  const [googleImg, setGoogleImg] = useState("")

  const hanldeImage = img => {
    let data = img !== null && img.split("/")[0]
    if (data === "uploads") {
      return process.env.API_IMAGE_URL + img
    } else {
      return img
    }
  }
  const [shows, setShows] = useState(false)
  const toggleShows = () => setShows(p => !p)
  // console.log(props.image.address)

  const ImageCard = (address) => {
    console.log("address");

    if (address !== undefined) {
      var address1 = address.replace("#", "")
      axios
        .get(`https://maps.googleapis.com/maps/api/streetview?size=449x290&location=${address1}&key=AIzaSyCVpTy7dON49XuSmtftSLfjgw2QxTSia2M`, {
          headers: {
            "Content-Type": 'application/json;charset=UTF-8'
          }
        })
        .then((response) => {
          setGoogleImg(`https://maps.googleapis.com/maps/api/streetview?size=449x290&location=${address1}&key=AIzaSyCVpTy7dON49XuSmtftSLfjgw2QxTSia2M`)
          //  console.log("response",response.status);

        })
        .catch((error) => {
          setGoogleImg(Dummy)
          // console.log("response",error)
        });
    }

    return (
      googleImg
    )
  }
  return (

    <div className={styles.ImageView1}>
      <SharePopup shows={shows} toggleShows={toggleShows} id={props.image !== undefined && props.image._id} />
      <>

      {props.image.thumbnail_image !== undefined && props.image.thumbnail_image !== null && props.image.thumbnail_image !== "" ?
                                        <>
                                            {props.image !== null && props.image.thumbnail_image !== undefined && props.image.thumbnail_image.includes('mls') ?
                                                <img src={hanldeImage(props.image !== null && props.image.thumbnail_image)} alt="mask192" className={styles.AvatarImageOwned} style={{ width: "100%", padding: "10px 0", height: "448px", position: "relative", top: "6px" }}  /> :
                                                <img src={hanldeImage(props.image !== null && props.image.thumbnail_image)} alt="mask192" className={styles.AvatarImageOwned} style={{ width: "100%", padding: "10px 0", height: "448px", position: "relative", top: "6px" }} crossOrigin="anonymous" />}

                                        </> :
                                        <div>
                                            {props.image.location ?
                                                <img src={`https://maps.googleapis.com/maps/api/streetview?size=449x290&location=${props.image.address.replaceAll("#", "")}&key=AIzaSyCVpTy7dON49XuSmtftSLfjgw2QxTSia2M`} alt="EditGroup10 " className={styles.AvatarImageOwned} style={{ width: "100%", padding: "10px 0", height: "448px", position: "relative", top: "6px" }}/>
                                                :
                                                <>{props.image.property_latitude !== null && props.image.property_longitude !== null &&
                                                    <PropMap lat={parseFloat(props.image.property_latitude)} lng={parseFloat(props.image.property_longitude)} />

                                                }</>}

                                            {/* <PropMap lat={parseFloat(sellerDetail.property_id.property_latitude)} lng={parseFloat(sellerDetail.property_id.property_longitude)} /> */}
                                        </div>}
        {/* {props.image !== undefined && props.image.thumbnail_image !== null && props.image.thumbnail_image !== undefined ?
          <div>

            {props.image !== undefined && props.image.thumbnail_image !== null && props.image.thumbnail_image !== undefined && props.image.thumbnail_image.includes('mls') ? <img src={props.image.thumbnail_image !== undefined && hanldeImage(props.image.thumbnail_image)} alt="EditGroup1 " className={styles.AvatarImageOwned} style={{ width: "100%", padding: "10px 0", height: "448px", position: "relative", top: "6px" }} /> :
              <img className={styles.AvatarImageOwned} src={props.image !== undefined && props.image.thumbnail_image !== undefined && hanldeImage(props.image.thumbnail_image)} alt="EditGroup1 " crossOrigin='anonymous' style={{ width: "100%", padding: "10px 0", height: "448px", position: "relative", top: "6px" }} />}

          </div> :

          <div >
            {/* {props.image.location!==undefined && props.image.location.coordinates[0] && */}
            {/* // <PropMap lng={props.image.location.coordinates[0]} lat={props.image.location.coordinates[1]} /> */}
            {/* <img className={styles.AvatarImageOwned} src={ImageCard(props.image.address)} alt="EditGroup00 " style={{ width: "100%", padding: "10px 0", height: "448px", position: "relative", top: "6px" }} /> */}
            {/* } */}
            {/* <>{props.image.property_latitude !== null && props.image.property_longitude !== null && 
              <PropMap lat={parseFloat(props.image.property_latitude)} lng={parseFloat(props.image.property_longitude)} />
              
              }</>} */}
          {/* </div>} */}
          </>

      {props.image !== undefined && (props.image.listing_status === 3 || props.image.listing_status === "3") &&
        ((props.image.brokerage_exclusive !== false && props.image.companyID === "1") ? <div className={style1.memberdetails}>
          <div className={style1.memberbutt} > <img src={memberIcon} alt="memberIcon" className={style1.memberIcon} /> Exclusive</div>
        </div> :
          <div>
            <div> <img src={newBrokaragelogo} alt="memberIcon" className={style1.memberIcon} /> Exclusive</div>
          </div>)
      }
      {/* {(props.image.thumbnail_image == null || props.image.thumbnail_image == undefined) && (props.image.location.coordinates==[]||props.image.location==undefined )&&  props.image.property_longitude==null && props.image.property_latitude==null && */}
      {/* {console.log((props.image.thumbnail_image == null || props.image.thumbnail_image == undefined) , (typeof  props.image.location.coordinates=="object" ), props.image.property_longitude==null , props.image.property_latitude==null  )} */}
      {(props.image.thumbnail_image == null || props.image.thumbnail_image == undefined) && props.image.location.coordinates[0] == undefined &&
        <img className={styles.AvatarImageOwned} src={ImageCard(props.image.address)} alt="EditGroup11 " aria-hidden crossOrigin='anonymous' style={{ width: "100%", padding: "10px 0", height: "448px", position: "relative", top: "6px" }} />}

      {/* <img className={styles.AvatarImageOwned} src={Dummy} alt="EditGroup1 " aria-hidden crossOrigin='anonymous' style={{ width: "100%", padding: "10px 0", height: "448px", position: "relative", top: "6px" }} />} */}
      <img
        src={closebutton}
        alt="closebutton"
        style={{ position: "absolute", right: '61px', top: "62px", cursor: "pointer" }}
        className={styles.CloseButton}
        onClick={close}
        aria-hidden
      />
      <div className="shareButton" >
        <img src={Group236} alt="group236" className="shareIcon" onClick={toggleShows} aria-hidden />
      </div>
    </div>
  )
}
export default HouseDetails
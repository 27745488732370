import React, { useEffect, useState } from "react"
import { navigate } from '@reach/router';
// import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import * as styles from "../../pages/seller/sellerProp.module.css"
import "react-multi-carousel/lib/styles.css"
import "bootstrap/dist/css/bootstrap.css"
import { useSelector } from "react-redux"
import { Card, Button } from "react-bootstrap"
import ConnectAgentPopup from "../../components/popup/connectAgentPopup"
import HouseDetails from "../../components/Seller/HouseDetails"
import { imageLikeList, propertyActivity, viewershipOffer, reportDisclosure, propertyimage, attomAvmData } from '../../components/Api/ListingApi'
import * as styled from "../buyers/buyer.module.css"
import Bar from "../bars/bar"
import * as style1 from '../../pages/dashboard/detail.module.css';
import { Link } from "gatsby";
import ToastPopup from "../../components/popup/ToastPopup"
// import Dummy from "../../../static/images/house4.png"
// import PropMap from "../../components/Seller/PropMap3";
import ProperyDetails from "../../components/popup/propertDetails";
import SimilarCarousel from "../../components/Carousel/similarCarousel";


function OwnedPropDetails(props) {
  //props declaration //
  const details = props.detailList
  const ownedDetails = props.listing
  const similarProperty = props.similarProperty

  console.log("details", details)
  console.log("ownedDetails", ownedDetails)

  //redux declaration //
  const userDetails = useSelector(state => state.user.userDetail)

  //variable declaration //
  const userId = localStorage.getItem("userId");
  const [images, setImages] = useState("");
  const [indexs, setIndex] = useState(null);

  var image = images;
  var indexx = indexs;

  //useState declaration //
  const [connect, setConnect] = useState(false);
  const [success, setSucess] = useState(false);
  const [messages, setMessages] = useState("");
  const [viewerShip, setViewrShip] = useState({});
  const [view, setView] = useState(true);

  const [activityDetails, setActivityDetails] = useState({});
  const [openProperty, setOpenProperty] = useState(false);
  const [propertyId, setPropertyId] = useState("");
  const [propertDetails, setPropertyDetails] = useState({});
  const [isReadMore, setIsReadMore] = useState(true);

  //useEffect function //
  useEffect(() => {
    async function fetchData() {
      likeProperty()
      setImages(details.thumbnail_image)
      setIndex(null)
    } fetchData()

  }, [props.detailList]);

  useEffect(() => {
    async function fetchData() {
      viewOfferCount()
    } fetchData()
  }, [details]);

  useEffect(() => {
    async function fetchData() {
      getPropertyActivity("this_week");

    } fetchData()
  }, [details._id]);


  //connect agent popup //
  const toggleConnect = () => setConnect(p => !p);
  // Toast Popup //
  const toggleSuccess = () => setSucess(p => !p);

  //Like property Api inttegartion
  const likeProperty = async (id) => {
    let userId = localStorage.getItem('userId')
    await imageLikeList(userId, id).then((res) => {
    }).catch((error) => {
      console.log(error)
    })
  }
  // Property activity Api integration //
  const getPropertyActivity = async e => {
    let id = ownedDetails.property_id === undefined ? ownedDetails.property_id._id : ownedDetails.property_id._id
    let params = {
      date_filter: e.target !== undefined ? e.target.value : "this_week"
    }
    await propertyActivity(id, params)
      .then((res) => {
        setActivityDetails(res.data.data)
      }).catch((error) => {
        console.log(error)
      })
  }

  // const responsive = {
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1280 },
  //     items: 2,
  //     slidesToSlide: 2 // optional, default to 1.
  //   },
  //   tablet: {
  //     breakpoint: { max: 1280, min: 464 },
  //     items: 1,
  //     slidesToSlide: 1 // optional, default to 1.
  //   },
  //   mobile: {
  //     breakpoint: { max: 464, min: 0 },
  //     items: 1,
  //     slidesToSlide: 1 // optional, default to 1.
  //   }
  // };
  // const handleRoute = (id) => {
  //   navigate(`/dashboard/detail/${id}`)
  // }

  // Property Details popup //
  const hanldeOpenDetails = (id, data) => {
    setOpenProperty(p => !p)
    setPropertyId(id)
    setPropertyDetails(data)
  }
  const handleClose = () => {
    props.close()
  }

  // Offer count Api integration //
  const viewOfferCount = async () => {
    let id = ownedDetails.property_id === undefined ? ownedDetails.property_id !== undefined && ownedDetails.property_id._id : ownedDetails.property_id._id
    await viewershipOffer(id).then((res) => {
      setViewrShip(res.data)
    })
  }

  // Edit property rout function //
  const handleRoutes = () => {
    navigate(`/editProperty`)
  }

  // handle image null function //
  const hanldeImage = img => {
    let data = img !== null && img.split("/")[0]
    if (data === "uploads") {
      return process.env.API_IMAGE_URL + img
    } else {
      return img
    }
  }

  // handle disclosure function //
  const handleDisclousre = async () => {
    let getParams = {
      user_id: userId,
      property_id: details._id,

    }
    await reportDisclosure(getParams).then((res) => {
      if (res.data.status === 1)
        setMessages(res.data.message)
      toggleSuccess()
    }).catch((error) => {
      console.log(error);
    })
  }

  // Description read more function //
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  // image highlight function //
  const handleImageView = (item, index) => {
    setView(false)
    setImages(item.MediaURL)
    setIndex(index)
  }

  // price format function //
  const nFormatter = (num, digits) => {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function (item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : details.avm_data !== undefined && details.avm_data !== null && details.avm_data.EstimatedMinValue;
  }

  return (

    <div >
      {/* {openProperty && propertDetails !== {} && <ProperyDetails openProperty={openProperty} hanldeOpenDetails={hanldeOpenDetails} id={propertyId} propertDetails={propertDetails} />} */}
      <ToastPopup success={success} toggleSuccess={toggleSuccess} messages={messages} />
      {connect ? <ConnectAgentPopup data={details} cityDetail={ownedDetails} location={props.data.location} connect={connect} toggleConnect={toggleConnect} isActive={true} /> : null}
      {details.length !== 0 &&
        <Card
          style={{
            background: 'rgb(255, 255, 255)',
          }}>

          <div className="row " style={{ margin: '17px 18px', padding: '10px 0', border: '2px solid #f8f7f7', borderRadius: "18px" }}>
            <HouseDetails address={details.address} image={details} close={handleClose} />

            <div className="col-md-12" style={{ display: "flex", flexWrap: "wrap", marginTop: "10px" }}>
              <div className="col-md-8">
                <div className="d-flex justify-content-flex-start">
                  <i className="fa fa-circle ClaimActiveIcon" style={{ color: details.listing_status === 0 ? "orange" : details.listing_status === 1 || details.listing_status === 2 ? "#03B45B" : details.listing_status === 4 ? "yellow" : details.listing_status === 5 ? "red" : details.listing_status === 6 ? "grey" : details.listing_status === 7 ? "red" : details.listing_status === 8 ? "red" : "gray" }} ></i>

                  <p className={style1.housetext1}>{details.listing_status === 0 ? "Draft" : details.listing_status === 1 ? "Active" : details.listing_status === 2 ? "Active Contingent" : details.listing_status === 3 ? "Exclusive" : details.listing_status === 4 ? "Pending" : details.listing_status === 5 ? "Sold" : details.listing_status === 6 ? "Expired" : details.listing_status === 7 ? "Cancelled" : details.listing_status === 8 ? "Withdrawn" : "Off Market"}</p>
                </div>
              </div>
              <div className="col-md-4">
              {details.listing_status ==5 ?<p className={styles.homePrice} id={styles.pricedetail}>${String((details !== undefined && details.sale_price !== undefined && details.sale_price !== null) ? details.sale_price.$numberDecimal : null).replace(
                  /(.)(?=(\d{3})+$)/g,
                  "$1,")}</p>
                  :
                  <p className={styles.homePrice} id={styles.pricedetail}>${String((details !== undefined && details.price !== undefined && details.price !== null) ? details.price.$numberDecimal : null).replace(
                    /(.)(?=(\d{3})+$)/g,
                    "$1,")}</p>
                }
              </div>
              <div className="col-md-6">
                {details.address !== undefined && details.address !== null &&
                  <p className={styles.homedescription1} id={styles.ownedpropadddress}>
                    {details.address.split(',').slice(0).shift()}, <br /> {details.address.split(',').slice(1).join(",")}
                  </p>
                }
              </div>
              <div>
                <p className={styles.listedText} id={styles.listtext}>
                  {isReadMore ? details.description !== undefined && details.description !== null && details.description.slice(0, 150) : details.description}
                  <span onClick={toggleReadMore} role="button" tabIndex="0" onKeyPress={toggleReadMore} className="read-or-hide" >
                    {isReadMore && details.description !== undefined && details.description !== null && details.description.length >= 150 ? "...Read more" : details.description !== undefined && details.description !== null && details.description.length >= 150 && " show less"}
                  </span>
                </p>
              </div>
            </div>
            <div>
              {details.beds == "0" && details.baths == "0" && details.square_feet.$numberDecimal == "0" ? <></> :
                <Card
                  className={styles.detCard1}
                  style={{
                    borderRadius: "10px",
                    height: "90px",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                    borderTop: "0px"

                  }}
                >
                  <div className="row m-0 d-flex justify-content-around">
                    {details.beds !== "0" &&
                      <div className="col-md-4 cardtext2 mt-3" >
                        <p className={styles.smallCard}>{details.beds}</p>
                        <p className={styles.smallCard1} style={{ color: "#898686" }}>
                          Beds
                        </p>
                      </div>}
                    {details.baths !== "0" &&
                      <div className="col-md-4 cardtext2 mt-3">
                        <p className={styles.smallCard}>{details.baths}</p>
                        <p className={styles.smallCard1} style={{ color: "#898686" }}>
                          Baths
                        </p>
                      </div>}
                    {details.square_feet.$numberDecimal !== "0" &&
                      <div className="col-md-4 cardtext2 mt-3">
                        <p className={styles.smallCard}>{String(details.square_feet !== undefined && details.square_feet.$numberDecimal).replace(
                          /(.)(?=(\d{3})+$)/g, "$1,"
                        )}</p>
                        <p className={styles.smallCard1} style={{ color: "#898686" }}>
                          Sq. Feet
                        </p>
                      </div>}
                  </div>

                </Card>}
              {details.avm_data !== undefined && details.avm_data !== null &&
                <div>
                  <Card
                    className={styles.detCard1}
                    style={{
                      borderRadius: "10px",
                      height: "90px",
                      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      borderTop: "0px"

                    }}
                  >

                    <div className=" m-0 d-flex justify-content-around">
                      <div className=" cardtext2 mt-3" >
                        <p className={styles.smallCard}>${details.avm_data !== null && details.avm_data.EstimatedValue.split(".")[0].replace(
                          /(.)(?=(\d{3})+$)/g, "$1,"
                        )}</p>
                        <p className={styles.smallCard1} style={{ color: "#898686" }}>
                          Estimated Value
                        </p>
                      </div>
                      <div className=" cardtext2 mt-3">
                        <p className={styles.smallCard}>
                          ${nFormatter(details.avm_data !== null && details.avm_data.EstimatedMinValue, 1)}-${nFormatter(details.avm_data !== null && details.avm_data.EstimatedMaxValue, 1)}</p>
                        <p className={styles.smallCard1} style={{ color: "#898686" }}>
                          Estimated Sales Range
                        </p>
                      </div>
                      <div className=" cardtext2 mt-3">
                        <p className={styles.smallCard}>{details.avm_data !== null && details.avm_data.ConfidenceScore.split(".")[0].replace(
                          /(.)(?=(\d{3})+$)/g, "$1,"
                        )}%</p>
                        <p className={styles.smallCard1} style={{ color: "#898686" }}>
                          Confidence score
                        </p>
                      </div>
                    </div>

                  </Card>
                </div>
              }
              {!details.owned &&
                <div className="d-flex justify-content-between mb-4">
                  <Button className="transferbutton" onClick={toggleConnect}>Sell My Home</Button>
                  <>
                    {(details.listing_status == 5 || details.listing_status == 6 || details.listing_status == 7 || details.listing_status == 8 || details.listing_status == 10) &&
                      < Link to="/editProperty/editProperty" state={{ props: ownedDetails, data: props.propImages, propPath: "owned" }}>
                        <Button className="transferbutton" id="edithomebutt" onClick={() => handleRoutes} >Edit Home Details</Button>
                      </Link>}</>
                </div>
              }
            </div>
            <div >
              {details.signed_disclosure !== null && details.signed_disclosure !== "" &&
                userDetails.role === "agent" ? <a href={`${process.env.API_IMAGE_URL + details.signed_disclosure}`} target="_blank" rel="noreferrer"> <Button type="button" className="sellertour-owned btn btn-primary" style={{ fontSize: "14px", borderRadius: "9px", marginTop: "2px", height: "53px" }}>
                  View Disclosures & Reports
                </Button></a> :
                userDetails.role !== "agent" && details.signed_disclosure !== null && details.signed_disclosure !== "" && details.view_signed_disclosure === true ?
                  <a href={`${process.env.API_IMAGE_URL + details.signed_disclosure}`} target="_blank" rel="noreferrer"> <Button type="button" className="sellertour-owned btn btn-primary" style={{ fontSize: "14px", borderRadius: "9px", marginTop: "2px", height: "53px" }}>
                    View Disclosures & Reports
                  </Button></a> : (userDetails.role === "user" && details.view_signed_disclosure === false && details.signed_disclosure !== null && details.signed_disclosure !== "" &&
                    <Button type="button" className="sellertour-owned btn btn-primary" style={{ fontSize: "14px", borderRadius: "9px", marginTop: "2px", height: "53px" }} onClick={handleDisclousre} >
                      View Disclosures & Reports
                    </Button>
                  )}
            </div>
            {details.thumbnail_image !== undefined && details.thumbnail_image !== null &&
              <div className={styled.activity}>
                <div>
                  <p className={styled.activityparagraph}>
                    Activity
                  </p>
                </div>
                <div >
                  <select className="form-select editSelect22" style={{ borderRadius: "30px", width: "128px", height: "42px", fontSize: "13px", cursor: "pointer", marginRight: "19px", padding: "10px 14px !important" }} name="calender" id="calender" onChange={getPropertyActivity}>
                    <option value="this_week">This Week</option>
                    <option value="this_month">This Month</option>
                    <option value="six_month">Six Month</option>
                    <option value="this_year">This Year</option>
                    <option value="all_year">All Year</option>
                  </select>
                </div>
              </div>}
            {details.thumbnail_image !== undefined && details.thumbnail_image !== null &&
              <div className="mt-3">
                <Bar activity={activityDetails} />
              </div>}
            {details.thumbnail_image !== undefined && details.thumbnail_image !== null &&
              <div>
                <div>
                  <div>
                    <div>
                      <div className={styled.viewdetails} id={styled.viewerdetails}>
                        <div>
                          <p className={styled.viewtext1}>Viewership</p>
                          <p style={{ fontFamily: "DejaVuSansBold" }}>{viewerShip.viewership}%</p>
                        </div>
                        <div>
                          <p className={styled.viewtext2}>Downloads</p>
                          <p style={{ fontFamily: "DejaVuSansBold" }}>{viewerShip.downloads}</p>
                        </div>
                        <div>
                          <p className={styled.viewtext3}>Offers</p>
                          <p style={{ fontFamily: "DejaVuSansBold" }}>{viewerShip.offers}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>}
            <div>

              <div className={styles.ImageView1}>
                {details.thumbnail_image !== undefined && details.thumbnail_image !== null &&
                  <>
                    {view ?
                      (details.thumbnail_image !== null && details.thumbnail_image !== undefined && details.thumbnail_image.includes('mls') ? <img src={details.thumbnail_image !== undefined && hanldeImage(details.thumbnail_image)} alt="EditGroup1 " style={{ width: "100%", padding: "15px" }} /> :
                        <img style={{ width: "100%", padding: "15px" }} src={details.thumbnail_image !== undefined && hanldeImage(details.thumbnail_image)} alt="EditGroup1 " crossOrigin='anonymous' />) :
                      (image !== null && image !== undefined && image.includes('mls') ? <img src={image !== undefined && hanldeImage(image)} alt="EditGroup1 " style={{ width: "100%", padding: "15px" }} /> :
                        <img style={{ width: "100%", padding: "15px" }} src={image !== undefined && hanldeImage(image)} alt="EditGroup1 " crossOrigin='anonymous' />)
                    }</>}
                {props.propImages.length !== 0 &&
                  <div
                    className="row overflow-auto"
                    style={{ height: "305px", marginTop: "10px", padding: "0 15px" }}>
                    {props.propImages !== undefined && props.propImages.map((item, index) => (
                      <div className="col-md-6" key={index} style={{ paddingRight: "0" }} onClick={() => handleImageView(item, index)} onKeyPress={() => handleImageView(item, index)} role="button" tabIndex="0" >
                        {item.MediaURL !== undefined && item.MediaURL !== undefined && item.MediaURL.includes('mls') ? <img src={item.MediaURL !== undefined && hanldeImage(item.MediaURL)} alt="EditGroup1 " style={{ width: "100%", padding: "7px", border: (index === indexx ? "1px solid rgb(4, 144, 251)" : "none") }} className={styles.ImageView3} /> :
                          <img style={{ width: "100%", padding: " 7px", border: (index === indexx ? "1px solid rgb(4, 144, 251)" : "none") }} className={styles.ImageView3} src={item.MediaURL !== undefined && hanldeImage(item.MediaURL)} alt="EditGroup1 " crossOrigin='anonymous' />}
                      </div>))}
                  </div>}
              </div>

            </div>
            <div>
              {similarProperty.length !== 0 &&
                <div style={{ background: "#F2F2F2" }}>
                  <p className={style1.similar}>Similar Properties</p>
                  <SimilarCarousel similar={similarProperty} detail={true} />
                  {/* <Carousel
                    swipeable={false}
                    draggable={false}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container simlilar"
                    removeArrowOnDeviceType={["mobile"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                  >
                    {
                      similarProperty.map((item, i) => (
                        <div className={style1.simhead} key={i} onClick={() => hanldeOpenDetails(item._id,item)} role="button" tabIndex="0">
                          <div className="row">
                            <div className="col">
                              <div >
                                <Card className={style1.simheadCard}  >
                                  <div className='div-carousel'>
                                     {item.thumbnail_image !== undefined ? (item.thumbnail_image !== null ?
                                                <img src={item.thumbnail_image} alt="image95" height={100} width={180} className={style1.propImage} id={style1.propertyImage} />
                                                :
                                                <img src={Dummy} alt="EditGroup1 " height={100} width={180} className={style1.propImage} id={style1.propertyImage} />) :
                                                item.location !== undefined && item.location &&
                                                <PropMap lng={item.location.coordinates[0]} lat={item.location.coordinates[1]} valueFor="simalar"/>}
                                     <div className={style1.simproperties}>
                                      <p>{item.address.split(',').slice(0).shift()},</p>
                                      <p>{item.address.split(",").slice(1).join(",")}</p>
                                      <p style={{ fontFamily: "DejaVuSansBold" }}>${String(item.price.$numberDecimal).replace(
                                        /(.)(?=(\d{3})+$)/g,
                                        "$1,"
                                      )}</p>
                                    </div>
                                  </div>
                                </Card>
                              </div>
                            </div>
                          </div>

                        </div>
                      ))
                    }
                  </Carousel> */}

                </div>
              }
            </div>
          </div>
        </Card>
      }
    </div >
  )
}
export default OwnedPropDetails

import { navigate } from "gatsby";
import React, { useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import * as style1 from '../../pages/dashboard/detail.module.css';
import * as style from "../../pages/agents/agent.module.css"
import { resetPassword } from "../Api/ListingApi";
import { useDispatch } from "react-redux";
import firebase from 'gatsby-plugin-firebase';
import { calenderResponse } from "../../state/calenderPopUp";
import { logout } from "../../state/userSlice";
import { singlePropertyClear } from "../../state/detailSlice";
import VisibilityOffSharpIcon from '@mui/icons-material/VisibilityOffSharp';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import ToastPopup from "./ToastPopup";
import { leadsId } from "../../state/userSlice";

function ChangePasswordPopup(props) {
  const dispatch = useDispatch()
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");
  const [isnew_password, setNew_password] = useState("")
  const [isconfirm_password, setConfirm_password] = useState("")
  const { show, toggleShow, uid } = props;
  const initialValues = {
    new_password: "",
    confirm_password: ""
  }
  const [formValues, setFormValues] = useState(initialValues)
  const [success, setSucess] = useState(false);
  const [messages, setMessages] = useState("")

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  }

  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
  }

  const validation = () => {

    if (formValues.new_password === "") {
      setNew_password("New password is required")
    }
    if (formValues.new_password.length < 8 && formValues.new_password.length > 0) {
      setNew_password("Password must be minimum 8 characters")
    }
    if (formValues.confirm_password === "") {
      setConfirm_password("Confrim password is required")
    }
    if (formValues.confirm_password.length < 8 && formValues.confirm_password.length > 0) {
      setConfirm_password("Password must be minimum 8 characters")
    }
    if (formValues.new_password !== formValues.confirm_password) {
      setConfirm_password("Password doesn`t match")
    }
    if (formValues.new_password.length > 8 && formValues.new_password === formValues.confirm_password) {
      changepassword()
    }
  }

  const toggleSuccess = () => {
    setSucess(p => !p);
  }

  const changepassword = async () => {
    let getParams = {
      id: uid,
      new_password: formValues.new_password,
      confirm_password: formValues.confirm_password
    }
    await resetPassword(getParams).then((res) => {
      setMessages("Password reset Successully, Please Login.")
      toggleSuccess()
      toggleShow()
      handleLogoutFirebase()
    }).catch((error) => {
      console.log(error)
    })
  }

  const handleLogoutFirebase = async () => {
    await firebase
      .auth()
      .signOut()
    localStorage.removeItem("persist:root");
    localStorage.removeItem("userInfo");
    localStorage.removeItem('userId')

    navigate("/signin");
    dispatch(singlePropertyClear())
    dispatch(logout())
    dispatch(calenderResponse([]))
    dispatch(leadsId("")) 
  }

  const formValChange = e => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value.trim() });
  };

  return (
    <div>
      <ToastPopup success={success} toggleSuccess={toggleSuccess} messages={messages} />
      <Modal show={show} onHide={toggleShow}>
        <Modal.Header closeButton className="team-popup-margin teampopup-title " >
          <Modal.Title className='form-field-label form-label-team '></Modal.Title>
        </Modal.Header>
        <Modal.Body className="team-popup-margin pb-0" >
          <div className={style.overallBack}>
            <div className={style.createInBack2}>
              <label htmlFor="new_password" className={style.createText1}>New password</label>
              <br />
              <input placeholder="Enter new password" value={formValues.new_password} name="new_password" onChange={formValChange} type={passwordType} aria-hidden className={style.createInput3} onFocus={(e) => setNew_password("")}></input>
              <span style={{ position: "absolute", left: "407px", paddingTop: "9px" , cursor:"pointer"}} aria-hidden onClick={togglePassword}>
                {passwordType === "password" ? (
                  <VisibilitySharpIcon />
                ) : (
                  <VisibilityOffSharpIcon />
                )}
              </span><br></br>
            </div>
            <p style={{ color: "red", fontSize: "12px", paddingLeft: "158px" }}>{isnew_password}</p>
            <div className={style.createInBack2}>
              <label htmlFor="confirm_password" className={style.createText1}>Confirm password</label>
              <br />
              <input placeholder="Enter Confirm password" value={formValues.confirm_password} name="confirm_password" onChange={formValChange} aria-hidden type={passwordType1} className={style.createInput3} onFocus={(e) => setConfirm_password("")}></input>
              <span style={{ position: "absolute", left: "407px", paddingTop: "9px" }} aria-hidden onClick={togglePassword1}>
                {passwordType1 === "password" ? (
                  <VisibilitySharpIcon />
                ) : (
                  <VisibilityOffSharpIcon />
                )}
              </span><br></br>
            </div>
            <p style={{ color: "red", fontSize: "12px", paddingLeft: "158px" }}>{isconfirm_password}</p>
          </div>
          <Button className={style1.yesbutton1} onClick={validation}>
            submit
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  )
}
export default ChangePasswordPopup
import { useState } from "react"
import React  from "react"
// import AgentVerifypopup from "../../../components/popup/AgentVerifypopup"
// import { Link, navigate } from "gatsby"
// import Seo from "../../../components/seo";

export default function AgentMLSAccount(props)
{
    const initialValues = {
        company: "",
    }
  const [formValues, setFormValues] = useState(initialValues)
  const formErrors={}
  // const [isShow, setIsShow] = useState(false)
  // const toggleOpen = () => {
  //   setIsShow(false)
  //   props.closePopUp()
  // }

    const formValChange = e => {
        e.preventDefault()
        const { name, value } = e.target
        if(e.target.value === "MLS listing"){
            props.closeAgent()
        }
        setFormValues({ ...formValues, [name]: value })
    }
    return(
        <div>
       <div className='col-lg-12 col-xl-12 col-md-12'>

        <div className="col-lg-12 col-xl-12 col-md-12 pl-40 pr-40 mlspopup" >
        <label htmlFor="company" className="form-label form-field-label">
          MLS<span className="error-msg"> *</span>
        </label>
        <select
          value={formValues.company}
          onChange={formValChange}
          id="company"
          name="company"
          className="form-select form-select-lg form-field mb-2 company-select AgentAccountForm"
          
          aria-label="--Select--"
        >
          <option>--Default--</option>
          <option>MLS listing</option>
        </select>
        <p className="error-msg">{formErrors.company}</p>
      </div> 
      </div>
      </div>
    )
}
import React, { useEffect, useState, useRef } from 'react';
import { Form, Button } from "react-bootstrap";
import { Link, navigate } from 'gatsby';
import firebase from 'gatsby-plugin-firebase';
import { signinDetails, userDetailInfo } from '../Api/formApi';
import Loader from '../Loader';
import { useDispatch, useSelector, } from 'react-redux';
import { userResponse } from '../../state/userSlice';
import { updateProfile, roleCheck, leadsAdding } from '../Api/ListingApi';
import VisibilityOffSharpIcon from '@mui/icons-material/VisibilityOffSharp';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import ErrorPopup from '../popup/ErrorPopup';
import InactiveProfilePopup from "../../components/popup/inactiveProfilePopup";
// import { initGA, logPageView, logUserLogin } from '../../pages/GoogleAnalytics';

export default function LoginForm() {
    const initialValues = {
        email: '',
        password: '',
        rememberme: false,
        emailValidate: "",
        passwordValidate: ""
    }
    const [formValues, setFormValues] = useState(initialValues);
    const store_url = useSelector((state) => state.dashboard.store_url)
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [success, setSucess] = useState(false);
    const [messages, setMessages] = useState("hii")
    const toggleSuccess = () => setSucess(p => !p);
    const [visible, setIsVisible] = useState(true);
    const [isshow, setIsShow] = useState(true);
    const [showInactive, setShowInactive] = useState(false)
    const toggleInactive = () => setShowInactive(p => !p)
    const [active, inActive] = useState()
    const inputElement = useRef(null);
    const [loader, setLoader] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    let dispatch = useDispatch();
    const myLeadsId = useSelector(state => state.user.leadsId);

    useEffect(() => {
        const storedData = localStorage.getItem("remember-me");
        if (!storedData) {
        } else {
            let email = JSON.parse(storedData).email;
            let password = JSON.parse(storedData).password;
            let isRememberme = JSON.parse(storedData).isRememberme;
            if (isRememberme) {
                document.getElementById("rcheckbox").checked = true;
            } else {
                document.getElementById("rcheckbox").checked = false;
            }
            setFormValues({ ...formValues, email: email, password: password, rememberme: isRememberme });
        }
    }, []);

    useEffect(() => {
        document.body.classList.add("form-bg");
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            handleSubmitFirebase();
        }
    }, [formErrors]);

    useEffect(() => {
        if (inputElement.current) {
            inputElement.current.focus();
        }
    }, []);

    setTimeout(function () {
        if (formValues.emailValidate === "Email not found") {
            setIsVisible(false);
        }
        else if (formValues.passwordValidate === "Password is incorrect") {
            setIsShow(false)
        }

    }, 5000);

    const togglePassword = () => {
        if (passwordType === "password") {
            setPasswordType("text");
            return;
        }
        setPasswordType("password");
    }

    const formValChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value.trim() });
    };

    const signin = (uid, email) => {
        signinDetails(uid)
            .then(async (usersDetails) => {
                if (usersDetails.status === 200) {
                    try {
                        await firebase.messaging().firebaseDependencies.installations.getToken().then(async (res) => {
                            await updateProfile(uid, { push_token: res }).then().catch((error) => {
                            })
                        })
                    }
                    catch (error) {
                        console.log(error)
                    }
                    await userDetailInfo(uid)
                        .then((res) => {
                            console.log("response login", res.data.data)
                            //agent connect //
                            if (myLeadsId !== "" && myLeadsId[0] !== "" && myLeadsId[0] !== null && myLeadsId !== null) {
                                let getParams = {
                                    user_id: res.data.data._id,
                                    agent_id: myLeadsId[0]
                                }
                                leadsAdding(getParams).then((res) => {
                                    // console.log("responselead", res);
                                }).catch((err) => {
                                    console.log(err);
                                })
                            }
                            localStorage.setItem('userId', res.data.data._id)
                            if (res.status === 200) {
                                dispatch(userResponse(res.data.data));
                                inActive(res.data.data.online_status)
                            }
                            let claimStatus = res.data.data.role;
                            var a = [];
                            a.push(JSON.stringify({ email: email, uid: uid, userType: claimStatus, provider: "local" }));
                            localStorage.setItem("userInfo", a);
                            if (claimStatus) {
                                if (res.data.data.online_status == false && claimStatus == "agent") {
                                    toggleInactive()
                                    setTimeout(() => toggleInactive(), 5000)

                                    setTimeout(() => {
                                        setLoader(false);
                                        if (claimStatus === "agent" && myLeadsId !== "" && myLeadsId[0] !== "") {
                                            navigate("/myleads");
                                        }
                                        else if (claimStatus === "agent") {
                                            navigate("/buyers");
                                        }
                                    }, 1000)
                                }
                                else {
                                    setTimeout(() => {
                                        setLoader(false);
                                        if (claimStatus === "agent" && myLeadsId !== "" && myLeadsId[0] !== "") {
                                            navigate("/myleads");
                                        }
                                        else if (claimStatus === "agent") {
                                            navigate("/buyers");
                                        } else if (claimStatus === "user" && myLeadsId !== "" && myLeadsId[0] !== "") {
                                            navigate("/myAgent");
                                        }
                                        else {

                                            if (store_url !== "") {

                                                navigate(store_url)
                                            }
                                            else {
                                                navigate("/");
                                            }
                                        }
                                    }, 1000)
                                }
                            }
                        }).catch((error) => {
                            setLoader(false);
                            setMessages("You are not valid user")
                            toggleSuccess()
                        })
                }
            })
    }

    const handleSubmitFirebase = async () => {
        try {
            setLoader(true);

            const { email, password } = formValues;
            await firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .then(function (firebaseInfo) {
                    // console.log("firebaseInfo", firebaseInfo)
                    let isEmailVerify = firebaseInfo.user.emailVerified;
                    let uid = firebaseInfo.user.uid;
                    let email = firebaseInfo.user.email;
                    // console.log(firebaseInfo);
                    if (firebaseInfo.user.email !== "" || firebaseInfo.user.email !== null) {
                        roleCheck(firebaseInfo.user.email).then((res) => {
                            // console.log("res.data.data[0]", res.data.data[0])
                            if (res.data.data[0].role == "agent") {
                                signin(uid, email)
                                // logUserLogin(res.data.data[0]._id, res.data.data[0].role);
                            }
                            else {
                                if (isEmailVerify) {
                                    // console.log("checking role", res.data.data[0])
                                    signin(uid, email)
                                    // logUserLogin(res.data.data[0]._id, res.data.data[0].role);
                                } else {
                                    setLoader(false);
                                    setMessages("Email address is not verified")
                                    toggleSuccess()
                                }
                            }
                        })
                    }
                })
        } catch (err) {
            setLoader(false);
            if (err.code === "auth/wrong-password") {
                setFormValues({ ...formValues, passwordValidate: "Password is incorrect" })
                setMessages("Password is incorrect")
                toggleSuccess()
            } else {
                setFormValues({ ...formValues, emailValidate: "Email not found" });
                setMessages("Email not found")
                toggleSuccess()
            }
        }
    }

    const onSubmit = e => {
        e.preventDefault();
        setFormErrors(validate(formValues));
        setIsSubmit(true);
        // logUserLogin(userDetails._id, userDetails.role);
    };

    const validate = (values) => {
        const errors = {};
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!values.email) {
            errors.email = 'Please enter a valid Email address';
        } else if (!regex.test(values.email)) {
            errors.email = 'Please enter a valid Email address';
        }

        if (!values.password) {
            errors.password = 'Please enter your password';
        } else if (values.password.length < 8) {
            errors.password = 'Password must be minimum 8 characters';
        }
        if (values.password.length > 30) {
            errors.password = 'Password maximum length is 30 characters';
        }
        return errors;
    };

    const isRememberme = (e) => {
        let isChecked = !formValues.rememberme;
        if (isChecked && formValues.email && formValues.password) {
            let rememberMe = [];
            rememberMe.push(JSON.stringify({ email: formValues.email, password: formValues.password, isRememberme: isChecked }));
            localStorage.setItem('remember-me', rememberMe);
        } else {
            localStorage.setItem('remember-me', '');
        }
        setFormValues({ ...formValues, rememberme: isChecked });
    }

    return (
        <div className='login-formsection'>
            <InactiveProfilePopup showInactive={showInactive} toggleInactive={toggleInactive} status={active} />

            <ErrorPopup success={success} toggleSuccess={toggleSuccess} messages={messages} />
            {loader ? <Loader /> : null}
            {/* id='userrole-signin' */}
            <Form onSubmit={onSubmit} className='SignInFormsInput' id='userrole-signin'>
                <Form.Group controlId="email" className="mb-2">
                    <Form.Label className="form-field-label" style={{ fontSize: "18px", fontFamily: "DejaVuSansBold" }}>Email<span className='error-msg'> *</span></Form.Label>
                    <Form.Control name="email" value={formValues.email} onChange={formValChange} className="form-field SignUpForm SignUpForm0 SignInForm InputErrorStyle SignUPFormagent " type='text' id="signinLogin" maxLength={50} placeholder='Email Address' style={{ borderRadius: "8px" }} ref={inputElement} />
                </Form.Group>
                {formValues.email.length > 0 && isSubmit ? <p className='error-msg ErrorMessage1'>{formErrors.email}</p> : <p className='error-msg ErrorMessage1'>{formErrors.email}</p>}
                <Form.Group controlId="password" className="mb-2">
                    <Form.Label className="form-field-label" style={{ fontSize: "18px", fontFamily: "DejaVuSansBold" }}>Password<span className='error-msg'> *</span></Form.Label>
                    <div style={{ display: "flex" }}>
                        <Form.Control name="password" value={formValues.password} onChange={formValChange} className="form-field SignUpForm SignUpForm0 SignInForm InputErrorStyle SignUPFormagent" type={passwordType} placeholder='Password' id="signinLogin" style={{ borderRadius: "8px" }} />
                        <p style={{ margin: "18px 0 0px -39px", cursor: "pointer" }} >
                            {passwordType === "password" ? (
                                <VisibilitySharpIcon onClick={togglePassword} />
                            ) : (
                                <VisibilityOffSharpIcon onClick={togglePassword} />
                            )}
                        </p>
                    </div>
                </Form.Group>
                {formValues.password.length > 0 && isSubmit ? <p className='error-msg ErrorMessage1'>{formErrors.password}</p> : <p className='error-msg ErrorMessage1'>{formErrors.password}</p>}
                <Form.Group controlId='accept RememberPassword'>
                    <label>
                        <Form.Check id="rcheckbox" type="checkbox" style={{ display: "inline-block", paddingRight: "10px" }} data-value={formValues.rememberme} defaultChecked={formValues.rememberme} onClick={(e) => isRememberme(e)} />
                        <Form.Text className='remember-me RememberForget' style={{ cursor: "pointer" }}>Remember me </Form.Text> </label><Form.Text className='remember-me RememberForget' > <Link to="/forgotpassword" ><span className="forget-password ForgetPassword " id="ForgetPassword">Forgot Password?</span></Link></Form.Text>
                </Form.Group>
                <Button type='submit' className='signin-submit-btn SignUpForm SignInForm'>Login</Button>
            </Form>
        </div>
    );
}
